import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Upload, Select, DatePicker, message, notification, Tooltip, Typography} from 'antd';
import jsonfile from './bill.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectedData from "../../../../util/StaticSelectData";
import { UploadOutlined } from '@ant-design/icons';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const NewMultyLineBill = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();

    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    let Vendors = [];
    // const Companies = SelectData("company/companylists");
    const Companies = [];

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company_id: null,
        vendor_id: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : "",
        description : "",
        remark : "",
    });


    const [state, setState] = useState({
        po: {},
        items: [],
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const onFinish = (values) => {
        setLoading({loading: true})
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        values["items"] = lineItems;
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    };

    let POData = {};

    useEffect(() => {
        // getPODetails();
        const comID = CisUI().getUserInfo("com_id");
        if(comID > 0) {
            document.getElementById("loader").style.display = "block";
            axios.get("billentrydata?com_id="+comID)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }

                    document.getElementById("loader").style.display = "none";
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });

            // formRef.current.setFieldsValue({
            //     vendor: "",
            //     accounts_payable: "",
            //     accounts_id: ""
            // });
        }

    }, []);

    const getPODetails = () => {
        if(isEdit === 0) {
            axios.get(Config.apiserver + "purchase/podetails/" + poid)
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        const PODataD = res.data.podata;
                        setPolist({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_d,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark
                        });

                        formRef.current.setFieldsValue({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_d,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark
                        });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            setPolist({
                po_number : userData.po_number,
                date: CisUI().DateFormat(userData.date),
                due_date: CisUI().DateFormat(userData.due_date),
                reference_no: userData.reference_no,
                company_id: userData.company_id,
                vendor_id: userData.vendor_d,
                payable_id: userData.payable_id,
                fund_program_id : userData.fund_program_id,
                func_expense_id : userData.func_expense_id,
                amount : userData.amount,
                description : userData.description,
                remark : userData.remark
            });
        }
    }

    const ShowVendor = (e) => {
        console.log("Vendor : "+e);
    }

    const handleAddRow = () => {
        const item = {
            account_id: null,
            fund_program_id: 1,
            func_expense_id: 1,
            restriction_type: "without",
            amount: null,
            note: null,
            classification : null,
            files : [],
            file_location : []
        };
        setLineItems([...lineItems, item]);
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = lineItems.filter((item, index) => index !== idx)
        setLineItems(filteredArray);
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...lineItems];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        if(name === "amount") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: value,
                note: rows[idx].note,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }
        else if(name === "note") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: rows[idx].amount,
                note: value,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }

        setLineItems(rows);

        let ttl = 0;
        rows.map((item,index) => {
            ttl += parseFloat(item.amount);
        })
        setTotalAmount(ttl);

    };

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: e,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const ItemCellChangeFundProgram = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: e,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const ItemCellChangeFunxExpense = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: e,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const ItemCellChangeRestrictionType = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: e,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const ItemCellChangeClassification = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : e,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...lineItems];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setLineItems(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    return (
        <Card title={Title}>
            {CisUI().showLoading}
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >

                <div className="row">

                    <div className="col-md-4">
                        <Form.Item
                            name="date"
                            label="Date"
                            initialValue={polist.date}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Date"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="due_date"
                            label="Due Date"
                            initialValue={polist.due_date}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a due date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Due Date"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="reference_no"
                            label="Reference No#"
                            initialValue={polist.reference_no}
                            rules={[
                                {
                                    required: false,
                                    message: "Enter reference number"
                                },
                            ]}
                        >
                            <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                        </Form.Item>
                    </div>

                    {
                        CisUI().getUserInfo("com_id") === 0 ?

                            <div className="col-md-4">
                                <Form.Item
                                    name="company_id"
                                    label="Company"
                                    initialValue={polist.company_id}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a company"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a company"
                                        optionFilterProp="children"
                                        onChange={onCompanyChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            Companies.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            : ""
                    }

                    <div className="col-md-4">
                        <Form.Item
                            name="vendor_id"
                            label="Vendor/Payee"
                            initialValue={polist.vendor_id}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a vendor"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a vendor"
                                optionFilterProp="children"
                                onChange={ShowVendor}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    vendor.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <div style={{marginTop: "5px", marginRight: "15px", textAlign: 'right', fontWeight: 'bold'}}>
                            <Tooltip title="Useful information" overlayClassName="right">
                                <Link to="../vendor/newvendorinfo"><i className="fa fa-plus" /> Add New Vendor</Link>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="payable_id"
                            label="Accounts Payable"
                            initialValue={polist.payable_id}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a accounts payable"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a payable account"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    payable.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="description"
                            label="Description(Memo)"
                            initialValue={polist.description}
                            rules={[
                                {
                                    required: true,
                                    message: "Enter description here"
                                },
                            ]}
                        >
                            <TextArea placeholder="Enter description here"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="remark"
                            label="Remark/Additional Notes"
                            initialValue={polist.remark}
                            rules={[
                                {
                                    required: false,
                                    message: "Enter something here"
                                },
                            ]}
                        >
                            <TextArea placeholder="Enter somthing ehre"/>
                        </Form.Item>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h2>Bill Items</h2>
                    </div>
                    <div className="col-md-6" style={{textAlign : "right"}}>
                        <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <th>#</th>
                            <th>Account</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Fund Program</th>
                            <th>Functional Expense</th>
                            <th>Attachment</th>
                            </thead>
                            <tbody>
                            {lineItems.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            onChange={ItemCellChangeAccount(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                accounts.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Amount" name="amount" value={item.amount} onChange={ItemCellChange(idx)}  />
                                    </td>
                                    <td style={{width : '300px'}}><input className="form-control text-right" style={{textAlign : 'left'}} name="note" value={item.note} onChange={ItemCellChange(idx)}  /></td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a fund program"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            defaultValue={1}
                                            onChange={ItemCellChangeFundProgram(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                fund.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a functional expense"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            defaultValue={1}
                                            onChange={ItemCellChangeFunxExpense(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                expense.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        <Upload data= {
                                            {
                                                file : formData,
                                                type : "vendorbill",
                                                file_id : fileUID,
                                                row_id : item.id,
                                                line_index : idx
                                            }
                                        } {...uplod}>
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan="2">Total = </th>
                                <th style={{textAlign: 'right', fontSize: '15px'}}>{CisUI().getCurrencyFormated1(totalAmount || 0)}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <a className="ant-btn ant-btn-info" onClick={() => history.goBack()} >Cancel</a>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewMultyLineBill;