import {Form, Row, Col, Button, Select, Card, notification, InputNumber, Input} from 'antd';
import React, {useEffect, useState} from "react";
import jsonfile from './approver.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputOnly from "../../../../util/InputOnly";
import SelectData from "../../../../util/selectdata";
import moment from "moment";

const {Option} = Select;

const NewApproverMulty = (props) => {

    const [loading, setLoading] = useState([]);
    const [type, setType] = useState([
        {
            id: "Transactional",
            name: "Transactional",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        },
        {
            id: "HR",
            name: "HR",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        },
        {
            id: "Bill",
            name: "Bill",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        },
        {
            id: "Procurement",
            name: "Procurement",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        },
        {
            id: "Voucher",
            name: "Voucher",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        },
        {
            id: "Expense Request",
            name: "Expense Request",
            reviewed_by : 0,
            reviewed_limit : 0,
            certified_by: 0,
            certified_limit: 0,
            authorized_by: 0,
            authorized_limit: 0,
            status: 1,
        }
    ]);

    const handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        values['items'] = type;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const ItemCellChange = (idx,fildID) => e => {

        const rows = [...type];
        rows[idx][fildID] = e;
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        setType(rows);
    };

    // const userRoles = SelectData("userpositions");
    const userList = SelectData("user/alluserlist");
    // const Companies = SelectData("company/companylists");
    const AccountsLevel = SelectData("accounts/accountslevel");
    //console.log("UserData : "+userData);

    const getEditData = async () => {
        document.getElementById("loader").style.display = "block";
        await axios.get(Config.apiserver+jsonfile.urls.apvdetails)
            .then(res => {
                // console.log(res.data.data)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    setType(res.data.data);
                }
            })
            .catch(error => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            })
    }

    useEffect(() => {
        getEditData();
    }, [])

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Reviewed By</th>
                        <th>Certified By</th>
                        <th>Authorized By</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        type.map((item, index) => {
                            return <tr>
                                <td>{item.name}</td>
                                <td>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Select
                                                style={{width: '100%'}}
                                                showSearch
                                                placeholder="Select an user"
                                                value={item.reviewed_by > 0 ? item.reviewed_by : null}
                                                optionFilterProp="children"
                                                onChange={ItemCellChange(index,"reviewed_by")}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    userList.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Select
                                                style={{width: '100%'}}
                                                showSearch
                                                value={item.certified_by > 0 ? item.certified_by : null}
                                                placeholder="Select an user"
                                                optionFilterProp="children"
                                                onChange={ItemCellChange(index,"certified_by")}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    userList.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Select
                                                style={{width: '100%'}}
                                                showSearch
                                                value={item.authorized_by > 0 ? item.authorized_by : null}
                                                placeholder="Select an user"
                                                optionFilterProp="children"
                                                onChange={ItemCellChange(index,"authorized_by")}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    userList.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"}/>
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()}><IntlMessages
                            id={"Cancel"}/></a>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewApproverMulty;