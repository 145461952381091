import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {CisUI} from "../../util/CISUI";
import CONFIG from "../../util/config";
import localStorage from "react-secure-storage";

const UserProfile = () => {
    const dispatch = useDispatch();

    const setHorizontalLayout = () => {
        console.log("Clicked on Window reload");
        localStorage.setItem("layout", "horizontal");
        window.location.reload();
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>
                <Link to={`/profile/myaccount`}>My Account</Link>
            </li>
            <li>
                <Link to={`/profile/changepassword`}>Change Password</Link>
            </li>
            <li>
                <Link to={"/changelayout"}>Change Layout</Link>
            </li>
            {/*<li onClick={setHorizontalLayout}>Horizontal Layout</li>*/}
            <li onClick={() => dispatch(userSignOut())}>Logout
            </li>
        </ul>
    );

    const userLocal = localStorage.getItem('userData') || "";
    // let  userID = 0;
    let userName = "";
    if (userLocal !== "") {
        const user = JSON.parse(userLocal);
        // userID = user['id'];
        userName = user['name'];
    }

    let ProfilePicURL = "https://via.placeholder.com/150";
    const image = CisUI().getUserInfo('image');
    if (image !== "") {
        ProfilePicURL = CONFIG.profile_pic_url + "" + image;
        // console.log(ProfilePicURL);
    }

    return (

        <div className="gx-align-items-center gx-avatar-row">
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                <Avatar src={ProfilePicURL}
                        className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
                        <span className="gx-avatar-name">{userName}
                            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
            </Popover>

        </div>

    )
};

export default UserProfile;
