import React from "react";
import {Route, Switch} from "react-router-dom";
import SalesOrderHistory from "../../containers/yotta/sales/order/SalesOrderHistory";
import PurchaseOrderList from "../../containers/yotta/purchase/list";
import AllVoucherList from "../../containers/yotta/entries/allvoucherlist";
import SalesOrderDetails from "../../containers/yotta/sales/order/Salesorderdetails";
import Debitnote from "../../containers/yotta/entries/debitnote";
import CreditNote from "../../containers/yotta/entries/creditnote";
import ReceiptRegister from "../../containers/yotta/entries/receiptsregister";
import PaymentRegister from "../../containers/yotta/entries/paymentregister";

const ArAP = ({match}) => (
    <Switch>
        <Route path={`${match.url}/salesregister`} component={SalesOrderHistory}/>
        <Route path={`${match.url}/purchseregister`} component={PurchaseOrderList}/>
        <Route path={`${match.url}/creditnote`} component={CreditNote}/>
        <Route path={`${match.url}/debitnote`} component={Debitnote}/>
        <Route path={`${match.url}/receiptsregister`} component={ReceiptRegister}/>
        <Route path={`${match.url}/paymentregister`} component={PaymentRegister}/>
        <Route path={`${match.url}/salesdetails/:id`} component={SalesOrderDetails}/>
    </Switch>
)

export default ArAP;