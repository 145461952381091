import React from "react";
import {Route, Switch} from "react-router-dom";

import ProjectTypeList from "../../containers/yotta/project/project_type/ProjectTypeList";
import NewProjectType from "../../containers/yotta/project/project_type/NewProjectType";
import ProjectInfoList from "../../containers/yotta/project/project_info/ProjectInfoList";
import NewProjectInfo from "../../containers/yotta/project/project_info/NewProjectInfo";

const Project = ({match}) => (
    <Switch>
        <Route path={`${match.url}/projecttypelists`} component={ProjectTypeList}/>
        <Route path={`${match.url}/newprojecttype`} component={NewProjectType}/>
        <Route path={`${match.url}/projecttypeedit/:id`} component={NewProjectType}/>
        <Route path={`${match.url}/projectinfolists`} component={ProjectInfoList}/>
        <Route path={`${match.url}/newprojectinfo`} component={NewProjectInfo}/>
        <Route path={`${match.url}/projectinfoedit/:id`} component={NewProjectInfo}/>
    </Switch>
);

export default Project;
