import React, {useState, useEffect} from "react";
import {Form, Button, Card, DatePicker, Select, Input, Upload, message} from 'antd';
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-us';
import {UploadOutlined} from "@ant-design/icons";
import StaticSelectedData from "../../../util/StaticSelectData";
import RemoteJson from "../../../util/gerremotedata";
const {Option} = Select;
const {TextArea} = Input;

const PVDetails = (props) => {

    const history = useHistory();
    const formRef = React.createRef();
    const formRef2 = React.createRef();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [bill, setBill] = useState({});
    const [cline, setCLine] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [totalexp, setTotalAmount] = useState(0);
    const [dueAmount, setDueAmount] = useState(0);
    const [balanceAdjust, setBalanceAdjust] = useState(false);

    const cashaccount = RemoteJson("cashaccount");
    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        // axios.get("vendor/billstatusupdate?bill_id="+id+"&status="+status)
        //     .then((res) => {
        //         document.getElementById("loader").style.display = "none";
        //         console.log(res.data);
        //         if(res.data.status === 1) {
        //             notification.success({
        //                 message: 'Success',
        //                 type: "success",
        //                 description: res.data.msg
        //             });
        //             getPODetails();
        //         }
        //         else {
        //             document.getElementById("loader").style.display = "none";
        //             notification.success({
        //                 message: 'Failed',
        //                 type: "danger",
        //                 description: res.data.msg
        //             });
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         document.getElementById("loader").style.display = "none";
        //     });
    }

    const [polist, setPolist] = useState({
        fund : [],
        expense: [],
        accounts: [],
        cashaccounts: [],
        vendor: [],
        employee: [],
        company: [],
        expenses: [],
    });



    const poid = props.match.params.id;
    const userID = poid;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }


    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + "entries/pvdetails/" + poid)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res.data;
                    setBill(res.data.data);
                    setCLine(res.data.lineitems);
                    const due = (data.voucher_amount) - (data.expense_amount || 0);
                    setDueAmount(due);
                    setPolist({
                        accounts: data.accounts,
                        cashaccounts: data.cashaccounts,
                        vendor: data.vendor,
                        employee: data.employee,
                        company: data.company,
                        expenses: data.expenses,
                    });

                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });

    }


    const onFinish = (values) => {
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        values['items'] = lineItems;

        let ttl = 0;
        lineItems.map((mp, ind) => {
            ttl += mp.amount;
        })

        //if(bill.voucher_amount >= ttl) {
            axios.post(Config.apiserver + "entries/pvclearenceadd/" + poid, values)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        CisUI().showAlert("Success", "success", res.data.msg);
                        document.getElementById("loader").style.display = "none";
                        window.location.reload();
                    } else {
                        CisUI().showAlert("Alert", "warning", res.data.msg);
                        document.getElementById("loader").style.display = "none";
                    }
                })
                .catch(errors => {
                    //console.log(errors.response.data.errors);
                    document.getElementById("loader").style.display = "none";
                });
        // }
        // else {
        //     CisUI().showAlert("Alert", "warning", "Total amount can not be more than "+CisUI().getCurrencyFormated1(bill.voucher_amount));
        //     document.getElementById("loader").style.display = "none";
        // }
    };

    const onFinishAdjustment = (values) => {
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        if (bill?.expense_amount > bill?.voucher_amount) {
            values["type"] = "OverExpense";
        }
        else {
            values["type"] = "LessExpense";
        }
        values["amount"] = bill?.balance;

        axios.post(Config.apiserver + "entries/pvclearenceadjust/" + poid, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success", "success", res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    window.location.reload();
                } else {
                    CisUI().showAlert("Alert", "warning", res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
            })
            .catch(errors => {
                //console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...lineItems];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setLineItems(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    let totalPaid = 0;
    let totalBill = 0;

    const handleAddRow = () => {
        const item = {
            expense_account_id: null,
            purpose_imperest: null,
            amount: 0,
            description: null,
            files : [],
            file_location : []
        };
        setLineItems([...lineItems, item]);
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = lineItems.filter((item, index) => index !== idx)
        setLineItems(filteredArray);
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...lineItems];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        console.log("FieldName : "+name);

        if(name === "amount") {
            rows[idx] = {
                expense_account_id: rows[idx].expense_account_id,
                purpose_imperest: rows[idx].purpose_imperest,
                amount: value,
                description: rows[idx].description,
                files: rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }
        else if(name === "purpose") {
            rows[idx] = {
                expense_account_id: rows[idx].expense_account_id,
                purpose_imperest: value,
                amount: rows[idx].amount,
                description: rows[idx].description,
                files: rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }
        else if(name === "description") {
            rows[idx] = {
                expense_account_id: rows[idx].expense_account_id,
                purpose_imperest: rows[idx].purpose_imperest,
                amount: rows[idx].amount,
                description: value,
                files: rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }

        setLineItems(rows);

        let ttl = 0;
        rows.map((item,index) => {
            const amount = item.amount || 0;
            ttl += parseFloat(amount);
        })
        setTotalAmount(ttl);
        const DUE = (bill.voucher_amount - bill.expense_amount) - ttl;
        setDueAmount(DUE);

    };

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];

        rows[idx] = {
            expense_account_id: e,
            purpose_imperest: rows[idx].purpose_imperest,
            amount: rows[idx].amount,
            description: rows[idx].description,
            files: rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const onBalanceAdjustClick = (type) => {
        setBalanceAdjust(type)
    }

    const BillInfo = (
        <>
            <div className="row" style={{padding: '20px'}}>
                <h4>Voucher Details</h4>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Voucher Number : </label>
                    <span> {bill.voucher_no}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Employee : </label>
                    <span> {bill.employee}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Description : </label>
                    <span> {bill.description}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Date of Collection : </label>
                    <span> {CisUI().DateFormat(bill.date)}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Reference/Check No# : </label>
                    <span> {bill.ref_no}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Accounts :  </label>
                    <span> {bill.account}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Cash/Bank Account Head : </label>
                    <span> {bill.cash_account}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Payment Method : </label>
                    <span> {bill.payment_method}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Bank Account : </label>
                    <span> {bill.bank_account}</span>
                </div>

                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Collection Amount : </label>
                    <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.voucher_amount)}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Remark : </label>
                    <span> {bill.remark}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Created By : </label>
                    <span> {bill.created_by}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Created at : </label>
                    <span> {bill.created_at}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Updated at : </label>
                    <span> {bill.updated_at}</span>
                </div>
                <div className="col-md-4">
                    <label style={{fontWeight: 'bold'}}>Status : </label>
                    <span> {CisUI().getTransactionStatus(bill.status)}</span>
                </div>

            </div>


            {
                bill.expense_amount > 0 ?
                    <div className="row" style={{padding: '20px'}}>
                        <h4>Clearance Details</h4>
                        <div className="col-md-4">
                            <label style={{fontWeight: 'bold'}}>PV# : </label>
                            <span> {bill.pv_no}</span>
                        </div>
                        <div className="col-md-4">
                            <label style={{fontWeight: 'bold'}}>Clearance Date : </label>
                            <span> {CisUI().DateFormat(bill.clearence_date)}</span>
                        </div>
                        <div className="col-md-4">
                            <label style={{fontWeight: 'bold'}}>Remark : </label>
                            <span> {bill.clearence_remark}</span>
                        </div>
                        <div className="col-md-4">
                            <label style={{fontWeight: 'bold'}}>Expense Amount : </label>
                            <span style={{color: 'green'}}>{CisUI().getCurrencyFormated1(bill.expense_amount)}</span>
                        </div>
                        <div className="col-md-4">
                            <label style={{fontWeight: 'bold'}}>Balance Due To/From/Me : </label>
                            <span style={bill?.balance > 0 ? {color: 'green'} : {color: 'red'}}>{CisUI().getCurrencyFormated1((bill.balance))}</span>
                            {
                                (bill.balance > 0 || bill.balance < 0) ? <><br /><a href={"#"} onClick={() => onBalanceAdjustClick(true)} className="ant-btn ant-btn-primary">Adjust Balance</a></> : <></>
                            }
                        </div>

                        {
                            bill?.adjustment_amount > 0 ?
                                <div className="col-md-12" style={{paddingTop: '20px'}}>
                                    <h4>Adjustment Details</h4>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label style={{fontWeight: 'bold'}}>Amount : </label>
                                            <span> {CisUI().getCurrencyFormated1(bill.adjustment_amount)}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label style={{fontWeight: 'bold'}}>Date : </label>
                                            <span> {CisUI().getFormatedDate(bill.adjustment_date)}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label style={{fontWeight: 'bold'}}>Type : </label>
                                            <span> {(bill.adjustment_type)}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label style={{fontWeight: 'bold'}}>Remark : </label>
                                            <span> {(bill.adjustment_note)}</span>
                                        </div>
                                    </div>
                                </div> : <></>
                        }

                        <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Expense Account</th>
                                <th>Purpose Imprest</th>
                                <th style={{textAlign: 'right'}}>Amount ({CisUI().getCurrencySymbol()})</th>
                                <th>Description</th>
                                <th>Attachment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cline.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}></td>
                                    <td style={{width : '350px'}}>{item.account}</td>
                                    <td style={{width : '200px'}}>{item.purpose_imprest}</td>
                                    <td style={{width : '150px' , textAlign: 'right'}}>{CisUI().getCurrencyFormated1(item.amount)}</td>
                                    <td>{item.details}</td>
                                    <td>
                                        {
                                            item.attachment.map((atc,inda) => {
                                                return <><a href={Config.attachement+atc?.attachment} target="_blank"><i className="fa fa-attachment"></i> Download</a><br /></>

                                            })
                                        }

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Total Expense</td>
                                <td style={{
                                    textAlign: 'right',
                                    fontWeight: 'bold'
                                }}>{CisUI().getCurrencyFormated1(bill.expense_amount)}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Balance Due To/From/me</td>
                                <td style={{
                                    textAlign: 'right',
                                    fontWeight: 'bold'
                                }}>{CisUI().getCurrencyFormated1(bill.balance)}</td>
                            </tr>
                            </tfoot>
                        </table>

                    </div>
                : <></>


            }

            {
                bill.voucher_amount > bill.expense_amount ?
                    <>
                        <Card title={"Cash Clearance Details"} >
                            <Form
                                form={form}
                                {...CisUI().formItemLayout}
                                name="input"
                                ref={formRef}
                                className="ant-advanced-search-form"
                                onFinish={onFinish}
                            >
                                <div className="row">

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="date"
                                            label="Date"
                                            initialValue={polist.date}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a date"
                                                },
                                            ]}
                                        >
                                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                        placeholder="Date"/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="reference_no"
                                            label="Reference PV No#"
                                            initialValue={"PC"+Math.floor(Math.random() * 900000)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter voucher number"
                                                },
                                            ]}
                                        >
                                            <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="remark"
                                            label="Remark/Payee"
                                            initialValue={polist.remark}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Enter something here"
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder="Enter something here"/>
                                        </Form.Item>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Clearance Items</h2>
                                    </div>
                                    <div className="col-md-6" style={{textAlign : "right"}}>
                                        <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                                    </div>
                                </div>

                                <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Expense Account</th>
                                        <th>Purpose Imprest</th>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Attachment</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lineItems.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                            <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                            <td style={{width : '350px'}}>
                                                <Select
                                                    showSearch
                                                    placeholder="Select an account"
                                                    optionFilterProp="children"
                                                    allowClear={true}
                                                    style={{width: '100%'}}
                                                    onChange={ItemCellChangeAccount(idx)}
                                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                                >
                                                    {
                                                        polist.expenses.map((items, index) =>
                                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </td>
                                            <td style={{width : '200px'}}>
                                                <input className="form-control" name="purpose" value={item.purpose_imperest} onChange={ItemCellChange(idx)}  />
                                            </td>
                                            <td style={{width : '150px'}}>
                                                <input className="form-control" style={{textAlign : 'right'}} name="amount" value={item.amount} onChange={ItemCellChange(idx)}  /></td>
                                            <td><input className="form-control" style={{textAlign : 'left'}} name="description" value={item.description} onChange={ItemCellChange(idx)}  /></td>
                                            <td>
                                                <Upload data= {
                                                    {
                                                        file : formData,
                                                        type : "pv_clearence",
                                                        file_id : fileUID,
                                                        row_id : item.id,
                                                        line_index : idx
                                                    }
                                                } {...uplod}>
                                                    <Button icon={<UploadOutlined />}> <span style={{color: 'red'}}>*&nbsp;</span> Click to Upload</Button>
                                                </Upload>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Total Expense</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>{CisUI().getCurrencyFormated1(totalexp)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Balance Due To/From/me</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>{CisUI().getCurrencyFormated1(dueAmount)}</td>
                                    </tr>
                                    </tfoot>
                                </table>

                                <div className="row">
                                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                        <Button id="submitbutton" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                                    </div>
                                </div>
                            </Form>
                        </Card>


                    </> : <></>
            }

            {
                balanceAdjust ?
                    <>
                        <Card title={"Balance Adjust"} >
                            <Form
                                form={form2}
                                {...CisUI().formItemLayout}
                                name="input"
                                ref={formRef2}
                                className="ant-advanced-search-form"
                                onFinish={onFinishAdjustment}
                            >
                                <div className="row">

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="date"
                                            label="Date"
                                            initialValue={moment()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a date"
                                                },
                                            ]}
                                        >
                                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                        placeholder="Date"/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="reference_no"
                                            label="Reference PV No#"
                                            initialValue={bill?.voucher_no}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Enter voucher number"
                                                },
                                            ]}
                                        >
                                            <Input disabled={true} className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="remark"
                                            label="Remark/Payee"
                                            initialValue={""}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Enter something here"
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder="Enter something here"/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="account_id"
                                            initialValue={bill?.cash_account_id || null}
                                            label="Account"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select an account"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select an account"
                                                optionFilterProp="children"
                                                allowClear={false}
                                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                            >
                                                {
                                                    cashaccount.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            name="amount"
                                            label="Amount"
                                            initialValue={bill?.balance}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Enter amount here"
                                                },
                                            ]}
                                        >
                                            <Input disabled={true} className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                                        </Form.Item>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                        <Button id="submitbutton" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                        <a className="ant-btn ant-alert-info" onClick={() => onBalanceAdjustClick(false)} >Cancel</a>
                                    </div>
                                </div>
                            </Form>
                        </Card>


                    </> : <></>
            }

        </>

    );

    return (
        <Card title={"Provisional Voucher Details"} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>

            {CisUI().showLoading}

            {BillInfo}


        </Card>
    );
};

export default PVDetails;