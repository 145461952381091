import React, {useState, useEffect} from "react";
import { Form, Col, Input, Card, notification, Collapse} from 'antd';
import jsonfile from './purchase.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import axios from "../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const VendorInfoList = () => {
    const value = 0;
    const [loading, setLoading] = useState([]);
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list+"?status=0",
        filtervalue : ''
    });

    const [stdata,setStdata] = useState([]);
    const [selected,setSelected] = useState([]);

    const selectedForPrint = (e) => {
        // console.log(JSON.stringify(selected));
        // const st = [...selected];
        // console.log(e.target.checked);
        if(e.target.checked === true) {
            //st[e.target.name] = e.target.value;
            selected.push(e.target.value);
        }
        else {
            selected.pop(e.target.value);
        }
        // setSelected(st);
        // console.log(JSON.stringify(selected));
        // console.log(JSON.stringify(selected.length));

        if(selected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(selected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"vendor/checkprint?selected="+id;
        const ck = "../../checkprint.html?type=checkprint&url="+window.btoa(url)+"&selected="+id;
        //window.open(ck, "_blank");
        return ck;
    }

    const checkAll = (e) => {
        //var tbody = document.getElementsByTagName('tbody');
        var checkboxes = document.getElementsByTagName('input');
        var checkedBoxes = document.querySelectorAll('input[type=checkbox]');
        console.log(checkedBoxes.length);
        for(var i=1, n=checkedBoxes.length;i<n;i++) {
            if (checkedBoxes[i].checked) {
                checkedBoxes[i].checked = false;
                selected.pop(checkedBoxes[i].value);
            } else {
                checkedBoxes[i].checked = true;
                selected.push(checkedBoxes[i].value);
            }
            //console.log("value :"+checkedBoxes[i].value);
        }

        if(selected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(selected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const headers = {
        id: {
            text: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1} />,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} />
            )
        },
        company_id: {
            invisible: true
        },
        vendor_id: {
            invisible: true
        },
        po_number: {
            text: <IntlMessages id="PO Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"podetails/"+row.id}>{value}</Link>
            )
        },
        required_by_date: {
            text: <IntlMessages id="Required By"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        total: {
            text: <IntlMessages id="Gross Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        sales_tax: {
            text: <IntlMessages id="Sales Tax"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        freight: {
            text: <IntlMessages id="Freight"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        discount: {
            text: <IntlMessages id="Discount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        grand_total: {
            text: <IntlMessages id="Grand Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getOrderStatus(value)
            )
        },
        // actions: {
        //     text: 'Actions',
        //     sortable: false,
        //     filterable: false,
        //     transform: (value, idx, row) => (
        //         <>
        //             {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
        //         </>
        //     ),
        // },
    };

    const orderedHeaders = jsonfile.pending_listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?status=0&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, 'data', 'pending_purchase_order', jsonfile, state.subtitle, "landscape")}
        </>
    );

    const goForApprove = () => {
        var URL = Config.apiserver + "purchase/multiplepoapprove?status=1&tps="+stdata;
        //console.log("URL : "+ URL);
        axios.post(Config.apiserver + "purchase/multiplepoapprove?status=1&tps="+stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push("../"+jsonfile.urls.list);
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    const goForReject = () => {
        axios.post(Config.apiserver + "purchase/multiplepoapprove?status=3&tps="+stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push("../"+jsonfile.urls.list);
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    return (
        <>
            {searchView}
            <div className="row" id="prntbtn" style={{padding: '10px',  display: 'none'}}>
                <a onClick={goForApprove} className="btn btn-primary" style={{width: '100px'}} >Approve</a>
                <a onClick={goForReject} className="btn btn-danger" style={{width: '100px'}} >Reject</a>
            </div>
            <Card title={jsonfile.pendinglist} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default VendorInfoList;