import {Form, Row, Col, Input, Upload, DatePicker, message, Button, Card, Select, notification} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './localexpenses.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import InputOnly from "../../../../util/InputOnly";
import { UploadOutlined } from '@ant-design/icons';



const ExpenseDetails = (props) => {

    const formRef = React.createRef();

    const [total, setTotal] = useState(0);
    const [officeExpnese, setOfficeExpense] = useState([]);
    let endpoint = jsonfile.urls.add;
    const search = props.location.search;
    const params = new URLSearchParams(search);


    const history = useHistory();


    const userID = props.match.params.id;
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        //userData = RemoteJson(jsonfile.urls.viewbymonth+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }

    const callOfficeExpense = () => {
        document.getElementById("loader").style.display = "block";
        const req_id = params.get("req_id");
        const area = params.get("area");
        axios.get(Config.apiserver+"donation/lcexpneseopenbymonth/"+userID+"?req_id="+req_id+"&area="+area)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setOfficeExpense(res.data.data);
                    setTotal(res.data.total);
                    console.log(res.data.data);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    useEffect(() => {
        callOfficeExpense();
    }, []);


    return (
        <Card title={"Expenses Details for the Month of "+CisUI().getMonth(userID)}>
            {/*{*/}
            {/*    isEdit === 1*/}
            {/*        ? CisUI().addAction("../../"+jsonfile.urls.list)*/}
            {/*        : CisUI().addAction("../"+jsonfile.urls.list)*/}
            {/*}*/}
            {CisUI().showLoading}

            <Row gutter={24}>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Company</th>
                        <th>Month</th>
                        <th>Account Name</th>
                        <th style={{textAlign: 'right'}}>Amount</th>
                        <th>Note</th>
                        <th>Attachment</th>
                        <th>Entry Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {officeExpnese.map((x, i) => {
                        return (
                            <>
                                <tr>
                                    <td>{x.company}</td>
                                    <td>{CisUI().getMonth(x.month)}</td>
                                    <td>
                                        {x.expense_type}
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        {CisUI().getCurrencyFormated1(x.amount)}
                                    </td>
                                    <td>{x.notes}</td>
                                    <td>{
                                        x.hasfile === 1 ?
                                            <>
                                                {
                                                   x.allattachment.map((files, fl)=> {
                                                       return <a href={Config.fileserver+""+files.location} target="_blank" className="btn btn-primary btn-sm btn-circle">
                                                           <i className="fa fa-file"></i>
                                                       </a>
                                                   })
                                                }
                                            </> :""
                                    }</td>
                                    <td>{CisUI().DateFormat(x.date)}</td>
                                </tr>
                            </>
                        );
                    })}

                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={3}>Total = </th>
                            <th style={{textAlign : 'right'}} >{CisUI().getCurrencyFormated1(total)}</th>
                        </tr>
                    </tfoot>

                </table>

            </Row>


        </Card>
    );
};

export default ExpenseDetails;