import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification} from 'antd';
import jsonfile from './requistion.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";

const {Option} = Select;

const NewPurchaseRequistion = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [bill, setBill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            vendor_address: "",
            vendor_city: "",
            vendor_state: "",
            vendor_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../vendor/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const ShippingMethod = SelectData("shippingmethod");


    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();


    if(loaded) {
        OrderDetails();
    }

    const OrderDetails = async () => {
        return await axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.podata;
                    //onCompanyChange(podata.company_id);
                    setVendor(res.data.vendors);
                    setPOdata(res.data.podata);
                    formRef.current.setFieldsValue({
                        company : podata.company_id,
                        address : podata.address,
                        city : podata.city,
                        state : podata.state,
                        zipcode : podata.zipcode,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    useEffect(() => {
        if(isEdit === 1) {
            //OrderDetails();
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
        else {
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
    }, []);


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
            //document.getElementById("input_com_address").value = "Test";
            formRef.current.setFieldsValue({
                address : (company.address_line_1 || "")+", "+(company.address_line_2 || ""),
                city : company.city || "",
                state : company.state || "",
                zipcode: company.zip_code || "",
            });
        }
    }

    const onCompanyChangeLocal = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            let company = {};
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                        company = res.data.company;
                        console.log("selected : "+ JSON.stringify(company));


                        console.log("selected : "+ JSON.stringify(company));
                        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
                        //document.getElementById("input_com_address").value = "Test";
                        let  fullAddress = "";
                        if(company.address_line_2 !== null) {
                            fullAddress += company.address_line_1 || "";
                        }
                        if(company.address_line_2 !== null) {
                            fullAddress += "," +company.address_line_2 || "";
                        }

                        formRef.current.setFieldsValue({
                            company_id: company.id,
                            address: fullAddress,
                            city: company.city || "",
                            state: company.state || "",
                            zipcode: company.zip_code || "",
                        });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }


    const appendChild = () => {
        let { data } = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data : data,
            rows : {
                code : null,
                name : null,
                qty : 0,
                reference : null
            }
        });

    };


    const removeRow = () => {
        let { data } = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "code") {
            rows[idx] = {
                code: value,
                name: rows[idx].name,
                qty: rows[idx].qty,
                reference: rows[idx].reference,
            };
        }
        else if(name === "name") {
            rows[idx] = {
                code: rows[idx].code,
                name: value,
                qty: rows[idx].qty,
                reference: rows[idx].reference,
            };
        }
        else if(name === "qty") {
            const amount = value * rows[idx].price;
            rows[idx] = {
                code: rows[idx].code,
                name: rows[idx].name,
                qty: value,
                reference: rows[idx].reference,
            };
        }
        else if(name === "reference") {
            //CalculateCell();
            const amount = value * rows[idx].qty;
            rows[idx] = {
                code: rows[idx].code,
                name: rows[idx].name,
                qty: rows[idx].qty,
                reference: value
            };

        }

        setState({
            rows
        });

        // let totals = 0
        // rows.map((item, idx) => {
        //     totals += item.amount;
        // });
        //
        // var total = totals;
        // var freight = bill.freight;
        // var taxp = bill.taxp;
        // var discount_p = bill.discount_p;
        // var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        // var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        // var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        // var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);
        //
        // setBill({
        //     total : total,
        //     grand_total : grandTotal,
        //     freight : freight,
        //     taxp : taxp,
        //     tax_amount : taxAmount,
        //     discount_p : discount_p,
        //     discount_amount : discount_amount
        // });

    };

    const handleAddRow = () => {
        const item = {
            code : null,
            name : null,
            qty : 0,
            reference : null
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    var totalAmount = 0;

    const CalculateCell = () => {
        var table = document.getElementById('itemtable');
        for (var r = 0, n = table.rows.length; r < n; r++) {
            var rowID = table.rows[r];
            var qty = rowID.cells[3].getElementsByTagName('INPUT')[0].value || 0;
            var price = rowID.cells[4].getElementsByTagName('INPUT')[0].value || 0;
            var total = parseInt(qty) * parseFloat(price);
            totalAmount += total;
            rowID.cells[4].getElementsByTagName('INPUT')[0].value = total;
        }
    }

    const formField = (

        <>
            <div className="row">
                <div className="col-md-12">
                    <Card title="Ship To (Department/Branch)">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="company"
                                        label="Department/Branch"
                                        initialValue={podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select a department/branch"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a department/branch"
                                            optionFilterProp="children"
                                            onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                Companies.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        initialValue={podata.address}
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Company Shipping Address"
                                            },
                                        ]}
                                    >
                                        <Input defaultValue={comp.address} value={comp.address} placeholder="Company Shipping Address" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        initialValue={podata.city}
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "City"
                                            },
                                        ]}
                                    >
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        label="Country"
                                        name="state"
                                        onChange={handleChange}
                                        initialValue={podata.state}
                                        rules={[
                                            {
                                                required: false,
                                                message: ""
                                            },
                                        ]}
                                    >
                                        <Input value={comp.state} placeholder="Country" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        label="Zip Code"
                                        name="zipcode"
                                        initialValue={podata.zipcode}
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Company Zip Code"
                                            },
                                        ]}
                                    >
                                        <Input value={comp.zipcode} placeholder="Company Zip Code" />
                                    </Form.Item>
                                </div>
                            </div>

                        </div>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="order_date"
                            label="Requisition Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Requisition date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Requisition Date" />
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="required_date"
                            label="Required By"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: false,
                                    message: "Required by date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Required by date" />
                        </Form.Item>

                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Requisition Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Item Code</th>
                            <th>Item Description</th>
                            <th>Reference/URL</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td style={{width : '150px'}}><input className="form-control" placeholder="Enter Item Code" name="code" value={state.rows[idx].code} onChange={ItemCellChange(idx)}   /></td>
                                <td><input className="form-control" placeholder="Enter Item Description" name="name" value={state.rows[idx].name} onChange={ItemCellChange(idx)}  /></td>
                                <td><input className="form-control" placeholder="Enter reference / URL" name="reference" value={state.rows[idx].reference} onChange={ItemCellChange(idx)}  /></td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Quantity" name="qty" value={state.rows[idx].qty} onChange={ItemCellChange(idx)}  /></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div onLoad={OrderDetails}></div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewPurchaseRequistion;