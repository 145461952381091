import React, { useState, useEffect } from "react";
import { Row, Col, Input, Card, Button, Table, Menu, Dropdown, Modal, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { CisUI } from "../../../../util/CISUI";
import axios from "axios";
import config from "../../../../util/config";
import jsonfile from "./product.json";
import IntlMessages from "../../../../util/IntlMessages";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";
const { Search } = Input;
const { Title } = Typography;
// const onSearch = (value) => console.log(value);

const columns = [
  {
    title: "SL",
    dataIndex: "sl",
    key: "sl",
  },
  {
    title: "Product Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Product SKU",
    dataIndex: "sku",
    key: "sku",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Sub Category",
    dataIndex: "subcategory",
    key: "subcategory",
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    key: "vendor",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
  },
];

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const [state, setState] = useState({
    reqURL: Config.apiserver + jsonfile.urls.list,
    filtervalue: '',
    subtitle: '',
  });

  const getproduct = () => {
    axios.get(config.apiserver + "stores/listProduct", CisUI().HeaderRequest)
        .then((res) => {
          console.log(res);
          setProducts(res.data.data)
        })
        .catch((errors) => console.log(errors));
  };

  useEffect(() => {
    getproduct();
  }, []);

  const onSearch = ((e) => {
    console.log(e.target.value)

    const salesproduct = [];

    products.map((product, i) => {
      if (product.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        salesproduct.push({
          key: product.id,
          sl: ++i,
          name: product.name,
          sku: product.sku,
          category: product.category_name,
          subcategory: product.subcategory_name,
          vendor: product.vendor_name,
          status: CisUI().getStatusLabel(product.status),
          action: (
              ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
          ),
        });
      }
    })
    setTableData(salesproduct)
  });


  const getTableData = () => {
    const data = [];

    products.map((product, i) => {
      data.push({
        key: product.id,
        sl: ++i,
        name: product.name,
        sku: product.sku,
        category: product.category_name,
        subcategory: product.subcategory_name,
        vendor: product.vendor_name,
        status: CisUI().getStatusLabel(product.status),
        action: (
            ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
        ),
      });
    });
    setTableData(data)
  }

  useEffect(() => {
    getTableData();
  }, [products]);

  const getExtra = (
    <>
      {CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", "customer_type", jsonfile, state.subtitle, "portrait")}
    </>
  );

  return (
    <>

      <Card title={"Products List"} extra={getExtra}>

        <Row className="list-header">
          <Col xs={{ span: 24 }} xl={{ span: 18 }}></Col>

          <Col xs={{ span: 24 }} xl={{ span: 6 }}>
            <Input className="list-header-search" placeholder="Search Product" prefix={<SearchOutlined />} onChange={onSearch} />
          </Col>
        </Row>
        <Table scroll={{ x: true }} columns={columns} dataSource={tableData} className="list-table" bordered pagination={{ position: ["bottomCenter"] }} />
      </Card>

    </>
  );
};

export default ProductList;


