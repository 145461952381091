import React from "react";
import Widget from "components/Widget/index";
import {Button, Col, Row} from "antd";
import LineIndicator from "./LineIndicator";
import {Link} from "react-router-dom";
import {CisUI} from "../../../util/CISUI";

const Portfolio = ({data = {receipt: 0, payment: 0, rp: 0, pp: 0}}) => {
  return (
    <Widget>
      <h2 className="h4 gx-mb-3">Current Month Voucher Status</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>

          {/*<div className="ant-row-flex">*/}
          {/*  <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium" style={{color: "#0E4675"}}>{CisUI().getCurrencyFormated1(receipt)}</h2>*/}
          {/*  <h4 className="gx-pt-2 gx-chart-up">Receipt</h4>*/}
          {/*</div>*/}
          {/*<div className="ant-row-flex">*/}
          {/*  <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium" style={{color: "#D68619"}}>{CisUI().getCurrencyFormated1(payment)}</h2>*/}
          {/*  <h4 className="gx-pt-2 gx-chart-up" style={{color: "#D68619"}}>Payment</h4>*/}
          {/*</div>*/}
          {/*<p className="gx-text-grey">Overall balance</p>*/}
          <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
            <Button className="gx-mr-2" type="primary"><Link to={"../../entries/voucherlist"}>Receipt</Link></Button>
            <Button className="gx-btn-cyan"><Link to={"../../entries/voucherlist"}>Payment</Link></Button>
          </div>

          <p className="gx-text-primary gx-pointer gx-d-none gx-d-sm-block gx-mb-1">
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> <Link to={"../../entries/singlevoucher"}>Create New Voucher</Link></p>
        </Col>
        
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="gx-site-dash">
            <h5 className="gx-mb-3">Voucher Summary</h5>
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
              <li>
                <LineIndicator width={data.rp+"%"} title="Total Receipt" title2={CisUI().getCurrencyFormated1(data.receipt)} color="primary" value={data.rp+"%"} />
              </li>
              <li>
                <LineIndicator width={data.pp+"%"} title="Total Payment" title2={CisUI().getCurrencyFormated1(data.payment)} color="red" value={data.pp+"%"}/>
              </li>
            </ul>
            <p className="gx-text-primary gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> <Link to={"../../entries/singlevoucher"}>Create New Voucher</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Widget>
  );
};

export default Portfolio;
