import React, {useState, useEffect} from "react";
import {Row, Col, Form, Card, Input, Select, notification, Button} from 'antd';
import jsonfile from './workinghistory.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import {CisUI} from "../../../../util/CISUI";
import SelectData from "../../../../util/selectdata";
import Config from "../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input
const WorkingHistory = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);

    const userID = props.empid;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    let AccountsLevel = SelectData("accounts/accountslevel");

    const [state, setState] = useState({
        rows: [
            {
                level: "",
                details: "",
                start_date : "",
                end_date : "",
                remark : ""
            }
        ]
    });

    let edit_data = [];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;

        userData.forEach((item, index) =>
            edit_data.push({
                level: item.level,
                details: item.details,
                start_date : item.start_date,
                end_date : item.end_date,
                remark : item.remark
            })
        )
    }

    useEffect(() => {
        if(isEdit === 1) {
            setState({
                rows: edit_data
            })
        }
    }, [userData]);

    const handleAdd = () => {
        // console.log("hi");
        const item = {
            level: "",
            details: "",
            start_date : "",
            end_date : "",
            remark : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = (idx) => e => {
        console.log(idx);

        const filteredArray = [...state.rows];
        // console.log(filteredArray.length)
        if(filteredArray.length == 1) {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "You can not delete this item."
            });
        } else {
            setState({
                rows: filteredArray.filter((item, index) => index !== idx)
            });
        }

    };

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "level") {
            rows[id] = {
                level: value,
                details: rows[id].details,
                start_date: rows[id].start_date,
                end_date: rows[id].end_date,
                remark: rows[id].remark
            };
        }
        else if(name === "details") {
            rows[id] = {
                level: rows[id].level,
                details: value,
                start_date: rows[id].start_date,
                end_date: rows[id].end_date,
                remark: rows[id].remark,
            };
        }
        else if(name === "start_date") {
            rows[id] = {
                level: rows[id].level,
                details: rows[id].details,
                start_date: value,
                end_date: rows[id].end_date,
                remark: rows[id].remark,
            };
        }
        else if(name === "end_date") {
            rows[id] = {
                level: rows[id].level,
                details: rows[id].details,
                start_date: rows[id].start_date,
                end_date: value,
                remark: rows[id].remark,
            };
        }
        else if(name === "remark") {
            rows[id] = {
                level: rows[id].level,
                details: rows[id].details,
                start_date: rows[id].start_date,
                end_date: rows[id].end_date,
                remark: value,
            };
        }

        setState({
            rows
        });

    }

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const onFinish = (values) => {
        values["working_history"] = state.rows;
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint + "/" + props.empid, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    // history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title={Title}>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="workinghistory" className="ptable table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Level</th>
                                    <th>Details</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width : '30px'}}>
                                            <a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                            {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                            {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                            {/*</Popconfirm>*/}
                                        </td>
                                        <td style={{width : '150px'}}>
                                            {/*<input type="text" className="form-control" placeholder="Level" name="add_level" value={state.rows[idx].type} onChange={handleAddChange(idx)} />*/}
                                            <select name="level" id="level" className="form-control" onChange={handleAddChange(idx)} value={state.rows[idx].level}>
                                                <option value="">Select An Option</option>
                                                {
                                                    AccountsLevel.map((items, index) =>
                                                        <option key={++index} value={items.id}>{items.name}</option>
                                                    )
                                                }

                                            </select>

                                        </td>
                                        <td style={{width : '100px'}}><textarea className="form-control" name="details" value={state.rows[idx].details} onChange={handleAddChange(idx)} /></td>
                                        <td style={{width : '100px'}}><input type="date" className="form-control" name="start_date" value={state.rows[idx].start_date} onChange={handleAddChange(idx)} /></td>
                                        <td style={{width : '100px'}}><input type="date" className="form-control " name="end_date" value={state.rows[idx].end_date} onChange={handleAddChange(idx)} /></td>
                                        <td style={{width : '100px'}}><textarea className="form-control " name="remark" value={state.rows[idx].remark} onChange={handleAddChange(idx)} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>

                    </Form>

                </div>
            </div>
        </Card>
    );
}

export default WorkingHistory;