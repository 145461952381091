import React, {useState} from "react";
import { Form, Col, Input,Card, Collapse} from 'antd';
import jsonfile from './sales.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const OverDueInvoice = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.overdue,
        filtervalue : '',
        subtitle:''
    });


    const headers = {
        id: {
            invisible: true
        },
        company_id: {
            invisible: true
        },
        paid: {invisible: true},
        customer_id: {invisible: true},
        beneficiary_id: {invisible: true},
        beneficiary_source: {invisible: true},
        customer_name: {invisible: true},
        shipping_method: {invisible: true},
        sales_type: {invisible: true},
        vendor_id: {
            invisible: true
        },
        beneficiary_name: {
            text: <IntlMessages id="Beneficiary"/>,
            sortable: true,
            filterable: false
        },
        invoice_number: {
            text: <IntlMessages id="Invoice Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"salesdetails/"+row.id}>{value}</Link>
            )
        },
        required_by_date: {
            text: <IntlMessages id="Required By"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        due_date: {
            text: <IntlMessages id="Due Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        total: {
            text: <IntlMessages id="Gross Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        sales_tax: {
            text: <IntlMessages id="Sales Tax"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        freight: {
            text: <IntlMessages id="Freight"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        discount: {
            text: <IntlMessages id="Discount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        grand_total: {
            text: <IntlMessages id="Grand Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        paid_amount: {
            text: <IntlMessages id="Paid"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        due: {
            text: <IntlMessages id="Due"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getOrderStatus(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.overdue+"/?"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {/*{CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, 'data', 'purchase_order', jsonfile, state.subtitle, "landscape")}*/}
        </>
    );

    return (
        <>
            {searchView}
            <Card title="Over Due Invoice List" extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    // withToggles
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default OverDueInvoice;