import {Form, Row, Col, Radio, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const MultipleVoucherDetails = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [company, setCompany] = useState({});
    const [user, setUser] = useState({});
    const [state, setState] = useState([]);
    const [singlestate, setSingleState] = useState({});
    const [voucherType, setvoucherType] = useState("");
    const [summary, setSummary] = useState({
        receipt : 0,
        payment : 0
    });

    const poid = props.match.params.id;
    const isEdit = 1;

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver +jsonfile.urls.mview+"/"+ poid)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState(res.data.data);
                    setCompany(res.data.company);
                    setUser(res.data.user);
                    setSingleState(res.data.data[0]);
                    setSummary({
                        receipt: res.data.totalReceipt,
                        payment: res.data.totalPayment,
                    });
                    if(res.data.data[0]["debit"] > 0) {
                        setvoucherType("Receipt Voucher");
                    }
                    else {
                        setvoucherType("Payment Voucher");
                    }

                    //onPrintClick();

                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }


    let totalPaid = 0;

    const onPrintClick = () => {
        window.print();
    }


    return (
        <Card title={"Voucher Details"} extra= {
            <>
                {/*<Button id="submitbuttons" type="primary" htmlType="button">*/}
                {/*    <Link to={"../voucheredit/"+poid}>Edit</Link>*/}
                {/*</Button>*/}
                {/*<Button id="submitbuttons" type="primary" htmlType="button">*/}
                {/*    <Link to={"../voucheredit/"+poid}>Print</Link>*/}
                {/*</Button>*/}
                {/*{*/}
                {/*    isEdit === 1*/}
                {/*        ? CisUI().addAction('../../' + jsonfile.urls.list)*/}
                {/*        : CisUI().addAction('../' + jsonfile.urls.list)*/}
                {/*}*/}

                <div className="row no-print">
                    <Link to={isEdit === 1 ? "../../"+jsonfile.urls.list : "../"+jsonfile.urls.list} className="btn btn-export-excel btn-circle-md" title="List">
                        <i className="fas fa-list"/>
                    </Link>
                    <button type={"button"} onClick={onPrintClick} className="btn btn-export-excel btn-circle-md" title="Print">
                        <i className="fas fa-print"/>
                    </button>
                    {
                        singlestate.is_multy === 1 ?

                            <Link
                                to={isEdit === 1 ? "../../" + jsonfile.urls2.edit + "/" + singlestate.id : "../" + jsonfile.urls.edit + "/" + singlestate.id}
                                className="btn btn-export-excel btn-circle-md" title="Edit">
                                <i className="fas fa-edit"/>
                            </Link> : <Link
                                to={isEdit === 1 ? "../../" + jsonfile.urls.edit + "/" + singlestate.id : "../" + jsonfile.urls.edit + "/" + singlestate.id}
                                className="btn btn-export-excel btn-circle-md" title="Edit">
                                <i className="fas fa-edit"/>
                            </Link>
                    }
                </div>

            </>
        }>

            {CisUI().showLoading}

            <div className="no-print row" style={{display: 'none'}}>
                <div className="col-md-12" style={{textAlign: "right"}}>
                    <a target="_blank" href={Config.baseurl+"/voucherprint.html?apiurl="+window.btoa(Config.apiserver +jsonfile.urls.mview+"/"+ poid)} className="ant-btn ant-btn-primary">
                        <i className="fa fa-print"></i> Print
                    </a>
                </div>
            </div>

            <div className="receipt-area" style={{overflow: "auto"}}>
                <div className="receipt-content">
                    <div className="invoice_header">
                        <div className="voucher-details">
                            <h1>{voucherType}</h1>
                            <div className="details">
                                <div className="title1">
                                    <div><strong>Voucher#</strong>: {singlestate.voucher_no}</div>
                                    <div><strong>Date</strong>: {CisUI().DateFormat(singlestate.date)}</div>
                                    <div><strong>Account (Cash/Bank)</strong>: {singlestate.cashaccount_number}-{singlestate.cashaccount}
                                        {
                                            singlestate.bank_account_name !== "" ? <> ({singlestate.bank_account_name}
                                            {
                                                singlestate.bank_name !== "" ? singlestate.bank_name : <></>
                                            }
                                            )</> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="logo" style={{textAlign : 'center'}}>
                            <img src="/assets/images/poc_logo.png" alt="POC" />
                            <h3>The Church of Pentecost</h3>
                            <h3>{company.name || ""}</h3>
                        </div>
                    </div>
                    <div>
                        <table className="product-list">
                            <thead>
                            <tr>
                                <th>Particulars</th>
                                <th style={{width: '150px', textAlign : 'right'}}>Receipt({CisUI().getCurrencySymbol()})</th>
                                <th style={{width: '150px', textAlign : 'right'}}>Payment({CisUI().getCurrencySymbol()})</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                state.map((items,index) => {
                                    return <tr>
                                        <td>
                                            <strong>{items.account_number} - {items.account_name}</strong><br/><strong>Beneficiary:</strong> {items.beneficiary}
                                            <br/><strong>Narration : </strong> {items.details}
                                        </td>
                                        <td style={{width: '150px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit || 0)}</td>
                                        <td style={{width: '150px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit || 0)}</td>
                                    </tr>
                                })
                            }

                            </tbody>
                            <tfoot>
                            <tr>
                                <td></td>
                                <td className="table-footer" style={{textAlign : 'right'}}>{CisUI().getCurrencySymbol()} {CisUI().getCurrencyFormated1(summary.receipt || 0)}</td>
                                <td className="table-footer" style={{textAlign : 'right'}}>{CisUI().getCurrencySymbol()} {CisUI().getCurrencyFormated1(summary.payment || 0)}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="benficiaryTable">
                                <tbody>
                                <tr>
                                    {/*<th><strong>{singlestate.debit > 0 ? "Received From " : "Paid To "}</strong> : {singlestate.beneficiary}</th>*/}
                                    <th><strong>Remarks/Payee</strong> : {singlestate.notes}</th>
                                    <th><strong>By Check No</strong> : {singlestate.reference}</th>
                                    <th><strong>Dated</strong> : {CisUI().DateFormat(singlestate.date)}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <span><strong>{singlestate.debit > 0 ? "Received By " : "Paid By "}</strong> : </span>
                            <table className="benficiaryTable">
                                <tbody>
                                <tr>
                                    {/*<th><strong>Entered By : </strong> :  /!*{singlestate.username}*!/ </th>*/}
                                    <th><strong>Prepared By</strong> : {user.hasOwnProperty("name") ? user["name"] : ""}</th>
                                    {/*<th><strong>Authorized By</strong> : </th>*/}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="invoice_footer">
                        <p style={{textAlign : 'center'}}>
                            {company.name || ""}
                            {/*<br/>{(company.address_line_1 ||"")+","+(company.address_line_2 || "")+","+(company.city || "")+","+(company.state || "")+","+(company.country || "")}<br />*/}
                            {/*Phone # : {company.phone1 || ""}<br/>*/}
                            {/*Email : {company.email || ""}<br/>*/}
                            {/*Website : {Config.website || ""}<br/>*/}
                        </p>
                    </div>
                </div>
            </div>



        </Card>
    );
};

export default MultipleVoucherDetails;