import {Form, Row, Col, Card, Select,notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './chartofaccount.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import $ from "jquery";
import SelectData from "../../../../util/selectdata";
import CircularProgress from "../../../../components/CircularProgress";

const { Option } = Select;

const NewChartOfAccounts = (props) => {

    const formRef = React.createRef();
    const [loading, setLoading] = useState([]);

    const AccountsLevel = SelectData("accounts/accountslevel");
    const AllAccounts = SelectData("accounts/allaccountsbylevel");

    const [accounts, setAccounts] = useState([]);

    const [form] = Form.useForm();

    const history = useHistory();

    const handleChange=(e)=> {
        // setState({
        //     [e.target.id]: e.target.value
        // })
        console.log(e.target.value);
    }


    const userID = props.match.params.id;
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let userData = [];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);

        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    const onFinish = (values) => {
        //setLoading({loading:true})
        console.log("Input Data : "+values);
        let msg = "Sorry! request not processed, please try again";

        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });

                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    };

    // $("#input_parent_account_number").change(function(){
    //     const vl = document.getElementById("#input_parent_account_number").value;
    //     console.log(vl);
    // });

    $('#input_parent_account_number').unbind('keyup');
    $('#input_parent_account_number').bind('keyup', function (e) {
        const parent = this.value;
        const level = formRef.current.getFieldValue("act_level");
        if(level === null) {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Please select a Account Level"
            });
        }

        if(parent.length === 5) {
            CisUI().LoadingOn();
        }

    });



    const onLevelChange = value => {
        setAccounts(AllAccounts[value]);
    };

    // $("#input_parent_account_number").keyup(function () {
    //     console.log(this.value);
    // });

    //console.log("UserData : "+userData);

    const ParentAccount = (
        userData == "" && isEdit == 1 ? <CircularProgress/> : (
            <>
        <Col lg={12} md={12} sm={12} xs={24} id="parents" >
            <div className="gx-form-row0">
                <Form.Item
                    name="parent_id"
                    label="Parent Account Number (If it is subsidiary account)"
                    initialValue={userData.parent_id}
                    rules={[
                        {
                            required: false,
                            message: "Select a Parent Account"
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select a Parent Account"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            CisUI().getUserInfo('com_id') > 0 ?
                                AllAccounts.map((items, index) =>
                                    <Option key={++index} value={items.id} >{items.name}</Option>
                                )
                                :
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id} >{items.name}</Option>
                                )
                        }
                    </Select>
                </Form.Item>

            </div>
        </Col>
                </> )
    )

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >

                <Row gutter={24}>
                    {
                        CisUI().getUserInfo('area') >= 26 ? <></> :
                        <Col lg={12} md={12} sm={12} xs={24} >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="act_level"
                                    label="Access Level"
                                    initialValue={userData.act_level}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a level"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a level"
                                        optionFilterProp="children"
                                        onChange={onLevelChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            AccountsLevel.map((items, index) =>
                                                <Option key={++index} value={items.id} >{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>
                    }

                    {ParentAccount}

                </Row>

                {InputFields(jsonfile.input,userData,isEdit)}



            </Form>


        </Card>
    );
};


export default NewChartOfAccounts;