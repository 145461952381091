import React, {useState} from "react";
import {Form, Card, Input, Checkbox, Select, Col, Row, Button, notification} from 'antd';
import jsonfile from './administration.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import {CisUI} from "../../../../util/CISUI";
import InputFieldV2 from "../../../../util/InputFieldV2";
import SelectData from "../../../../util/selectdata";
import Config from "../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input;

const Administration = (props) => {
	
	console.log(props);
	
	const history = useHistory();
	const [ form ] = Form.useForm();
	const [loading, setLoading] = useState([]);
	const States = SelectData("usstates");
	
	const userID = props.empid;
	const editID = props.editEmpId
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(editID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add+"/"+userID;
	let redirectto = "../"+jsonfile.urls.list;
	
	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+editID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+editID;
		redirectto = "../../"+jsonfile.urls.list;
	}
	
	
	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		const data = {
			...values,
			'dob': values['dob'].format("YYYY-MM-DD")
		}
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}
	
	return (
		<Card title={Title}>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{InputFieldV2(jsonfile.input,userData,isEdit,props)}
				
				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
					</Col>
				</Row>
			
			</Form>
		
		</Card>
	);
}

export default Administration;