import React, {useState, useEffect} from "react";
import { Form,Card, Collapse} from 'antd';
import jsonfile from './asset_maintenance.json';
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const AssetMaintenanceList = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        company_name: {
            text: <IntlMessages id="Company Name"/>,
            sortable: true,
            filterable: true,
        },
        asset_name: {
            text: <IntlMessages id="Asset Name"/>,
            sortable: true,
            filterable: true,
        },
        last_date: {
            text: <IntlMessages id="Last Maintenance Date"/>,
            sortable: true,
            filterable: true,
        },
        next_date: {
            text: <IntlMessages id="Next Maintenance Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormat(value)
            )
        },
        current_condition: {
            text: <IntlMessages id="Current Condition"/>,
            sortable: true,
            filterable: true,
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = (values) => {
        // console.log('Received values of form: ', values);
        let data = { ...values };
        if(values.from_date != null) {
            data = {
                ...values,
                'from_date': values['from_date'].format("YYYY-MM-DD")
            }
        }

        if(values.to_date != null && values.from_date != null) {
            data = {
                ...values,
                'from_date': values['from_date'].format("YYYY-MM-DD"),
                'to_date': values['to_date'].format("YYYY-MM-DD")
            }
        }

        if(values.to_date != null && values.from_date == null) {
            data = {
                ...values,
                'to_date': values['to_date'].format("YYYY-MM-DD")
            }
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        let qsup = qs.replaceAll("undefined","");
        let qsup1 = qsup.replaceAll("null", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup1);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup1;
        setState({reqURL: newURL});
    };

    // const handleOnFilter = ({ target: { name, value } }) => {
    //     //filterValue = value;
    //     console.log("filteringwith :"+ value);
    //     setState({filtervalue : "test"});
    // }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        {AdvancedSearch(jsonfile.searchInputs)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'asset_maintenance', jsonfile, state.subtitle, 'landscape')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default AssetMaintenanceList;