import React, {useEffect, useState} from "react";
import CustomScrollbars from 'util/CustomScrollbars'
import Auxiliary from "util/Auxiliary";
import {Button, Row, Col, notification} from "antd";
import {Link} from "react-router-dom";
import axios from "../../util/Api";
import Config from "../../util/config";
import {CisUI} from "../../util/CISUI";

const Shortcuts = () => {
    const [data,setMenu] = useState([]);

    const getMentuItems = () => {
        document.getElementById("loader").style.display = "block";
        axios
            .get(Config.apiserver + "myfav", null, CisUI().HeaderRequest)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res);
                if (res.data.status === 1) {
                    setMenu(res.data.data);
                }
            })
            .catch((errors) => {
                // console.log(errors.response.data.errors);
               // console.log(errors);
                document.getElementById("loader").style.display = "none";
            });
    };


    useEffect(() => {
        getMentuItems();
    }, []);

    return (
        <Auxiliary style={{width: '100%'}}>
            <div className="gx-popover-header" style={{width: '100% !important'}}>
                <h3 className="gx-mb-0">Favorites</h3>
                <i className="gx-icon-btn icon icon-charvlet-down"/>
            </div>
            <CustomScrollbars className="gx-popover-scroll" style={{width: '100% !important'}}>
                {CisUI().showLoading}
                <div style={{padding: '30px', width: '100% !important'}}>
                    <div className="row">
                        {/*{*/}
                        {/*    data.map((items, index) => {*/}
                        {/*        return <div className="col-md-2">*/}
                        {/*            <p className="gx-fs-sm gx-text-uppercase">*/}
                        {/*                <strong>{items.name}</strong>*/}
                        {/*            </p>*/}
                        {/*            <ul className="gx-list-group" style={{paddingLeft: '10px'}}>*/}
                        {/*                {*/}
                        {/*                    items.submodule.map((sub, indx) => {*/}
                        {/*                        return <>*/}
                        {/*                            {*/}
                        {/*                                sub.menu.map((mnu, mi) => {*/}
                        {/*                                    return <li>*/}
                        {/*                                                            <span>*/}
                        {/*                                                                <Link*/}
                        {/*                                                                    to={"../" + items.urlkey + "/" + mnu.urlkey}><i*/}
                        {/*                                                                    className="fa fa-dot-circle"></i> {mnu.name}</Link>*/}
                        {/*                                                            </span>*/}
                        {/*                                    </li>*/}
                        {/*                                })*/}
                        {/*                            }*/}
                        {/*                        </>*/}
                        {/*                    })*/}
                        {/*                }*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    })*/}
                        {/*}*/}
                        {
                            data.map((items, index) => {
                                return <>
                                    {
                                        items.submodule.map((sub, indx) => {
                                            return <>
                                                {
                                                    sub.menu.map((mnu, mi) => {
                                                        return <div className={"col-md-2 col-sm-6 col-sx-12"}>
                                                                                    <span>
                                                                                        <Link
                                                                                            to={"../" + items.urlkey + "/" + mnu.urlkey}><i
                                                                                            className="fa fa-dot-circle"></i> {mnu.name}</Link>
                                                                                    </span>
                                                        </div>
                                                    })
                                                }
                                            </>
                                        })
                                    }
                                </>
                            })
                        }

                    </div>
                </div>
            </CustomScrollbars>
        </Auxiliary>
    )
};

export default Shortcuts;

