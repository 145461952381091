import React, {useEffect, useState,} from "react";
import {Form, Upload, DatePicker, message, Button, Card, notification, Col, Select, Input} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";

import { UploadOutlined } from '@ant-design/icons';
import * as XLXS from "xlsx";
import SelectData from "../../../../util/selectdata";
import moment from "moment/moment";
const Option = Select.Option;

const Importjournal_new = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [submitted, setSubmitted] = useState(0);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    const [ttl, setTTL] = useState(
        {
            totalDebit: 0,
            totalCredit: 0
        }
    );
    // const [data, setData] = useState([]);
    const [total, setTotal] = useState({
        debit : 0,
        credit : 0
    });
    const [uploading, fileList] = useState([]);
    const [dataobject,setDataObject] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [vendor, setVendor] = useState([]);

    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);


    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        area: [],
        district: [],
        local: [],
        committee: [],
        subsector: []

    });

    const Companies = SelectData("company/companylists");
    const Area = SelectData("allarea");
    const District = SelectData("alldistrict");
    const Local = SelectData("alllocal");

    const comID = CisUI().getUserInfo('com_id');

    useEffect(() => {
        if (comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : " + value);
        if (value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setCashAccount(res.data.cashaccount);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            // area: res.data.area,
                            // district: res.data.district,
                            // local: res.data.local
                        });

                        const JournalID = (parseInt(res.data.journalID || 0) + 1);
                        form.setFieldValue("thread","J"+JournalID);

                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            form.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }



    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    // let Title = jsonfile.addtitle;
    // let endpoint = jsonfile.urls.add;
    // let redirectto = "../"+jsonfile.urls.list;

    // if(isEdit === 1 ) {
    //     userData = RemoteJson(jsonfile.urls.view+"/"+userID);
    //     Title = jsonfile.edittitle;
    //     endpoint = jsonfile.urls.edit+"/"+userID;
    //     redirectto = "../../"+jsonfile.urls.list;
    // }

    let newArray = [];

    const onChangeExcel = (e) => {
        let file = e.target.files[0];
        setDataObject([]);
        let debitT = 0;
        let creditT = 0;
        if(file !== "") {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet') {
               // console.log(file);
                document.getElementById("loader").style.display = "block";
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLXS.read(bufferArray, {type: 'buffer'});
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data1 = XLXS.utils.sheet_to_json(ws);
                    // console.log(data1);
                    // setData(data1);

                    const subsChars = o => Object.fromEntries(Object.entries(o).map(
                        ([k, v]) => [k.replace(/[ \W]/g, m => m === ' ' ? '_' : '').toLowerCase(), v]
                    ))
                    newArray = data1.map(subsChars)
                    // document.getElementById("testResult").innerText = JSON.stringify(newArray);
                    let newObject = [];
                    newArray?.map((dt, index) => {

                            let allow = 0;
                            if ((dt.debit !== undefined)) {
                                allow = 1;
                            }

                            if ((dt.credit !== undefined)) {
                                allow = 1;
                            }

                            if (allow === 1) {
                                if (dt.subaccount !== undefined) {
                                    //console.log(dt);
                                    var dx = dt.subaccount.split("-");
                                    var bf = dt.beneficiary || "";
                                    // console.log(bf);
                                    // console.log(bf.toLowerCase());
                                    var bfs = Benificiary.filter((itm) => itm.name.toLowerCase() === bf.toLowerCase());
                                    // console.log(JSON.stringify(bfs));
                                    var bf_id = null;
                                    if(bfs.length > 0) {
                                        bf_id = bfs[0].id;
                                    }
                                    let newd = {
                                        'account_number': dx[0] || "",
                                        'account_name': dx[1] || "",
                                        'description': dt.description || "",
                                        'beneficiary': dt.beneficiary || "",
                                        'bf_id': bf_id,
                                        'beneficiary_id': null,
                                        // 'functional_expenses': dt.functional_expenses || "",
                                        // 'restriction_type': dt.restriction_type || "",
                                        'debit': dt.debit || 0,
                                        'credit': dt.credit || 0,
                                    };

                                    newObject.push(newd);
                                }
                            }

                        }
                    );

                    newObject.map((item,index) => {
                        debitT += item.debit;
                        creditT += item.credit;
                    });

                    //console.log("AllData : " + JSON.stringify(newObject));
                    // console.log("credit : " + creditT);
                    setTotal({
                        debit: debitT.toFixed(2),
                        credit: creditT.toFixed(2),
                    });

                    if(debitT > 0) {
                        if(debitT.toFixed(2) === creditT.toFixed(2)) {
                            document.getElementById("msg").style.color = "green";
                            document.getElementById("msg").innerText = "";
                        }
                        else {
                            console.log("Debit "+ debitT.toFixed(2));
                            console.log("Credit "+ creditT.toFixed(2));
                            // console.log("Not Equal");
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "Total debit and credit value is not same, please check in your file";
                        }
                    }
                    else {
                        if(newObject.length > 0) {
                            console.log("Not Worked")
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "Total debit and credit value is not same, please check in your file";
                        }
                        else {
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "No valid data found, please check your file";
                        }
                    }


                    setDataObject(newObject);

                    document.getElementById("loader").style.display = "none";

                }
            } else {
                setTotal({
                    debit: 0,
                    credit: 0,
                });
                message.error('You can only upload XlXS(Only Excel File) formated file!');
                document.getElementById("loader").style.display = "none";
            }

        }
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {

        // let msg = "Sorry! request not processed, please try again";
        values["data"] = dataobject;
        // console.log(JSON.stringify(values));

        if(total.debit > 0 && (total.debit === total.credit)) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver+"accounts/journalimportv2", values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        // setSubmitted(1);
                        // setDataObject(res.data.result);

                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                        history.push("journaldetails/"+res.data.thread);
                    }
                    else if (res.data.status === 3) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        // setSubmitted(1);
                        // setDataObject(res.data.result);
                        document.getElementById("msg").style.color = "red";
                        document.getElementById("msg").innerText = res.data.msg;
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    if(document.getElementById("loader")) {
                        document.getElementById("loader").style.display = "none";
                    }
                    console.log(errors);
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Total debit and credit are not same, please check your file."
            });
        }
    };


    const handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        this.setState({
            uploading: true,
        });
        // You can use any AJAX library you like
        fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(() => {
                this.setState({
                    fileList: [],
                });
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                this.setState({
                    uploading: false,
                });
            });
    };

    const upld = {
        onRemove: file => {
            this.setState(state => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    fileList: newFileList,
                };
            });
        },
        beforeUpload: file => {
            this.setState(state => ({
                fileList: [...state.fileList, file],
            }));
            return false;
        },
        fileList,
    };

    const token = localStorage.getItem('token');

    const Benificiary = SelectData("accountbenificary");
    const [benificiariesData, setBenificiaryData] = useState([]);
    let BenificaryData = [];

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        } else if (e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        } else if (e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        } else if (e === 6) {
            BenificaryData = beneficiaryData.department;
            setBenificiaryData(beneficiaryData.department);
        } else if (e === 7) {
            BenificaryData = Area;
            setBenificiaryData(Area);
        } else if (e === 8) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 9) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            BenificaryData = beneficiaryData.ministry;
            setBenificiaryData(beneficiaryData.ministry);
        } else if (e === 11) {
            BenificaryData = beneficiaryData.pentsos;
            setBenificiaryData(beneficiaryData.pentsos);
        } else if (e === 12) {
            BenificaryData = District;
            setBenificiaryData(District);
        } else if (e === 13) {
            BenificaryData = Local;
            setBenificiaryData(Local);
        }
        else if (e === 14) {
            BenificaryData = [];
            setBenificiaryData([]);
        }
        else if (e === 15) {
            BenificaryData = beneficiaryData.subsector;
            setBenificiaryData(beneficiaryData.subsector);
        }

        form.setFieldsValue({
            benificiary_id: null
        });
        return "";

    }

    // const formData = new FormData();
    // const uplod = {
    //     // name: 'file',
    //     // action: Config.apiserver+"settings/openingbalance",
    //     // headers: {
    //     //     'Authorization': token,
    //     // },
    //     // data : {
    //     //   'file' : formData
    //     // },
    //     beforeUpload(file) {
    //         console.log(file)
    //         // let file = e.target.files[0];
    //         let reader = new FileReader();
    //         reader.readAsArrayBuffer(file);
    //
    //         reader.onload = (e) => {
    //             const bufferArray = e.target.result;
    //             const wb = XLXS.read(bufferArray, {type:'buffer'});
    //             const wsname = wb.SheetNames[0];
    //             const ws = wb.Sheets[wsname]
    //             const data = XLXS.utils.sheet_to_json(ws)
    //
    //             // console.log(data)
    //
    //             // resolve(data)
    //
    //             setData(data)
    //         }
    //         // const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet';
    //         // if (!isJpgOrPng) {
    //         //     message.error('You can only upload XlXS(Only Excel File) formated file!');
    //         // }
    //         // return isJpgOrPng;
    //     },
    //     onChange(info) {
    //         formData.append('file', info.file)
    //         if (info.file.status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === 'done') {
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     }
    // }


    const ItemCellChange = (idx,cname="") => e => {
        let name = "";
        let value = null;
        if(cname === "") {
            name = e.target.name;
            value = e.target.value;
        }
        else {
            name = cname;
            value = e;
        }

        const rows = [...dataobject];
        // console.log("selectedValued:" + e);
        if (name === "beneficiary_source") {
            onBenificaryChange(value);
            rows[idx] = {
                account_number: rows[idx].account_number,
                account_name: rows[idx].account_name,
                description: rows[idx].description,
                beneficiary_source : value,
                bf_id : value,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }
        else if (name === "beneficiary_id") {
            rows[idx] = {
                account_number: rows[idx].account_number,
                account_name: rows[idx].account_name,
                description: rows[idx].description,
                beneficiary_source : rows[idx].beneficiary_source,
                bf_id : rows[idx].bf_id,
                beneficiary_id : value,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }

        setDataObject(rows);

    };


   // console.table(data);
    // console.log("Obj: " ,dataObj)
    

    const getRemark = (text) => {
        if(text === "Success") {
            return <span style={{color: 'green'}}>{text}</span>;
        }
        return text;
    }

    return (
        <Card title={"Import Journal"}>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h3>INSTRUCTIONS :</h3>
                        <div>
                            {
                                CisUI().getUserInfo('comarea') === 3 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://finapi.penterp.com/public/upload/tempfile/journal_template.xlsx" target="_blank">Click to Download</a><br />
                                    </>
                                    :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 4 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://finapi.penterp.com/public/upload/tempfile/journal_template.xlsx" target="_blank">Click to Download</a><br />
                                    </>
                                :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 5 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://finapi.penterp.com/public/upload/tempfile/journal_template.xlsx" target="_blank">Click to Download</a> <br />
                                    </>
                                    :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 6 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://finapi.penterp.com/public/upload/tempfile/journal_template.xlsx" target="_blank">Click to Download</a> <br />
                                    </>
                                    :""
                            }
                        </div>
                        <div>
                            2. Enter your debit or credit balance on excel file <br />
                            3. Verify that the total debit and total credit are the same.<br />
                            4. Once done, use the “Click to Upload” option to load. <br />

                            <div className="row" style={{margin: '20px'}}>
                                <div className="col-md-4">
                                    <Form.Item
                                        name="date"
                                        label="Date"
                                        initialValue={moment()}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Select a date"
                                            },
                                        ]}
                                    >
                                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                    placeholder="Date"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-4">

                                    <Form.Item
                                        name="thread"
                                        label="Entry No"
                                        // initialValue={"J"+Math.floor(Date.now() / 1000)}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Entry number is required"
                                            },
                                        ]}
                                    >
                                        <Input readOnly placeholder="Enter a number"/>
                                    </Form.Item>

                                </div>

                                <div className="col-md-4">

                                    {/*<Upload{...uplod}>*/}
                                    {/*    <Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
                                    {/*</Upload>*/}
                                    <div style={{fontWeight: 'bold', paddingBottom: '10px'}}>Choose your Excel File</div>
                                    <input type="file" onChange={onChangeExcel}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12" style={{textAlign : 'center'}}>
                                    <h3 style={{color: 'red'}} id="msg"></h3>
                                </div>
                            </div>

                            <div id="testResult"></div>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Account Code</th>
                                        <th>Account Name</th>
                                        <th>Description</th>
                                        <th>Beneficiary</th>
                                        <th style={{textAlign : 'right !important'}}>Debit</th>
                                        <th style={{textAlign : 'right !important'}}>Credit</th>
                                        {
                                            submitted === 1 ? <th>Result</th> : ""
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    dataobject.map((dt, idx) =>
                                        <>
                                            <tr>
                                                <td>{dt.account_number}</td>
                                                <td>{dt.account_name}</td>
                                                <td>{dt.description}</td>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a beneficiary source"
                                                        optionFilterProp="children"
                                                        style={{width : '100%'}}
                                                        onChange={ItemCellChange(idx,"beneficiary_source")}
                                                        value={dt.bf_id}
                                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                                    >
                                                        {
                                                            Benificiary.map((items, index) =>
                                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                    <br /> <br />
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a beneficiary"
                                                        optionFilterProp="children"
                                                        style={{width : '100%'}}
                                                        onChange={ItemCellChange(idx,"beneficiary_id")}
                                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                                    >
                                                        {
                                                            benificiariesData.map((items, index) =>
                                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                                            )
                                                        }
                                                    </Select>

                                                </td>
                                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(dt.debit ?? 0)}</td>
                                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(dt.credit ?? 0)}</td>
                                                {
                                                    submitted === 1 ? <th style={{paddingLeft : '10px'}}>{getRemark(dt.remark)}</th> : ""
                                                }
                                            </tr>
                                        </>
                                    )
                                }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={4}>Total</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.debit)}</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.credit)}</th>
                                        {
                                            submitted === 1 ? <th></th> : ""
                                        }
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
                <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                    {
                        total.debit > 0 ? total.debit === total.credit ? submitted === 0 ?
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button> : "" : "" : ""
                    }
                </Col>
            </Form>
        </Card>
    );
};

export default Importjournal_new;