import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card} from 'antd';
import jsonfile from './weeklyreport.json'
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from '../../../../util/Api'

const Panel = Collapse.Panel;

const LocalWeeklyReport = () => {
    const value = 0;
    const formRef = React.createRef();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.weekly,
        filtervalue : '',
        reportTitle : 'Weekly Report',
        reportData : [],
        totalAmount : 0
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.weekly
            }
        );
    }, []);

    const history = useHistory();

    const headers = {
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => {
                return CisUI().getCurrency(value);
            }
        }
    };

    const orderedHeaders = jsonfile.listView.weeklytitle;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    return `${key}=${CisUI().ParseDateFormat(values[key])}`
                }
                else if(key === 'week') {
                    const wk = formRef.current.getFieldValue('week');
                    //console.log("Selected Week : "+ wk);
                    wkd = CisUI().getWeekRange(formRef.current.getFieldValue('week'));
                    return `${key}=${wkd}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.weekly+"?"+qsup;
        console.log("NEURL :" + newURL);
        const WKR = CisUI().getWeekRangeLocal(wkd);
        const reportTitle = "Weekly Report between "+WKR[0]+" and "+WKR[1];

        const url = Config.apiserver+jsonfile.urls.weekly;

        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setState({
                        totalAmount : res.data.total,
                        reqURL: newURL,
                        reportTitle: reportTitle
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",0,'week')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", "local_weekly_report", jsonfile, state.subtitle, "landscape")}
        </>
    );

    const ReportData = state.reportData;

    return (
        <>
            {searchView}
            <Card title={state.reportTitle} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

            <div style={{textAlign : 'center', fontSize : '20px', display : 'none'}}>
                Total : {CisUI().getCurrencyFormated1(state.totalAmount)}
            </div>

            </Card>
        </>
    );
};

export default LocalWeeklyReport;