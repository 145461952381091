import React, {useState} from "react";
import {Form, Col, Input, Collapse, Card, notification} from 'antd';
import jsonfile from './income.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import moment from "moment";

const Panel = Collapse.Panel;

const IncomeStatement = () => {
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.listv2,
        filtervalue: '',
        subtitle: '',
        period: "For the end of " + CisUI().getMonthTitle(moment().format("YYYY-12-31")),
        currentYear: "as of " + CisUI().getMonthTitle(moment().format("YYYY-12-31")),
        lastYear: '',
        reporttype: "periodical"
    });

    const [dateset,setDate] = useState("");
    const [header, setHeader] = useState("");
    const [period, setPeriod] = useState("");

    const [hide, setHide] = useState([]);
    const [company, setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data, setData] = useState([]);
    const [resp, setResp] = useState({
        status: 0,
        msg: "General Ledger",
        company: {},
        incomes: [],
        expenses: [],
        total_income: 0,
        total_expense: 0,
        profit: 0,
        beginning_balance: 0,
        ending_balance: 0
    });

    // useEffect(() => {
    //     if(CisUI().getUserInfo('com_id') > 0) {
    //         getLedgerData();
    //     }
    // }, []);


    const getDetailsLink = (source,id) => {
        // if(source === 'Bill Payment') {
        //     return "../../"+jsonfile.detaillink.bill+"/"+id+"?date="+dateset;
        // }
        // else if(source === 'Journal') {
        //     return "../../"+jsonfile.detaillink.journal+"/"+id+"?date="+dateset;
        // }
        // else if(source === 'Vendor Bill') {
        //     return "../../"+jsonfile.detaillink.bill+"/"+id+"?date="+dateset;
        // }
        // else if(source === 'Ledger') {
        //     return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id+"?date="+dateset;
        // }
        // else if(source === 'Invoice') {
        //     return "../../"+jsonfile.detaillink.invoice+"/"+id+"?date="+dateset;
        // }
        // else if(source === 'Credit Note') {
        //     return "../../"+jsonfile.detaillink.creditnote+"/"+id+"?date="+dateset;
        // }
        // else {
        //     return "../../"+jsonfile.detaillink.journal+"/"+id+"?date="+dateset;
        // }

        return "../../accounts/ledger?account_id="+id+"&date="+dateset;
    }

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if (url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setCompany(res.data.company);
                    setHeader(res.data.company.legal_name);
                    setResp(res.data);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = [];
        let reprotType = "periodical";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    // wk = formRef.current.getFieldValue('date');
                    wk = formRef.current.getFieldValue('date');
                    const dtv = values[key] || moment().format("YYYY-01-01") + "," + moment().format("YYYY-12-31");
                    const dtvc = values[key] || "";
                    if (dtvc === "") {
                        dt.push(moment().format("YYYY-01-01"));
                        dt.push(moment().format("YYYY-12-31"));
                    } else {
                        dt = CisUI().ParseDateFormat(dtv);
                    }

                    setDate(dt);
                    //const dt = CisUI().ParseDateFormat(values[key]);
                    setPeriod("Between " + CisUI().getFormatedDate(dt[0]) + " and " + CisUI().getFormatedDate(dt[1]));
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                }
                else if (key === 'reporttype') {
                    if(values['reporttype'] === 'periodical') {
                        //setReportType("periodical");
                        reprotType = "periodical";
                    }
                    else {
                        // setReportType("comparative");
                        reprotType = "comparative";
                    }
                    return `${key}=${values[key]}`;
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        let ReprotPeriod = "For the End of " + CisUI().getMonthTitle(dt[1]);
        let CurrentYear = "as of " + CisUI().getMonthTitle(dt[1]);
        let LastYear = "";

        if (values['reporttype'] === 'comparative') {
            const pv = CisUI().PeriodValue(values['period']);
            console.log(pv);
            // setPeriod("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1])));
            ReprotPeriod = ("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1])));
            CurrentYear = "as of " + CisUI().getMonthTitle(dt[1]);
            LastYear = "as of " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1]));

            // setReportYear({
            //     currentYear: "as of " + CisUI().getMonthTitle(dt[1]),
            //     lastYear: "as of " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1]))
            // });
            // values['date2'] = [dt[1], moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1])];
            values['date2'] = dt[1]+","+moment(dt[1], "YYYY-MM-DD").subtract(pv[0], pv[1]).format("YYYY-MM-DD");
        }
        else if (values['reporttype'] === 'comparative2') {
            const pv = CisUI().PeriodValue(values['period']);
            console.log(pv);
            const last = CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(1, "year"));
            // setPeriod("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + last);
            ReprotPeriod = ("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + last);
            CurrentYear = "as of " + CisUI().getMonthTitle(dt[1]);
            LastYear = "as of " + last;
            // setReportYear({
            //     currentYear: "as of " + CisUI().getMonthTitle(dt[1]),
            //     lastYear: "as of " + last
            // });
            values['date2'] = +moment(dt[0], "YYYY-MM-DD").subtract(1, "year").format("YYYY-MM-DD")+","+moment(dt[1], "YYYY-MM-DD").subtract(1, "year").format("YYYY-MM-DD");
        }
        else {
            values["date2"] = null;
        }

        console.log("Report Header processed");
        // const qsup = "";
        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup+"&date2="+values["date2"]);
        const newURL = Config.apiserver + jsonfile.urls.listv2 + "?" + qsup+"&date2="+values["date2"];
        setState({
            reqURL: newURL,
            filtervalue: '',
            subtitle: '',
            period: ReprotPeriod,
            currentYear: CurrentYear,
            lastYear: LastYear,
            reporttype: reprotType
        });

        getLedgerData(newURL);
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search, "", 1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        window.open(Config.baseurl + "reports/incomestatementprint.html?url=" + window.btoa(state.reqURL) + "&filename=income_statement&data=incomestatementData&subtitle=" + period + "&rptype=incomestatement", "_blank");
    }


    const getAPIDataXLS = (APIUrl,qrystring) => {
        let URL  = APIUrl+"/xls?"+qrystring+"&token="+localStorage.getItem("token") || 0;
        if(APIUrl.includes("?")) {
            const sp = APIUrl.split("?");
            URL  = sp[0]+"/xls?"+sp[1]+"&token="+localStorage.getItem("token") || 0;
        }
        console.log("URL :"+URL);
        window.open(URL,"_blank");
    };

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height: '40px', display: 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height: '40px'}} alt="loader"/> Please
                    wait ...
                </a>
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}

                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>

                <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIDataXLS(state.reqURL,"")} title="Export to Excel"><i
                    className="fas fa-file-excel"/></button>

            </div>
        );

    const getExtra = (
        <>
            {/*{CisUI().listActionReportXLS("","",state.reqURL,"data","income_statement",jsonfile,"Income Statement")}*/}

            {listAction()}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if (hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index, 1);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);

            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                //row[i].removeAttribute('style');
                row[i].style.display = 'none';
            }

            for (i = 0; i < thisYear.length; i++) {
                thisYear[i].removeAttribute('style');
            }
        } else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'none';
                row[i].removeAttribute('style');
            }

            for (i = 0; i < thisYear.length; i++) {
                //row[i].style.display = 'none';
                thisYear[i].style.display = "none";
            }
        }
        console.log("hides : ", hide);
    }


    let totalDebit = 0;
    let totalCredit = 0;

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                {resp.status === 1 ?
                    <div>
                        <div className="table-responsive">
                            <div style={{textAlign: 'center'}}>
                                <h2>{header}</h2>
                                <h4>Statement of Comprehensive Income</h4>
                                <h4>{period}</h4>
                            </div>

                            <table className="statement table mx-auto w-auto">
                                <thead>
                                <tr>
                                    <th style={{width: '400px'}}>Description</th>
                                    <th style={{textAlign: 'right', width: '130px'}}>{state.currentYear}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight: 'bold'}}>Income :</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>&nbsp;</td>
                                </tr>
                                {
                                    resp.incomes.map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td className="clmintent no-border"
                                                style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                <div className="subItemText">
                                                    {itmes["child"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.account}</div>
                                            </td>
                                            <td className="no-border" style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} ><Link to={getDetailsLink("Ledger",itmes.id)}>{CisUI().getCurrencyFormated1(itmes.amount)}</Link></span></td>
                                        </tr>)

                                        itmes["child"].map((itm,ix)=>{
                                            if(itm.type === 'total') {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}} >
                                                    <td className="clmintent no-border"
                                                        style={{fontWeight: 'bold', paddingLeft: '40px'}}>
                                                        <div className="subItemText2">{itm.account}</div>
                                                    </td>
                                                    <td className="no-border" style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}><Link to={getDetailsLink("Ledger",itm.id)}>{CisUI().getCurrencyFormated1(itm.amount)}</Link></td>

                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}} >
                                                    <td className="clmintent no-border"
                                                        style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                        <div className="subItemText2">{itm.account}</div>
                                                    </td>
                                                    <td className="no-border" style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}><Link to={getDetailsLink("Ledger",itm.id)}>{CisUI().getCurrencyFormated1(itm.amount)}</Link></td>
                                                </tr>)
                                            }
                                        })

                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>Total Income :</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.total_income)}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: 'bold'}}>Expenses :</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>&nbsp;</td>
                                </tr>
                                {
                                    resp.expenses.map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td className="clmintent no-border"
                                                style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                <div className="subItemText">
                                                    {itmes["child"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.account}</div>
                                            </td>
                                            <td className="no-border" style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} ><Link to={getDetailsLink("Ledger",itmes.id)}>{CisUI().getCurrencyFormated1(itmes.amount)}</Link></span></td>
                                        </tr>)

                                        itmes["child"].map((itm,ix)=>{
                                            if(itm.type === 'total') {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}} >
                                                    <td className="clmintent no-border"
                                                        style={{fontWeight: 'bold', paddingLeft: '40px'}}>
                                                        <div className="subItemText2">{itm.account}</div>
                                                    </td>
                                                    <td className="no-border" style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}><Link to={getDetailsLink("Ledger",itm.id)}>{CisUI().getCurrencyFormated1(itm.amount)}</Link></td>
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}} >
                                                    <td className="clmintent no-border"
                                                        style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                        <div className="subItemText2">{itm.account}</div>
                                                    </td>
                                                    <td className="no-border" style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}><Link to={getDetailsLink("Ledger",itm.id)}>{CisUI().getCurrencyFormated1(itm.amount)}</Link></td>
                                                </tr>)
                                            }
                                        })

                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>Total Expenses</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.total_expense)}</td>

                                </tr>
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>Profit/Loss</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.profit)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""}

            </Card>
        </>
    );
};

export default IncomeStatement;