import axios from 'axios';
import config from './config';
import  localStorage  from  "react-secure-storage";
let token = "";
if(localStorage.getItem("token") !== undefined) {
  token = localStorage.getItem('token') || "";
}

const axiosinstance = axios.create({
  baseURL: config.apiserver,
  headers: {
    "Content-Type": 'application/json',
    "Authorization": token,
  }
});

export default axiosinstance;
