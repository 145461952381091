import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api';
import jsPDF from 'jspdf';
import moment from 'moment';

const Search = Input.Search;
const Panel = Collapse.Panel;

const LocalSummaryReport = () => {
    const value = 0;
    const formRef = React.createRef();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.summary,
        filtervalue : '',
        reportTitle : '',
        reportData : [],
        totalContribution : 0,
        month : "",
        region : "",
        district : "",
        lcoal : "",
        contribution : [],
        expenses : [],
        totalExpenses : 0,
        netTitheAfterExpense : 0,
        ddf : 0,
        NetTitheToDistrict : 0,
        missionOffering : 0,
        MinistryIncome : 0,
        Mckeowns : 0,
        InternalMissions : 0,
        NationalPrayer : 0,
        PENSOS : 0,
        netCashToDistrict : 0,
    });

    const [report,setReport] = useState({});

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                }
                else if(key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.summary+"?"+qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : "+ wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between "+CisUI().DateFormat(WKR[0])+" and "+ CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver+jsonfile.urls.summary;
        //const data = SelectData(url);

        // setState({
        //     reqURL: newURL,
        //     reportTitle: reportTitle
        // });
        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        reqURL: newURL,
                        contribution : data.contribution,
                        totalContribution : data.totalContribution,
                        local : data.local,
                        district : data.district,
                        region : data.region,
                        reportTitle : reportTitle,
                        month: WKR[0],
                        expenses : data.expenses,
                        totalExpenses : data.totalExpenses,
                        netTitheAfterExpense : data.netTitheAfterExpense,
                        ddf : data.ddf,
                        NetTitheToDistrict : data.NetTitheToDistrict,
                        missionOffering : data.missionOffering,
                        MinistryIncome : data.MinistryIncome,
                        Mckeowns : data.Mckeowns,
                        InternalMissions : data.InternalMissions,
                        NationalPrayer : data.NationalPrayer,
                        PENSOS : data.PENSOS,
                        netCashToDistrict : data.netCashToDistrict,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,'date')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"summaryreport.html?url="+window.btoa(state.reqURL)+"&filename=local_summary_report&data=localsummeryData&subtitle="+state.reportTitle+"&rptype=localsummaryreport","_blank");
    }


    const exportToPDF = () => {

        var doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: "landscape",
            unit: "px"
        });

        // var doc = new jsPDF('l', 'px', 'letter');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const marginX = (pageWidth - 80) / 2;
        const xOffset = (doc.internal.pageSize.width / 2);
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user)

        doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
        doc.text("Area Summary Report",xOffset,37, { align: 'center' });
        doc.setFontSize(12);
        doc.text(state.reportTitle,xOffset,43, { align: 'center' });
        doc.setFontSize(8);
        doc.text("Printed By : "+uj["name"],20,50, { align: 'left' });
        doc.setFontSize(8);
        doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,55, { align: 'left' });

        var content = document.getElementById("region_summary").innerHTML;

        doc.html(content,
            {
                margin: [40, 60, 40, 60],
                callback: function (doc) {
                    doc.save("region_summary");
                },
                x: 20,
                y: 50
            })

    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {/*<Link to={"../"+jsonfile.urls.add} className="btn btn-text-adjust btn-circle-md" title="Add New">*/}
                {/*    <i className="fas fa-plus"/>*/}
                {/*</Link>*/}
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );

    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>Local Summary Report</h3>
                        <h2>{state.reportTitle}</h2>
                    </div>
                    <div className="col-md-6">
                        <table>
                            <tr>
                                <th style={{textAlign: 'right'}}>Area :</th>
                                <td>{state.region}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: 'right'}}>Dsitrict :</th>
                                <td>{state.district}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: 'right'}}>Assembly :</th>
                                <td>{state.local}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th style={{textAlign : 'right'}}>Amount</th>
                        <th style={{textAlign : 'right'}}>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>TOTAL CONTRIBUTION RECEIVED (Gross Tithe & Offering)	</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                    </tr>

                    <tr>
                        <th>ALLOWABLE LOCAL EXPENSES	</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}></th>
                    </tr>
                    {
                        state.expenses.map((items, index) =>
                            <tr>
                                <td>{items.title}</td>
                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                <td style={{textAlign : 'right'}}></td>
                            </tr>
                        )
                    }
                    <tr>
                        <th>Total Allowable Local Expenses</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                    </tr>

                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe After Local Allowable Expenses</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Less :  Local Development Fund ( 10%)</th>
                        <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.ddf)})</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe to District</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToDistrict)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Missions Offering</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                    </tr>
                    <tr style={{display: 'none'}}>
                        <th colSpan={2} style={{textAlign : 'right'}}><a href="#">Add : Ministry Income</a></th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                    </tr>
                    <tr style={{display: 'none'}}>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Mckeowns</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                    </tr>
                    <tr style={{display: 'none'}}>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Internal Missions</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                    </tr>
                    <tr style={{display: 'none'}}>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : National Prayer</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                    </tr>
                    <tr style={{display: 'none'}}>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : PENSOS</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                    </tr>
                    {/*<tr>*/}
                    {/*    <th colSpan={2} style={{textAlign : 'right'}}>Add : Other Funds(Mckeowns, Internal Missions, National Prayer, & PENSOS)</th>*/}
                    {/*    <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(0)}</th>*/}
                    {/*</tr>*/}
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>NET CASH SENT TO DISTRICT	</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToDistrict)}</th>
                    </tr>


                    </tbody>
                </table>


            </Card>
        </>
    );
};

export default LocalSummaryReport;