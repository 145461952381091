import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card, notification} from 'antd';
import jsonfile from './ledger.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import AdvancedSearchBeneficiary from "../../../../util/AdvanceSearchWithBeneficiary";

const Search = Input.Search;
const Panel = Collapse.Panel;

const BankAccountTransactionDetails = (props) => {
    const value = 0;
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    let url = Config.apiserver+jsonfile.urls.bankdetails;
    if(accountID > 0) {
        url = Config.apiserver+jsonfile.urls.bankdetails+"?account_id="+accountID+"&date="+date;
    }


    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const [hide,setHide] = useState([]);
    const [company,setCompany] = useState({});
    const [account,setAccount] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setCompany(res.data.company);
                    setAccount(res.data.account);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const getDetailsLink = (source,id) => {

        if(source === 'bill') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'journal') {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
        else if(source === 'ledger') {
            return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id;
        }
        else if(source === 'invoice') {
            return "../../"+jsonfile.detaillink.invoice+"/"+id;
        }
        else if(source === 'creditnote') {
            return "../../"+jsonfile.detaillink.creditnote+"/"+id;
        }
        else if(source === 'voucher') {
            return "../../"+jsonfile.detaillink.voucherdetails+"/"+id;
        }
        else if(source === 'transaction') {
            return "../../"+jsonfile.detaillink.transactiondetails+"/"+id;
        }
        else {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
    }

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearchBeneficiary(jsonfile.search2,"",1,'date',CisUI().PeriodFiled(form), form)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionReport("../"+jsonfile.urls.add,"",state.reqURL,"data","general_ledger",jsonfile,"Journal", "landscape")}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if(hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index,1);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                row[i].removeAttribute('style');
            }
        }
        else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                row[i].style.display = 'none';
            }
        }
        console.log("hides : ", hide);
    }

    return (
        <>
            {searchView}
            <Card>
                {CisUI().showLoading}

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <h4 style={{textTransform: 'uppercase'}}>{company?.legal_name}</h4>
                        <h3>Bank Account Summary</h3>
                        <h4>For the period of {CisUI().getFormatedDate(account?.fromDate)} to {CisUI().getFormatedDate(account?.toDate)}</h4>
                        <h4>Account: {account?.account_name}</h4>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="statement table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Beneficiary</th>
                            <th style={{textAlign : 'right'}}>
                                <div style={{textAlign: 'right'}}>Inflow ({CisUI().getCurrencySymbol()})</div>
                            </th>
                            <th style={{textAlign : 'right'}}><div style={{textAlign: 'right'}}>Outflow ({CisUI().getCurrencySymbol()})</div></th>
                            <th style={{textAlign : 'right'}}><div style={{textAlign: 'right'}}>Balance ({CisUI().getCurrencySymbol()})</div></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data?.length > 0 ?
                                data?.map((itmes,index)=> {
                                if(itmes.type === 'section') {
                                    return <tr className="showTotalBorder">
                                        <td colSpan={6} style={{fontWeight: 'bold'}}>
                                            {itmes.name}
                                        </td>
                                    </tr>
                                }
                                else if(itmes.type === 'total') {
                                    return <tr className="showTotalBorder">
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>

                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }
                                else if(itmes.type === 'sectionTotal') {
                                    return <tr className="showTotalBorder">
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>

                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }
                                else {
                                    return <tr className={"disp_"+itmes.account_id} >
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }
                            }) : <></>
                        }

                        {
                            data.length > 0 ?
                            data[0]?.children.map((itmes,index)=> {
                                if(itmes.type === 'section') {
                                    return <tr className="showTotalBorder">
                                        <td colSpan={6} style={{fontWeight: 'bold'}}>
                                            {itmes.name}
                                        </td>
                                    </tr>
                                }
                                else if(itmes.type === 'total') {
                                    return <tr className="showTotalBorder">
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>

                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }
                                else if(itmes.type === 'sectionTotal') {
                                    return <tr className="showTotalBorder">
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>

                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }
                                else {
                                    return <tr className={"disp_"+itmes.account_id} >
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.source}</Link></td>
                                        <td><Link to={getDetailsLink(itmes.id)}>{itmes.beneficiary}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.current_balance)}</Link></td>
                                    </tr>
                                }

                            }) : <></>
                        }

                        </tbody>
                    </table>
                </div>

            </Card>
        </>
    );
};

export default BankAccountTransactionDetails;