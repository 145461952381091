import React from "react";
import {Route, Switch} from "react-router-dom";
import FleetRequest from "../../containers/yotta/fleet/vehiclerequest";
import FuelRequest from "../../containers/yotta/fleet/fuelrequisition";

const Fleet = ({match}) => (
    <Switch>
        <Route path={`${match.url}/vehiclerequest`} component={FleetRequest}/>
        <Route path={`${match.url}/fulerequest`} component={FuelRequest}/>
    </Switch>
)

export default Fleet;