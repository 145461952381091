import {Form, Row, Col, Button, Card, Select, notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './otherscollection.json';
import jsonMemberfile from './../member/member.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";
import IntlMessages from "../../../../util/IntlMessages";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;

const NewOthersCollection = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [selectedTithe, setSelectedTithe] = useState("");

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        let msg = "Sorry! request not processed, please try again";
        values["is_others"] = 1;
        values["tithe"] = selectedTithe;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    const time = Math.random();
                    form.resetFields();
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };

    const Contributions = SelectData("donation/contributiontype");

    const onchangeContribution = (e) => {
       const dts = Contributions.filter(items => items.id === e);
       if(dts[0]["account_number"] === 40000) {
            setSelectedTithe("1450LT");
            document.getElementById("tsname").innerText = "1450LT (Loose Tithe)";
            document.getElementById("tithe_type").style.display = "block";
       }
       else {
           const name = dts[0]["account_name"];
           const sp = name.split(" ");
           let nc = "1450";
           for(var key in sp) {
               var ts = sp[key];
               nc += ts.substr(0,1);
           }
           setSelectedTithe(nc);
           document.getElementById("tithe_type").style.display = "block";
           document.getElementById("tsname").innerText = nc;
       }
    }

    if(CisUI().getUserInfo('comarea') === 6) {
        const titheIDs = localStorage.getItem("titheID") || null;
        jsonfile.input2[0].value = parseInt(titheIDs);
    }

    const dataField = (
        <>
            <div className="col-md-4">
                <Form.Item
                    name="head_id"
                    label="Contribution Type / Income"
                    rules={[
                        {
                            required: true,
                            message: "Select contribution type"
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select contribution type"
                        optionFilterProp="children"
                        onChange={onchangeContribution}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            Contributions.map((items, index) =>
                                <Option key={++index} value={items.id}>{items.name}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </div>
            <div className="col-md-4" id="tithe_type" style={{paddingTop : '30px'}} >
                <label>Tithe Number : <strong id="tsname"></strong> </label>
            </div>
        </>
    )

    return (
        <Card title={<IntlMessages id={Title} />} extra={
            isEdit === 1
                ? CisUI().addAction("../../"+jsonfile.urls.list)
                : CisUI().addAction("../"+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card style={{paddingLeft: '20px'}} title="Enter others contribution">
                            <div className="col-md-12">
                                <div className="row" style={{marginLeft : '0px'}}>
                                    {dataField}
                                </div>
                            </div>
                            {InputOnly(jsonfile.input,userData,isEdit)}
                        </Card>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back"> <IntlMessages id={"Cancel"} /></Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewOthersCollection;