import React, {Component,useState, useEffect} from "react";
import {Form, Row, Col, Input, InputNumber, Button, Card, Select,notification, DatePicker} from 'antd';
import jsonfile from './salary.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;
const {TextArea} = Input;

const NewSalary = (props) => {

    const [loading, setLoading] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    let company = SelectData('company/companylists');
    let status = SelectData('userstatus');

    // console.log(company)


    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState({
        rows: [
            {
                emp_id : '',
                designation_id : '',
                basic : '',
                addition : '',
                deduction : '',
                total : 0,
            }
        ]
    });


    const handleRowAdd = () => {
        // console.log("hi");
        const item = {
            emp_id : '',
            emp_name : '',
            designation_id : '',
            designation_name : '',
            basic : '',
            addition : '',
            deduction : '',
            total : 0,
        };
        setState({
            rows: [...state.rows, item]
        });

        // console.log(state.rows1);
    };


    // const handleRemoveStateRow = (idx) => e => {
    //
    //     const filteredArray = [...state.rows1];
    //     setState({
    //         rows1: filteredArray.filter((item, index) => index !== idx)
    //     });
    //
    //     const basic_amount = form.getFieldValue('com_id');
    //
    // };

    const CompanyChange = (value) => {

        axios.get(Config.apiserver + "hrm/empaccountsbycomid/"+value, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data.data.length)
                const emp_data = res.data.data
                if(emp_data.length > 0)
                {
                    let item = [];
                    emp_data.map((value, index) => {
                        item.push({
                            emp_id: value.emp_id,
                            emp_name: value.emp_name,
                            designation_id: value.dg_id,
                            designation_name: value.dg_name,
                            basic: value.basic,
                            addition: value.total_addition,
                            deduction: value.total_deduction,
                            total: value.total
                        });
                    })
                    setState({
                        rows: item
                    });
                }
                else{
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: 'This company has no employee....'
                    });
                    setState({
                        rows: []
                    });
                }
            })
            .catch(errors => {
                console.log(errors)
            });


    }

    const handleAddChange = (id) => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "emp_name") {
            rows[id] = {
                emp_name : value,
                designation_name : rows[id].designation_name,
                basic : rows[id].basic,
                addition : rows[id].addition,
                deduction : rows[id].deduction,
                total : rows[id].total,
            };
        }
        else if(name === "designation_name") {
            rows[id] = {
                emp_name : rows[id].emp_name,
                designation_name : value,
                basic : rows[id].basic,
                addition : rows[id].addition,
                deduction : rows[id].deduction,
                total : rows[id].total,
            };
        }
        else if(name === "basic") {
            rows[id] = {
                emp_name : rows[id].emp_name,
                designation_name : rows[id].designation_name,
                basic : rows[id].basic,
                addition : rows[id].addition,
                deduction : rows[id].deduction,
                total : rows[id].total,
            };
        }
        else if(name === "addition") {
            rows[id] = {
                emp_name : rows[id].emp_name,
                designation_name : rows[id].designation_name,
                basic : rows[id].basic,
                addition : value,
                deduction : rows[id].deduction,
                total : rows[id].total,
            };
        }
        else if(name === "deduction") {
            rows[id] = {
                emp_name : rows[id].emp_name,
                designation_name : rows[id].designation_name,
                basic : rows[id].basic,
                addition : rows[id].addition,
                deduction : value,
                total : rows[id].total,
            };
        }
        else if(name === "total") {
            rows[id] = {
                emp_name : rows[id].emp_name,
                designation_name : rows[id].designation_name,
                basic : rows[id].basic,
                addition : rows[id].addition,
                deduction : rows[id].deduction,
                total : value,
            };
        }

        setState({
            rows
        });


    }

    const onFinish = (values) => {
        setLoading({loading:true})
        // console.log("Input Data : "+values);
        // values["item1"] = state.rows;
        // values["total_addition"] = totalAddition;
        // values["total_deduction"] = totalDeduction;
        // values["total"] = total;
        console.log(values);
        // let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    };


    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFieldV2(jsonfile.input,userData,isEdit,props)}*/}

                <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                    {/*<div className="col-md-4">*/}
                            <Form.Item
                                id="month"
                                label="Month"
                                name="month"
                                // initialValue={`${InV}`}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Month is required."
                                    },
                                ]}
                            >
                                <DatePicker className="gx-mb-3 gx-w-100" picker="month" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                id="com_id"
                                label="Company"
                                name="com_id"
                                // initialValue={`${InV}`}
                                // onChange={CompanyChange}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Company is required."
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    placeholder="Select An Option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    onChange={CompanyChange}
                                >
                                    {
                                        company.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>

                            </Form.Item>
                        </div>
                    </Col>

                    {/*<Col lg={8} md={8} sm={12} xs={24}>*/}
                    {/*    <div className="gx-form-row0">*/}
                    {/*        <Form.Item*/}
                    {/*            id="basic"*/}
                    {/*            label="Basic"*/}
                    {/*            name="basic"*/}
                    {/*            initialValue={`${InV}`}*/}
                    {/*            rules={[*/}
                    {/*                {*/}
                    {/*                    required: "required",*/}
                    {/*                    message: "Basic is required."*/}
                    {/*                },*/}
                    {/*            ]}*/}
                    {/*        >*/}
                    {/*            <Input placeholder="Basic" onChange={handleBasicChange}/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}

                    {/*<Col lg={8} md={8} sm={12} xs={24}>*/}
                    {/*    <div className="gx-form-row0">*/}
                    {/*        <Form.Item*/}
                    {/*            id="status"*/}
                    {/*            label="Status"*/}
                    {/*            name="status"*/}
                    {/*            initialValue={`${InV}`}*/}
                    {/*            rules={[*/}
                    {/*                {*/}
                    {/*                    required: "required",*/}
                    {/*                    message: "Status is required"*/}
                    {/*                },*/}
                    {/*            ]}*/}
                    {/*        >*/}
                    {/*            <Select*/}
                    {/*                showSearch*/}
                    {/*                placeholder="Select An Option"*/}
                    {/*                optionFilterProp="children"*/}
                    {/*                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                    {/*                // defaultValue={`${InV}`}*/}
                    {/*                // onChange={handleChange}*/}
                    {/*            >*/}
                    {/*                {*/}
                    {/*                    status.map((items, index) =>*/}
                    {/*                        <Option key={++index} value={items.id}>{items.name}</Option>*/}
                    {/*                    )*/}
                    {/*                }*/}
                    {/*            </Select>*/}

                    {/*        </Form.Item>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                id="remarks"
                                label="Remarks"
                                name="remarks"
                                // initialValue={`${InV}`}
                                // rules={[
                                //     {
                                //         required: "required",
                                //         message: "Basic is required."
                                //     },
                                // ]}
                            >
                                <TextArea placeholder="Remarks"/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <div className="row">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Salary Generate Table</h2>
                            </div>
                            {/*<div className="col-md-6" style={{textAlign : "right"}}>*/}
                            {/*    <a className="btn btn-primary" onClick={handleRowAdd}>Add More</a>*/}
                            {/*</div>*/}
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                {/*<th></th>*/}
                                <th>Employee</th>
                                <th>Designation</th>
                                <th>Basic</th>
                                <th>Total Addition</th>
                                <th>Total Deduction</th>
                                <th>Total Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            { state.rows.map((item, idx) => (

                                <tr id="addr0" key={idx}>
                                    {/*{console.log(item)}*/}
                                    {/*<td style={{width : '30px'}}></td>*/}
                                    {/*<td style={{width : '30px'}}><a onClick={handleRemoveState1Row(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>*/}
                                    <td style={{width : '150px'}}><input type="text" className="form-control" readOnly="true" placeholder="Employee" name="emp_name" value={state.rows[idx].emp_name} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="text" className="form-control" readOnly="true" placeholder="Designation" name="designation_name" value={state.rows[idx].designation_name} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control" style={{textAlign : 'right'}} placeholder="Basic" name="basic" value={state.rows[idx].basic} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control" style={{textAlign : 'right'}} placeholder="Total Addition" name="addition" value={state.rows[idx].addition} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control" style={{textAlign : 'right'}} placeholder="Total Deduction" name="deduction" value={state.rows[idx].deduction} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control" style={{textAlign : 'right'}} name="total" value={state.rows[idx].total} onChange={handleAddChange(idx)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewSalary;