import React from "react";
import {Route, Switch} from "react-router-dom";

import MyDocument from "../../containers/yotta/document/documentupload";
import FileManagerDocument from "../../containers/yotta/document/filemanager";

const Document = ({match}) => (
    <Switch>
        <Route path={`${match.url}/mydocument`} component={MyDocument}/>
        <Route path={`${match.url}/opendoc`} component={FileManagerDocument}/>
    </Switch>
);

export default Document;
