import React, {useState} from "react";
import {Form, Card, Collapse} from "antd";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import jsonfile from "../../company/cominfo/company.json";
import AdvancedSearch from "../../../../util/AdvancedSearch";
import ActionButton from "../../../../util/actionbutton";
import IntlMessages from "util/IntlMessages";
import SmartDataTable from 'react-smart-data-table';
import {Link, useHistory} from "react-router-dom";

const Panel = Collapse.Panel;

const CompanyListInfo = () => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });

    const headers = {
        id: {
            text: <IntlMessages id="ID"/>,
            sortable: true,
            filterable: true,
            invisible:false
        },
        legal_name: {
            text: <IntlMessages id="Legal Name"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <Link to={"companyedit/"+row.id}>{value}</Link>
            )
        },
        name: {
            text: <IntlMessages id="Company Name"/>,
            sortable: true,
            filterable: true,
            invisible : true
        },
        ein: {
            invisible: true
        },
        region_name: {
            text: <IntlMessages id="Area Name"/>,
            sortable: true,
            filterable: true,
        },
        district_name: {
            text: <IntlMessages id="District"/>,
            sortable: true,
            filterable: true,
        },
        level: {
            text: <IntlMessages id="Level"/>,
            sortable: true,
            filterable: true,
        },
        local_name: {
            text: <IntlMessages id="Local Name"/>,
            sortable: true,
            filterable: true,
        },
        type: {
            text: <IntlMessages id="Type"/>,
            sortable: true,
            filterable: true,
        },
        city: {
            text: <IntlMessages id="City"/>,
            sortable: true,
            filterable: true,
        },
        email: {
            text: <IntlMessages id="Email"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        phone: {
            text: <IntlMessages id="Phone"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        address_line_1: {
            text: <IntlMessages id="Address Line 1"/>,
            sortable: true,
            filterable: true,

        },
        address_line_2: {
            text: <IntlMessages id="Address Line 2"/>,
            sortable: true,
            filterable: true,
        },
        state: {
            text: <IntlMessages id="State"/>,
            sortable: true,
            filterable: true,
        },
        zip_code: {
            text: <IntlMessages id="Zip Code"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {CisUI().getTransactionStatus(value)}
                </>
            ),
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;


    const onFinish = values => {
        //console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        console.log(newURL);
        setState({reqURL: newURL});
    };

    let filterValue = "";

    // const handleOnFilter = ({ target: { name, value } }) => {
    //     //filterValue = value;
    //     console.log("filteringwith :"+ value);
    // }


    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.inputSearch)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'company_info', jsonfile, state.subtitle, "landscape")}
        </>
    );

    return (

        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default CompanyListInfo;