import React, {useState, useEffect} from "react";
import { Form, Input,Card, Collapse} from 'antd';
import jsonfile from './chartofaccount.json'
import Config from '../../../../../util/config'
import { useHistory} from "react-router-dom";
import {CisUI} from '../../../../../util/CISUI'
import AdvancedSearch from '../../../../../util/AdvancedSearch'
import ActionButton from '../../../../../util/actionbutton'
import SmartDataTable from 'react-smart-data-table'
import 'react-smart-data-table/dist/react-smart-data-table.css'

import IntlMessages from "util/IntlMessages";


const Panel = Collapse.Panel;


const DistrictGLList = () => {

    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.alllist+"?act_level=5",
        filtervalue : '',
        subtitle: ''
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.alllist+"?act_level=5"
            }
        );
    }, []);

    for (var a in localStorage) {
        console.log(a, ' = ', localStorage[a]);
    }


    const getFormatedValue = (parentID,value) => {
        let vl = <span>{value}</span>;
        if(parentID > 0) {
            vl = <span className="subAccount">{value}</span>
        }

        return vl
    }

    const getFormatedAccountNumber = (parentID,value) => {
        let vl = <span className="mainAccount">{value}</span>;
        if(parentID > 0) {
            vl = <span className="subAccount">{value}</span>
        }

        return vl
    }

    const history = useHistory();
    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${value++}`,
        // },
        parent_id: {invisible: true},
        id: {
            text: <IntlMessages id="ID"/>,
            sortable: false,
            filterable: true,
            invisible : true
        },
        account_number: {
            text: <IntlMessages id="Account Number"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {getFormatedAccountNumber(row.parent_id,value)}
                </>
            )
        },
        account_name: {
            text: <IntlMessages id="Account Name"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {getFormatedValue(row.parent_id,value)}
                </>
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        //let res = RemoteJson("userlists");
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alllist+"/?act_level=5&"+qsup;
        setState({reqURL: newURL});
    };


    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", "chartofaccount_list", jsonfile, state.subtitle, "landscape")}
        </>
    );

    const getData = () => {
        // return RemoteJson(Config.apiserver+"userlist");
    }

    const token = localStorage.getItem('token');

    return (
        <>
            {searchView}
            <Card title={"District GL List"} >
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default DistrictGLList;