import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';

import { Input, Card, Button, Table, Menu, Dropdown, Modal, Typography, Checkbox, Col, Divider, Row, Statistic } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { LikeOutlined, MoreOutlined } from '@ant-design/icons';
import { CisUI } from "../../../util/CISUI";
import axios from "axios";
import config from '../../../util/config';
import IntlMessages from "../../../util/IntlMessages";


const { Search } = Input;
const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;

// Products Coloumn
const columns = [
    {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
    },
    {
        title: "Product Name",
        dataIndex: "name",
        key: "name",
        render: (name, record) => <Link to={`view/${record.key}`}>{name}</Link>
    },
    {
        title: "Slug",
        dataIndex: "slug",
        key: "slug",
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
    },
    {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Actions",
        dataIndex: "action",
        key: "action",
    },
];

const dataYearly = [
    {
        name: 'Jan',
        Retail: 2000,
        Dealer: 1200,
        Distributor: 1100,
        Wholesale: 1000,
    },
    {
        name: 'Feb',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Mar',
        Retail: 3242,
        Dealer: 1200,
        Distributor: 1600,
        Wholesale: 1400,
    },
    {
        name: 'Apr',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 2342,
        Wholesale: 1400,
    },
    {
        name: 'May',
        Retail: 2352,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jun',
        Retail: 4000,
        Dealer: 5464,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jul',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 2344,
    },
    {
        name: 'Aug',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 7878,
    },
    {
        name: 'Sep',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Oct',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Nov',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Dec',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
];

const dataMonthly = [
    {
        name: '1',
        Retail: 2000,
        Dealer: 1200,
        Distributor: 1100,
        Wholesale: 1000,
    },
    {
        name: '2',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Mar',
        Retail: 2000,
        Dealer: 1200,
        Distributor: 1600,
        Wholesale: 1400,
    },
    {
        name: 'Apr',
        Retail: 4000,
        Dealer: 1223,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'May',
        Retail: 3454,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jun',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jul',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Aug',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Sep',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Oct',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Nov',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Dec',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
];

const dataWeekly = [
    {
        name: 'Data',
        Retail: 2000,
        Dealer: 1200,
        Distributor: 1100,
        Wholesale: 1000,
    },
    {
        name: 'data',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Mar',
        Retail: 2000,
        Dealer: 1200,
        Distributor: 1600,
        Wholesale: 1400,
    },
    {
        name: 'Apr',
        Retail: 4000,
        Dealer: 3455,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'May',
        Retail: 3244,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jun',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Jul',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Aug',
        Retail: 2342,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Sep',
        Retail: 3245,
        Dealer: 2345,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Oct',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Nov',
        Retail: 7866,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
    {
        name: 'Dec',
        Retail: 4000,
        Dealer: 2400,
        Distributor: 1400,
        Wholesale: 1400,
    },
];


const ProductsReport = () => {

    // PRODUCTS LIST
    const [products, setProducts] = useState([]);
    const getproduct = () => {
        axios
            .get(`${config.baseurl}productData.json`)
            .then((res) => setProducts(res.data.data))
            .catch((errors) => console.log(errors));
    };


    useEffect(() => {
        getproduct();
    }, []);
    const actionMenu = (id) => {
        const menu = (
            <Menu style={{ border: "1px solid #0000001c" }}>
                <Menu.Item key={`view_` + id}>
                    <Link to={`/product/productinfo/view/${id}`}>
                        <Button size="small" type="primary" className="list-table-btn" block >
                            View
                        </Button>
                    </Link>
                </Menu.Item>

                <Menu.Item key={`edit_` + id}>
                    <Link to={`/product/productinfo/edit/${id}`}>
                        <Button
                            size="small"
                            type="primary"
                            className="list-table-btn"
                            block
                        >
                            Edit
                        </Button>
                    </Link>
                </Menu.Item>

                <Menu.Item key={`report_` + id}>
                    <Link to={`/product/productinfo/report/${id}`}>
                        <Button size="small" type="primary" className="list-table-btn" block >
                            Report
                        </Button>
                    </Link>
                </Menu.Item>

                <Menu.Item key={`delete_` + id}>
                    <Button
                        size="small"
                        type="danger"
                        className="list-table-btn"
                        block
                        onClick={() => {
                            showConfirm(id);
                        }}
                    >
                        Delete
                    </Button>
                </Menu.Item>
            </Menu>
        );

        return menu;
    };

    function showConfirm(productID) {
        Modal.confirm({
            title: "Do you Want to delete these items?",
            content: "Some descriptions",
            okText: "Ok",
            cancelText: "Cancel",
            onOk() {
                // axios
                //   .delete()
                //   .then((res) => {
                //     // if (res.data.status == 1) {
                //     //   console.log("Success");
                //     //   setCategories(categories.filter((product) => product.id !== productID));
                //     // } else {
                //     //   console.log("error");
                //     // }
                //     console.log(res);
                //   })
                //   .catch((errors) => console.log(errors));
                setProducts(products.filter((product) => product.id !== productID));
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    const data = [];

    products.map((product, i) => {
        let key = product.id;
        let sl = i;
        let name = product.product_name;
        let slug = product.product_slug;
        let category = product.prodcut_category;
        let sku = product.product_sku;
        let status = product.status;

        data.push({
            key: key,
            sl: ++sl,
            name: name,
            slug: slug,
            category: category,
            sku: sku,
            status: CisUI().getStatusLabel(status),
            action: (
                <Dropdown overlay={actionMenu(key)} placement="bottomCenter">
                    <Button className="list-table-btn" size="small">
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            ),
        });
    });

    // PORODUCTS LIST
    const [yearly, setYearly] = useState(true)
    const [monthly, setMonthly] = useState(false)
    const [weekly, setWeekly] = useState(false)
    const [graph, setGraph] = useState(dataYearly)

    function onChange(e) {

        if (e.target.value == "yearly") {
            // e.target.checked = false
            setMonthly(false)
            setWeekly(false)
            setYearly(true)
            setGraph(dataYearly)

        } else if (e.target.value == "monthly") {
            setMonthly(true)
            setYearly(false)
            setWeekly(false)
            setGraph(dataMonthly)
        } else {
            setMonthly(false)
            setYearly(false)
            setWeekly(true)
            setGraph(dataWeekly)
        }
    }

    return (
        <>
            {/* <Row className="title-center">
                <Col span={6}></Col>
                <Col span={12}> <Divider><Title className="add-product-title" level={3}>Top Selling Product</Title></Divider></Col>
                <Col span={6}></Col>
            </Row> */}
            <Card title={<IntlMessages id="Product Reports"/>}>
                <Row className="products-statistic">
                <Col span={24}><Title level={3} className="title-center">Top Selling Product</Title></Col>
                <Col span={6}> <Statistic title="Total Products" value={1128} /></Col>
                <Col span={6}> <Statistic title="Total Sell" value={1128} /></Col>
                <Col span={6}> <Statistic title="Feedback" value={1128} /></Col>
                <Col span={6}> <Statistic title="Feedback" value={1128} /></Col>
            </Row>
            {/* Product Chart */}
            {/* <Row>
                <Col span={6}></Col>
                <Col span={12}> <Divider><Title className="add-product-title" level={3}>Product Price</Title></Divider></Col>
                <Col span={6}></Col>
            </Row> */}
                <Row>
                <Col span={24}>   <Title level={3}>Top Selling Product</Title></Col>
                <Col span={24}  style={{ textAlign: "right", marginBottom: "20px" }}>
                    <Checkbox onChange={onChange} value='yearly' checked={yearly}>Yearly</Checkbox>
                    <Checkbox onChange={onChange} value="monthly" checked={monthly}>Monthly</Checkbox>
                    <Checkbox onChange={onChange} value="weekly" checked={weekly}>Weekly</Checkbox>
                </Col>
            </Row>

                <Row className="products-graph">
                <Col span="24">
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={graph}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" interval="preserveEnd" />
                            <YAxis interval="preserveEnd" />
                            <Line type="monotone" dataKey="Retail" stroke="#463aa2" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="Dealer" stroke="#65d4ff" />
                            <Line type="monotone" dataKey="Distributor" stroke="#c669b8" />
                            <Line type="monotone" dataKey="Wholesale" stroke="#463aa2" />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>

            </Row>
            {/* Products Table */}

            {/* <Row>
                <Col span={6}></Col>
                <Col span={12}> <Divider><Title className="add-product-title" level={3}>Top Selling Product</Title></Divider></Col>
                <Col span={6}></Col>
            </Row> */}
                <Row>
                    <Col span={24}>
                        <Title level={3}>Top Selling Product</Title>
                        <Table columns={columns} dataSource={data} className="list-table" size="small" />
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ProductsReport;
