import React from "react";
import {Route, Switch} from "react-router-dom";

import CustomerTypeList from "../../containers/yotta/crm/customer_type/CustomerTypeList";
import NewCustomerType from "../../containers/yotta/crm/customer_type/NewCustomerType";
import LeadList from "../../containers/yotta/crm/customer_lead/LeadList";
import NewLead from "../../containers/yotta/crm/customer_lead/NewLead";
import CustomerList from "../../containers/yotta/crm/customer_info/CustomerList";
import TaskList from "../../containers/yotta/crm/activity_records/task/TaskList";
import NewTask from "../../containers/yotta/crm/activity_records/task/NewTask";
import EventList from "../../containers/yotta/crm/activity_records/event/EventList";
import NewEvent from "../../containers/yotta/crm/activity_records/event/NewEvent";
import CallLogList from "../../containers/yotta/crm/activity_records/calllog/CallLogList";
import NewCallLog from "../../containers/yotta/crm/activity_records/calllog/NewCallLog";
import VisitList from "../../containers/yotta/crm/activity_records/visit/VisitList";
import NewVisit from "../../containers/yotta/crm/activity_records/visit/NewVisit";
import NewInvoice from "../../containers/yotta/crm/invoice/newinvoice";
import EditInvoice from "../../containers/yotta/crm/invoice/editinvoice";
import InvoiceList from "../../containers/yotta/crm/invoice/invoicelist";
import InvoiceDetails from "../../containers/yotta/crm/invoice/invoicedetails";
import InvoicePayment from "../../containers/yotta/crm/invoice/payment";
import NewOtherInvoice from "../../containers/yotta/crm/otherinvoice/new";

const CRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/customertype`} component={CustomerTypeList}/>
        <Route path={`${match.url}/newcustomertype`} component={NewCustomerType}/>
        <Route path={`${match.url}/customertypeedit/:id`} component={NewCustomerType}/>
        <Route path={`${match.url}/leads`} component={LeadList}/>
        <Route path={`${match.url}/newlead`} component={NewLead}/>
        <Route path={`${match.url}/leadedit/:id`} component={NewLead}/>
        <Route path={`${match.url}/customers`} component={CustomerList}/>
        <Route path={`${match.url}/tasklist`} component={TaskList}/>
        <Route path={`${match.url}/newtask`} component={NewTask}/>
        <Route path={`${match.url}/taskedit/:id`} component={NewTask}/>
        <Route path={`${match.url}/eventlist`} component={EventList}/>
        <Route path={`${match.url}/newevent`} component={NewEvent}/>
        <Route path={`${match.url}/eventedit/:id`} component={NewEvent}/>

        <Route path={`${match.url}/callloglist`} component={CallLogList}/>
        <Route path={`${match.url}/newcalllog`} component={NewCallLog}/>
        <Route path={`${match.url}/calllogedit/:id`} component={NewCallLog}/>

        <Route path={`${match.url}/visitlist`} component={VisitList}/>
        <Route path={`${match.url}/newvisit`} component={NewVisit}/>
        <Route path={`${match.url}/visitedit/:id`} component={NewVisit}/>

        <Route path={`${match.url}/newinvoice`} component={NewInvoice}/>
        <Route path={`${match.url}/invoicelist`} component={InvoiceList}/>
        <Route path={`${match.url}/invoicedetails/:id`} component={InvoiceDetails}/>
        {/*<Route path={`${match.url}/invoiceedit/:id`} component={EditInvoice}/>*/}
        <Route path={`${match.url}/invoiceedit/:id`} component={InvoiceDetails}/>
        <Route path={`${match.url}/payment/:id`} component={InvoicePayment}/>
        <Route path={`${match.url}/newotherinvoice`} component={NewOtherInvoice}/>


    </Switch>
);

export default CRM;
