import React from "react";
import { Col, Input, Row, Select, Form, Button, Card } from "antd";
import {Link, useHistory} from "react-router-dom";

import axios from "axios";
import jsonfile from './category.json';

import { UnorderedListOutlined } from "@ant-design/icons";
import InputFields from "../../../../util/InputField";
import RemoteJson from "../../../../util/gerremotedata";
import {CisUI} from "../../../../util/CISUI";
import IntlMessages from "../../../../util/IntlMessages";
import config from "../../../../util/config";

// const { Content } = Layout;
const { Option } = Select;

const AddCategory = (props) => {
	const history = useHistory();
	const userID = props.match.params.id;
	let userData = [];
	const [form] = Form.useForm();
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectTo = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectTo = "../../"+jsonfile.urls.list;
	}

	const slugify = () => {
		const cat_name = form.getFieldValue('name');

		const slug = cat_name
			.toString()
			.trim()
			.toLowerCase()
			.replace(/\s+/g, "-")
			.replace(/[^\w\-]+/g, "")
			.replace(/\-\-+/g, "-")
			.replace(/^-+/, "")
			.replace(/-+$/, "");

		// console.log(cat_name.toString().trim().toLowerCase());

		form.setFieldsValue({
			'slug': slug
		})
	}

	const onFinish = (values) => {
		console.log(values);


		axios.post(config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then((res) => {
				if (res.data.status == 1) {
					history.push(redirectTo);
				} else {
					console.log("error");
				}
			}).catch((errors) => console.log(errors));

	};
	return (
		<Card title={<IntlMessages id={Title}/>} extra={
			isEdit === 1
				? CisUI().addAction(redirectTo)
				: CisUI().addAction(redirectTo)
		}>
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{InputFields(jsonfile.input,userData,isEdit,props, '', redirectTo)}
			</Form>
		</Card>
	);
};

export default AddCategory;
