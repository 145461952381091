import React, {useState, useEffect} from "react";
import { Form, Input, Button, Collapse ,Card, notification} from 'antd';
import jsonfile from './journal.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const JournalDetails = (props) => {
    const value = 0;

    const [state, setState] = useState([]);
    const [entry, setEntryDetails] = useState([]);

    const [difference,setDifference] = useState(0);
    const [selected,setSelected] = useState([]);
    const [total,setTotal] = useState({
        totalDebit: 0,
        totalCredit: 0,
    });


    const history = useHistory();

    const [form] = Form.useForm();

    useEffect(() => {
        getReconcileData();
    }, []);

    const getReconcileData = () => {
        document.getElementById("loader").style.display = "block";
        const jID = props.match.params.id;
        axios.get(Config.apiserver + jsonfile.urls.view+"/"+jID)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setState(res.data.data);
                    const dt = res.data.data;
                    if(dt.length > 0) {
                        setEntryDetails({
                            date : dt[0].date,
                            company : dt[0].company,
                            entry_number : dt[0].entry_number
                        });

                        setTotal({
                            totalDebit: res.data.totalDebit,
                            totalCredit: res.data.totalCredit
                        })
                    }
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alltransactions+"/?type=2&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    const setSelection = (e) => {
        let slct = selected;
        const vl = parseInt(e.target.value);
        if(e.target.checked) {
            if(selected.some(item => item === vl)) {

            }
            else {
                slct.push(vl);
            }
        }
        else {
            if(selected.some(item => item === vl)) {
                var index = slct.indexOf(vl); // Let's say it's Bob.
                slct.splice(index,1);
            }
        }
        setSelected(slct);

        let receipt = 0;
        let payment = 0;
        slct.map((value,indx) => {
            state.transaction.map((itm,ind) => {
                if(itm.id === value) {
                    receipt += itm.debit;
                    payment += itm.credit;
                }
            });
        });

        console.log(receipt);
        //let gap = receipt - payment;
        const totalGap = state.ending_balance - (state.balance + receipt - payment);
        setDifference(totalGap);

    }

    const extraItems = (
        <>
            <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            <Button type="info" onClick={() => window.print()} htmlType="back">Print</Button>
            <Button id="submitbutton" type="primary" htmlType="button">
                <Link to={"../journaledit/"+entry.entry_number}>Edit</Link>
            </Button>
        </>
    );

    let totalDebit = 0;
    let totalCredit = 0;


    return (
        <>
            <Card title={"Entry details - "+entry.entry_number} extra={extraItems}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <label><strong>Company</strong> : </label> {entry.company}<br />
                            <label><strong>Date</strong> : </label> {CisUI().DateFormat(entry.date)}<br />
                            <label><strong>Entry Number</strong> : </label> {entry.entry_number}<br />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="table-responsive" style={{marginTop : '20px'}}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Account</th>
                                    <th>Description</th>
                                    <th>Beneficiary</th>
                                    <th style={{textAlign : 'right !important'}}>Debit ({CisUI().getCurrencySymbol()})</th>
                                    <th style={{textAlign : 'right !important'}}>Credit ({CisUI().getCurrencySymbol()})</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.map((items,index) => {
                                        return <tr>
                                            <td>{items.account}</td>
                                            <td>{items.description}</td>
                                            <td>{items.beneficiary}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={3}>Total = </th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.totalDebit)}</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.totalCredit)}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
            </Card>
        </>
    );
};

export default JournalDetails;