import React, { useState, useEffect } from "react";
import { Row, Col, Input, Card, Button, Table, Menu, Dropdown, Modal, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { CisUI } from "../../../../util/CISUI";
import axios from "axios";
import config from "../../../../util/config";
import IntlMessages from "../../../../util/IntlMessages";
import jsonfile from "./sub_category.json";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";
// import ActionButton from "../../../components/Table/ActionButton";
const { Search } = Input;
const { Title } = Typography;
const onSearch = (value) => console.log(value);

const columns = [
	{
		title: "SL",
		dataIndex: "sl",
		key: "sl",
	},
	{
		title: "Subcategory Name",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Slug",
		dataIndex: "slug",
		key: "slug",
	},
	{
		title: "Parent Category",
		dataIndex: "p_category",
		key: "p_category",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Actions",
		dataIndex: "action",
		key: "action",
	},
];

const SubCategoryList = () => {
	const [categories, setCategories] = useState([]);
	const history = useHistory();
	const [state, setState] = useState({
		reqURL: Config.apiserver + jsonfile.urls.list,
		filtervalue: '',
		subtitle: '',
	});

	const getCategory = () => {
		axios
			.get(config.apiserver + jsonfile.urls.list, CisUI().HeaderRequest)
			.then((res) => {
				console.log(res);
				setCategories(res.data.data)
			})
			.catch((errors) => console.log(errors));
	};


	useEffect(() => {
		getCategory();
	}, []);
	const actionMenu = (id) => {
		const menu = (
			<Menu style={{ border: "1px solid #0000001c;" }}>
				<Menu.Item key={`edit_` + id}>
					<Link to={`/product/subcategory/edit/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item>
				<Menu.Item key={`delete_` + id}>
					<Button
						size="small"
						type="primary"
						className="list-table-btn"
						block
						onClick={() => {
							showConfirm(id);
						}}
					>
						Delete
					</Button>
				</Menu.Item>
			</Menu>
		);

		return menu;
	};

	function showConfirm(categoryID) {
		Modal.confirm({
			title: "Do you Want to delete these items?",
			content: "Some descriptions",
			okText: "Ok",
			cancelText: "Cancel",
			onOk() {
				axios
					.delete()
					.then((res) => {
						if (res.data.status == 1) {
							console.log("Success");
							setCategories(categories.filter((category) => category.id !== categoryID));
						} else {
							console.log("error");
						}
						console.log(res);
					})
					.catch((errors) => console.log(errors));
				// setCategories(categories.filter((category) => category.id !== categoryID));
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	}

	const data = [];

	categories.map((category, i) => {
		data.push({
			key: category.id,
			sl: ++i,
			name: category.name,
			slug: category.subcategory_slug,
			p_category: category.category_name,
			status: CisUI().getStatusLabel(category.status),
			action: (
				ActionButton(category.id,jsonfile.urls,jsonfile.urls.list)
			),
		});
	});

	const getExtra = (
		<>
			{CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", "sub_category", jsonfile, state.subtitle, "portrait")}
		</>
	);

	return (
		<>
			{/* <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
				<Title className="product-title" level={3}>Subcategory List</Title>
				<Table columns={columns} dataSource={data} className="list-table" />
			</Card> */}


			<Card title={"Product Sub Category"} extra={getExtra}>

				{/*<Row className="list-header">*/}
				{/*	<Col xs={{ span: 24 }} xl={{ span: 18 }}>*/}
				{/*		<Title className="product-title"*/}
				{/*			level={3}>Product Sub Category</Title>*/}
				{/*	</Col>*/}

				{/*	<Col xs={{ span: 24 }} xl={{ span: 6 }}>*/}
				{/*		<Input className="list-header-search" placeholder="Search Sub Category" prefix={<SearchOutlined />} onChange={onSearch} />*/}
				{/*	</Col>*/}
				{/*</Row>*/}
				<Table scroll={{ x: true }} columns={columns} dataSource={data} className="list-table" bordered pagination={{ position: ["bottomCenter"] }} />
			</Card>

		</>
	);
};

export default SubCategoryList;

