import React from "react";
import {Col, Input, Row, Select, Form, Button, Card, notification} from "antd";
import {Link, useHistory} from "react-router-dom";

import { UnorderedListOutlined } from "@ant-design/icons";
import IntlMessages from "../../../../util/IntlMessages";
import {CisUI} from "../../../../util/CISUI";
import jsonfile from "./sub_category.json";
import RemoteJson from "../../../../util/gerremotedata";
import InputFields from "../../../../util/InputField";
import axios from "axios";
import config from "../../../../util/config";

// const { Content } = Layout;
// const { Option } = Select;

const AddSubCategory = (props) => {
	const history = useHistory();
	const userID = props.match.params.id;
	let userData = [];
	const [form] = Form.useForm();

	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectTo = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectTo = "../../"+jsonfile.urls.list;
	}
	const onFinish = (values) => {
		// dispatch(addCustomer(values))
		console.log(values);
		axios.post(config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then((res) => {
				if (res.data.status == 1) {
					notification.success({
						message: "Success",
						description: res.data.msg,
					});
					history.push("../../"+jsonfile.urls.list);
				} else {
					console.log("error");
				}
			})
			.catch((errors) => console.log(errors));
	};

	return (
		<Card
			title={<IntlMessages id={Title}/>} extra={
			isEdit === 1
				? CisUI().addAction(redirectTo)
				: CisUI().addAction(redirectTo)
		}>
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{/*<Row*/}
				{/*	className="ant-row"*/}
				{/*	style={{ rowGap: "15px" }}*/}
				{/*	justify="space-between"*/}
				{/*>*/}
				{/*	<Col lg={8} xs={24}>*/}
				{/*		<Form.Item*/}
				{/*			label="Subcategory Name"*/}
				{/*			rules={[{ required: true, message: "Subcategory Name is required" }]}*/}
				{/*			name="subcategory_name"*/}
				{/*			className="form-input"*/}
				{/*		>*/}
				{/*			<Input placeholder="Category Name" />*/}
				{/*		</Form.Item>*/}
				{/*	</Col>*/}

				{/*	<Col lg={8} xs={24}>*/}
				{/*		<Form.Item*/}
				{/*			label="Subcategory Slug"*/}
				{/*			rules={[{ required: true, message: "Subcategory Slug is required" }]}*/}
				{/*			name="subcategory_slug"*/}
				{/*			className="form-input"*/}
				{/*		>*/}
				{/*			<Input placeholder="Category Slug" />*/}
				{/*		</Form.Item>*/}
				{/*	</Col>*/}

				{/*	<Col lg={8} xs={24}>*/}
				{/*		<Form.Item*/}
				{/*			label="Parent category"*/}
				{/*			rules={[*/}
				{/*				{*/}
				{/*					required: true,*/}
				{/*					message: "User Status is required",*/}
				{/*				},*/}
				{/*			]}*/}
				{/*			name="parent_category"*/}
				{/*			className="form-input"*/}
				{/*		>*/}
				{/*			<Select*/}
				{/*				placeholder="Select An Parent category"*/}
				{/*				style={{ width: "100%" }}*/}
				{/*			>*/}
				{/*				<Option value="1">T-shirt</Option>*/}
				{/*				<Option value="0">Gift Item</Option>*/}
				{/*			</Select>*/}
				{/*		</Form.Item>*/}
				{/*	</Col>*/}

				{/*	<Col lg={8} xs={24}>*/}
				{/*		<Form.Item*/}
				{/*			label="Status"*/}
				{/*			rules={[*/}
				{/*				{*/}
				{/*					required: true,*/}
				{/*					message: "User Status is required",*/}
				{/*				},*/}
				{/*			]}*/}
				{/*			name="status"*/}
				{/*			className="form-input"*/}
				{/*		>*/}
				{/*			<Select*/}
				{/*				placeholder="Select An Option"*/}
				{/*				style={{ width: "100%" }}*/}
				{/*			>*/}
				{/*				<Option value="1">Active</Option>*/}
				{/*				<Option value="0">Inactive</Option>*/}
				{/*			</Select>*/}
				{/*		</Form.Item>*/}
				{/*	</Col>*/}

				{/*	<Col lg={24} xs={24} style={{ textAlign: "center" }}>*/}
				{/*		<Button type="primary" htmlType="submit">*/}
				{/*			Save*/}
				{/*		</Button>*/}
				{/*		<Button type="secondary" htmlType="reset">*/}
				{/*			Cancel*/}
				{/*		</Button>*/}
				{/*	</Col>*/}
				{/*</Row>*/}
				{ InputFields(jsonfile.input,userData,isEdit,props, '', redirectTo) }
			</Form>
		</Card>
	);
};

export default AddSubCategory;
