import {
    Form,
    Radio,
    Input,
    Button,
    Card,
    Modal,
    Select,
    DatePicker,
    notification
} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import document from "../../../routes/document";


const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const VoucherEdit = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();

    const [hasPayment, setHasPayment] = useState(false);
    const [isApprover, setApprover] = useState(false);
    const [po, setPO] = useState({});
    const [bill, setBill] = useState({});
    const [doc, setDoc] = useState({});
    const [segment, setSegment] = useState(1);
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(false);
    const [benificiariesData, setBenificiaryData] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const [bankaccounts, setBankAccounts] = useState([]);
    const [voucherType, setVoucherType] = useState("Payment");
    const [amount, setAmount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(0);
    const [beneficiarySourceData, setBeneficiarySourceData] = useState({
        department : [],
        users: [],
        ministry : [],
        pentsos : [],
        area : [],
        district : [],
        local : [],
        subsector : [],
        committee: []
    });

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        BillStatusUpdate(bill.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        BillStatusUpdate(bill.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const onBenificaryChange = async (e) => {
        //setBenificiaryselected(e.target.value);
        if(e === 2) {
            BenificaryData = customer;
            await setBenificiaryData(customer);
        }
        else if(e === 3) {
            console.log("Selected : ",e);
            console.log("Vendor : ",vendor);
            BenificaryData = vendor;
            await setBenificiaryData(vendor);
        }
        else if(e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        }
        else if(e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        }
        else if(e === 6) {
            BenificaryData = beneficiarySourceData.department;
            setBenificiaryData(beneficiarySourceData.department);
        }
        else if(e === 7) {
            BenificaryData = beneficiarySourceData.area;
            setBenificiaryData(beneficiarySourceData.area);
        }
        else if(e === 8) {
            BenificaryData = beneficiarySourceData.users;
            setBenificiaryData(beneficiarySourceData.users);
        }
        else if(e === 9) {
            BenificaryData = beneficiarySourceData.users;
            setBenificiaryData(beneficiarySourceData.users);
        }
        else if(e === 10) {
            // console.log(e);
            // console.log(beneficiarySourceData.ministry);
            BenificaryData = beneficiarySourceData.ministry;
            setBenificiaryData(beneficiarySourceData.ministry);
        }
        else if(e === 11) {
            BenificaryData = beneficiarySourceData.pentsos;
            setBenificiaryData(beneficiarySourceData.pentsos);
        }
        else if(e === 12) {
            BenificaryData = beneficiarySourceData.district;
            setBenificiaryData(beneficiarySourceData.district);
        }
        else if(e === 13) {
            BenificaryData = beneficiarySourceData.local;
            setBenificiaryData(beneficiarySourceData.local);
        }
        else if (e === 14) {
            BenificaryData = beneficiarySourceData.committee;
            setBenificiaryData(beneficiarySourceData.committee);
        }
        else if (e === 15) {
            BenificaryData = beneficiarySourceData.subsector;
            setBenificiaryData(beneficiarySourceData.subsector);
        }

        form.setFieldsValue({
            benificiary_id : null
        });

    }

    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("vendor/billstatusupdate?bill_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    let Vendors = [];
    const BankName = SelectData("banknames");
    const Companies = SelectData("company/companylists");
    const PaymentMethod = SelectData("paymentmethod");
    const Benificiary = SelectData("accountbenificary");


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setIsLoaded(1);
                        setBeneficiarySourceData({
                            department : res.data.department,
                            users: res.data.users,
                            ministry : res.data.ministry,
                            pentsos : res.data.pentsos,
                            area : res.data.area,
                            district : res.data.district,
                            local : res.data.local,
                            committee : res.data.committee,
                            subsector : res.data.subsector
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const onBankChange = (value) => {
        const vl = value+Math.floor(Math.random() * 900000);
        form.setFieldsValue({reference_no : vl});
    }

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = async value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            // document.getElementById("loader").style.display = "block";
            await axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {

                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        // setMainAccount(res.data.main_account);
                        setMainAccount(res.data.allacount);
                        setChildAccount(res.data.child_account);
                        setIsLoaded(1);
                        setBeneficiarySourceData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            area: res.data.area,
                            district: res.data.district,
                            local: res.data.local,
                            committee: res.data.committee,
                            subsector: res.data.subsector

                        });

                        console.log(res.data.vendor);
                        getPODetails();

                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: null,
        benificiary_id: null,
        vendor_id: null,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = jsonfile.urls.edit;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        //setTitle(jsonfile.title);
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    let BenificaryData = [];

    const onFinish = (values) => {
        setLoading({loading: true})
        // console.log(JSON.stringify(values));
        // document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    history.push(redirectto);
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                }
                // setLoading({loading: false});
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
            });
    };

    useEffect(() => {
        // getPODetails();
    }, []);

    const getPODetails = async () => {
        if(isEdit === 1) {
            // document.getElementById("loader").style.display = "block";
                await axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
                    .then((res) => {
                        // document.getElementById("loader").style.display = "none";
                        //console.log(res.data);
                        if (res.data.status === 1) {

                            const data = res.data.data;
                            // console.log("DataObject : "+ JSON.stringify(data));

                            let vt = "Receipt";
                            let amount = (data.debit).toFixed(2);
                            if (data.credit > 0) {
                                vt = "Payment";
                                amount = (data.credit).toFixed(2);
                            }

                            setPO(res.data.data);

                            const dataObject = {
                                voucher_type: vt,
                                date: moment(data.date, "YYYY-MM-DD"),
                                reference_no: (data.voucher_no),
                                benificiary_type: (data.benificiary_type),
                                benificiary_id: (data.benificiary_id),
                                details: (data.title),
                                payment_method: data.type,
                                account_id: data.head_id,
                                sub_account_id: data.head_id,
                                title: data.details,
                                bank_account_id: data.bank_account_id,
                                amount: amount,
                                check_no: data.reference
                            };
                            // console.log("DataObject : " + dataObject);
                            form.setFieldsValue(dataObject);

                            const vn = [...vendor];
                            onPaymentMethodChange(data.type);
                            // onBenificaryChange(data.benificiary_type);
                            setBenificiaryData(vendor);

                            return true;
                            //updateCompanyList();

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        // document.getElementById("loader").style.display = "none";
                    });
        }
    }

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        // formRef.current.setFieldsValue({
        //     bank_account_id : null,
        //     reference : ""
        // });
    }


    const onBankAccountChange = (value) => {
        let ref = "";
        if(!isCash) {
            bankaccounts.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });

            // console.log("RefNo : " + ref);

            form.setFieldsValue({
                check_no: ref,
            });
        }
    }


    const uplod = {
        // name: 'file',
        // action: Config.apiserver+"settings/openingbalance",
        // headers: {
        //     'Authorization': token,
        // },
        // data : {
        //     'file' : formData
        // },
        // beforeUpload(file) {
        //     const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet';
        //     if (!isJpgOrPng) {
        //         message.error('You can only upload XlXS(Only Excel File) formated file!');
        //     }
        //     return isJpgOrPng;
        // },
        // onChange(info) {
        //     formData.append('file', info.file)
        //     if (info.file.status !== 'uploading') {
        //         console.log(info.file, info.fileList);
        //     }
        //     if (info.file.status === 'done') {
        //         message.success(`${info.file.name} file uploaded successfully`);
        //     } else if (info.file.status === 'error') {
        //         message.error(`${info.file.name} file upload failed.`);
        //     }
        // }
    }

    const onSegementChange = (e) => {
        const vl = e.target.value;
        if(isLoaded === 1) {
            console.log(doc[vl]);
            form.setFieldsValue({
                doc_class: null
            });
            setSegment(vl);
            setSelectedSegment(doc[vl]);
        }
        else {
            CisUI().showAlert("Alert","warning","Please wait for while, it's loading");
        }
    }


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }

    const onAmountChange = (e) => {
        setAmount(e);
        //console.log(e);
    }

    const DocumentClassType = () => {
        const comarea = CisUI().getUserInfo('comarea') || 0
        if(comarea <= 3) {
            return <>
                <Radio value={1}>HQ</Radio>
                <Radio value={2}>Area</Radio>
                <Radio value={3}>District</Radio>
                <Radio value={4}>Local</Radio>
                <Radio value={5}>Individual</Radio>
            </>
        }
        else if(comarea === 4) {
            return <>
                <Radio value={1}>HQ</Radio>
                <Radio value={2}>Area</Radio>
                <Radio value={3}>District</Radio>
                <Radio value={4}>Local</Radio>
                <Radio value={5}>Individual</Radio>
            </>
        }
        else if(comarea === 5) {
            return <>
                <Radio value={1}>HQ</Radio>
                <Radio value={2}>Area</Radio>
                <Radio value={3}>District</Radio>
                <Radio value={4}>Pentsos</Radio>
                <Radio value={5}>Pent Tv</Radio>
            </>
        }
    }


    const noBillField = (
        <>

            {
                po.id > 0 ?

                <>
                    <div className="row">

                        <div className="col-md-4">
                            <Form.Item
                                name="voucher_type"
                                label="Voucher Type"
                                initialValue={polist.voucher_type}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a voucher type"
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChangeRadio} value={voucherType}>
                                    <Radio value={"Payment"}>Payment</Radio>
                                    <Radio value={"Receipt"}>Receipt</Radio>
                                </Radio.Group>

                            </Form.Item>
                        </div>

                        <div className="col-md-4">
                            <Form.Item
                                name="date"
                                label="Date"
                                initialValue={polist.date}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a date"
                                    },
                                ]}
                            >
                                <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                            placeholder="Date"/>
                            </Form.Item>
                        </div>

                        {
                            CisUI().getUserInfo('com_id') > 0 ? <></> :
                                <div className="col-md-4">
                                    <Form.Item
                                        name="com_id"
                                        initialValue={polist.company_id}
                                        label="Company"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select an company"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an company"
                                            optionFilterProp="children"
                                            onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                Companies.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                        }

                        <div className="col-md-4">
                            <Form.Item
                                name="reference_no"
                                label="Voucher No#"
                                className={"disableinput"}
                                initialValue={Math.floor(Math.random() * 900000)}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter voucher number"
                                    },
                                ]}
                            >
                                <Input readOnly={true} className="gx-mb-3 gx-w-100 disableinput" placeholder="Enter a reference number"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-4" style={{display : "none"}}>
                            <Form.Item
                                name="doc_class"
                                initialValue={polist.doc_class}
                                label="Document Class"
                                rules={[
                                    {
                                        required: false,
                                        message: "Select a document class"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a document class"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        selectedSegment.length > 0 ?
                                            doc[segment].map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.class}-{items.description}</Option>
                                            ) : null
                                    }
                                </Select>
                            </Form.Item>

                            <div style={{paddingLeft: '20px'}}>
                                <Radio.Group onChange={onSegementChange} value={segment}>
                                    <Radio value={1}>HQ</Radio>
                                    <Radio value={2}>Area</Radio>
                                    <Radio value={3}>Individual</Radio>
                                    <Radio value={4}>Ministries</Radio>
                                    <Radio value={5}>Pentsos</Radio>
                                    <Radio value={6}>Pent Tv</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        {/*<div className="col-md-4" style={{display: 'none'}}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="benificiary_type"*/}
                        {/*        initialValue={polist.benificiary_type}*/}
                        {/*        label="Beneficiary/Source Type"*/}
                        {/*        rules={[*/}
                        {/*            {*/}
                        {/*                required: false,*/}
                        {/*                message: "Select a Beneficiary/source"*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            showSearch*/}
                        {/*            placeholder="Select an payment source"*/}
                        {/*            optionFilterProp="children"*/}
                        {/*            onChange={onBenificaryChange}*/}
                        {/*            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                Benificiary.map((items, index) =>*/}
                        {/*                    <Option key={++index} value={items.id}>{items.name}</Option>*/}
                        {/*                )*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-4" style={{display: 'none'}}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="benificiary_id"*/}
                        {/*        initialValue={polist.benificiary_id}*/}
                        {/*        label="Benificiary"*/}
                        {/*        rules={[*/}
                        {/*            {*/}
                        {/*                required: false,*/}
                        {/*                message: "Select a benificiary"*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            // showSearch*/}
                        {/*            placeholder="Select an benificiary"*/}
                        {/*            optionFilterProp="children"*/}
                        {/*            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                benificiariesData.map((items, index) =>*/}
                        {/*                    <Option key={++index} value={items.id}>{items.name}</Option>*/}
                        {/*                )*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}

                        <div className="col-md-4">
                            <Form.Item
                                name="account_id"
                                initialValue={polist.account_id}
                                label="Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an account"
                                    },
                                ]}
                            >
                                <Select
                                    // showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="children"
                                    onChange={onChangeAccount}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        mainaccount.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        {
                            subaccount.length > 0 ?
                                <div className="col-md-4">
                                    <Form.Item
                                        name="sub_account_id"
                                        initialValue={polist.account_id}
                                        label="Sub Account"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select an account"
                                            },
                                        ]}
                                    >
                                        <Select
                                            // showSearch
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                subaccount.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div> : ""
                        }


                        <div className="col-md-4">
                            <Form.Item
                                name="title"
                                label="Description/Narration"
                                initialValue={polist.details}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter description here"
                                    },
                                ]}
                            >
                                <Input placeholder="Enter description here"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-4">
                            <Form.Item
                                name="payment_method"
                                label="Payment Method"
                                initialValue={2}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select payment method"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a payable account"
                                    optionFilterProp="children"
                                    onChange={onPaymentMethodChange}
                                    allowClear={true}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        PaymentMethod.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-4" id="bankaccount" >
                            <Form.Item
                                name="bank_account_id"
                                label="Bank Account/Cash Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a bank account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a bank account"
                                    optionFilterProp="children"
                                    onChange={onBankAccountChange}
                                    allowClear={true}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        isCash ?
                                            cashaccount.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                            :
                                            bankaccounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                            )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <Form.Item
                                name="benificiary_type"
                                initialValue={polist.benificiary_type}
                                label="Beneficiary/Source Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a Beneficiary/source"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an payment source"
                                    optionFilterProp="children"
                                    onChange={onBenificaryChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Benificiary.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <Form.Item
                                name="benificiary_id"
                                initialValue={polist.benificiary_id}
                                label="Beneficiary"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a beneficiary"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an beneficiary"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        benificiariesData.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-4">
                            <Form.Item
                                name="amount"
                                label="Amount"
                                initialValue={polist.title}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter amount here"
                                    },
                                ]}
                            >
                                {/*<InputNumber*/}
                                {/*    style={{width: '100%'}}*/}
                                {/*    defaultValue={amount}*/}
                                {/*    formatter={value => `₵ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*    parser={value => value.replace(/\₵\s?|(,*)/g, '')}*/}
                                {/*    onChange={onAmountChange}*/}
                                {/*/>*/}
                                <Input placeholder="Enter amount here"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-4">
                            <Form.Item
                                name="check_no"
                                label="Cheque No# / Reference No#"
                                initialValue={polist.remark}
                                rules={[
                                    {
                                        required: false,
                                        message: "Enter Cheque no"
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Cheque/reference no"/>
                            </Form.Item>
                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                            <Button id="submitbutton" type="primary" htmlType="submit">
                                Save
                            </Button>
                            <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                        </div>
                    </div>
                </>
                : <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <div id="loader" className="loading_view" style={{display: 'block'}}>
                            <div className="loading_center2">
                                <div className="loader-table" style={{textAlign: 'center'}}>
                                    <img style={{background : 'transparent'}} src="/assets/images/coploading.gif" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

    const ApproveOrReject = (
        <>
            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                {
                    bill.status === 0 ?
                        isApprover === true
                            ? <>
                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="button">Reject</Button>
                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="button">Approve</Button>
                            </>
                            : ""
                        : ""
                }
                {
                    bill.status === 1 ?
                        hasPayment === false ?
                            <><Button id="submitbutton" type="primary" onClick={showModalReject} htmlType="button"><Link to={"newpayment?bill_id="+bill.id}>Payment</Link></Button></>
                            : ""
                        : ""
                }
                <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            </div>
        </>
    );

    const PaymentInformaiton = (
        <></>
    );

    let totalPaid = 0;

    return (
        <Card title={"Voucher Entry"}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >

                {CisUI().showLoading}

                {
                    noBillField
                }



            </Form>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default VoucherEdit;