import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
import jsonfile from './nation.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;

const HQTitheReport = () => {
    const value = 0;
    const [data,setData] = useState([]);
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.hq_tithe_report,
        filtervalue : '',
        subtitle:''
    });

    useEffect(() => {
        getAPIData();
    }, []);

    const history = useHistory();

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);

        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    return `${key}=${CisUI().ParseDateFormat(values[key])}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.hq_tithe_report+"/?area=Region&"+qsup;
        console.log("NEURL :" + newURL);
        setState({reqURL: newURL});

        getAPIData();
    };

    const getAPIData = () => {
        //console.log("test"+state.reqURL);
        document.getElementById("loader").style.dispaly = "block";
        axios.get(state.reqURL)
            .then((res) => {
                //console.log(res.data);
                document.getElementById("loader").style.dispaly = "none";
                if(res.data.status === 1) {
                    const data = res.data.data;
                    setData(data);
                }
            })
            .catch((error) => {
                document.getElementById("loader").style.dispaly = "none";
                console.log(error);
            });
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data","local_expense_report",jsonfile,state.subtitle, "landscape")}
        </>
    );

    return (
        <>
            {searchView}
            {CisUI().showLoading}
            <Card title="Tithes Report" >
                <div className="table-responsive">

                    <table className="report2 table table-bordered">

                        <thead>
                        <tr>
                            {
                                jsonfile.listView.hq_title.map((items,index)=>{
                                    return <th>{items}</th>
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((items,index)=>{
                                if(items.fl_type === 'total') {
                                    return <tr>
                                        <th colSpan={2}>{items.area}</th>
                                        <th style={{textAlign: 'right'}} >{CisUI().getCurrencyFormated1(items.gross_tithe)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</th>
                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</th>
                                    </tr>
                                }
                                else {
                                    return <tr>
                                        <td>{items.id}</td>
                                        <td>{items.area}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.gross_tithe)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</td>
                                    </tr>
                                }
                            })
                        }
                        </tbody>
                    </table>

                </div>
            </Card>
        </>
    );
};

export default HQTitheReport;