import {Form, Card, notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './member.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";

const NewUser = (props) => {

    const [loading, setLoading] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();


    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }

    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        let msg = "Sorry! request not processed, please try again";
        //document.getElementById("loader").style.display = "block";

        notification.warning({
            message: 'Alert',
            type : "warning",
            description: "Successfully inserted"
        });

        // axios.post(Config.apiserver + endpoint, values)
        //     .then(res => {
        //         console.log(res)
        //         if(res.data.status === 1) {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //             history.push(redirectto);
        //         }
        //         else {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //         }
        //         setLoading({loading:false});
        //         document.getElementById("loader").style.display = "none";
        //     })
        //     .catch(errors => {
        //         console.log(errors);
        //         // msg = (errors);
        //         setLoading({loading:false});
        //
        //         document.getElementById("loader").style.display = "none";
        //         //setResponse([]);
        //     });
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={<IntlMessages id={Title} />} extra={
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
        }>

            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {InputFields(jsonfile.input,userData,isEdit)}
            </Form>


        </Card>
    );
};

export default NewUser;