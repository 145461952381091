import React from "react";
import {Form, Card, notification, Button} from 'antd';
import jsonfile from './leads.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";

const NewLead = (props) => {

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let customerredirectto = "../"+jsonfile.urls.customerList;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
        customerredirectto = "../../"+jsonfile.urls.customerList;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });

                    values.lead_status == "customer" ? history.push(customerredirectto) : history.push(redirectto);

                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            <>
                {
                    isEdit === 1
                        ? CisUI().addAction(redirectto)
                        : CisUI().addAction(redirectto)
                }
            </>
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFields(jsonfile.input,userData,isEdit,props,'',redirectto)}
            </Form>
        </Card>
    );
};

export default NewLead;