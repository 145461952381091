import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, Popconfirm, notification, Radio, Upload, message} from 'antd';
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";

const {Option} = Select;
const {TextArea} = Input;

const MultipleVoucherEdit = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [mdata, setMdata] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [isCash, setIsCash] = useState(false);
    const [cashaccount, setCashAccount] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);
    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        subsector : [],
        committee: [],
        area: [],
        district: [],
        local: []
    });


    const [doc, setDoc] = useState({});
    const [selectedSegment, setSelectedSegment] = useState([]);

    const [voucherType, setVoucherType] = useState("Payment");

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
    };


    const [bill, setBill] = useState(
        {
            totalReceipt : 0,
            totalPayment : 0
        }
    );


    const BankName = SelectData("banknames");
    const Benificiary = SelectData("accountbenificary");
    const [benificiariesData, setBenificiaryData] = useState([]);
    const PaymentMethod = SelectData("paymentmethod");
    const BankAccount = SelectData("bankaccount");
    // const Area = SelectData("allarea");
    // const District = SelectData("alldistrict");
    // const Local = SelectData("alllocal");

    let BenificaryData = [];

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        } else if (e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        } else if (e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        } else if (e === 6) {
            BenificaryData = beneficiaryData.department;
            setBenificiaryData(beneficiaryData.department);
        } else if (e === 7) {
            BenificaryData = beneficiaryData.area;
            setBenificiaryData(beneficiaryData.area);
        } else if (e === 8) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 9) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            BenificaryData = beneficiaryData.ministry;
            setBenificiaryData(beneficiaryData.ministry);
        } else if (e === 11) {
            BenificaryData = beneficiaryData.pentsos;
            setBenificiaryData(beneficiaryData.pentsos);
        } else if (e === 12) {
            BenificaryData = beneficiaryData.district;
            setBenificiaryData(beneficiaryData.district);
        } else if (e === 13) {
            BenificaryData = beneficiaryData.local;
            setBenificiaryData(beneficiaryData.local);
        }
        else if (e === 14) {
            BenificaryData = beneficiaryData.committee;
            setBenificiaryData(beneficiaryData.committee);
        }
        else if (e === 15) {
            BenificaryData = beneficiaryData.subsector;
            setBenificiaryData(beneficiaryData.subsector);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }


    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: null,
        benificiary_id: null,
        vendor_id: null,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });

    const onBankChange = (value) => {
        const vl = value+Math.floor(Math.random() * 900000);
        form.setFieldsValue({reference_no : vl});
    }


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.many_voucher_edit;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.many_voucher_edit + "/" + userID;
        redirectto = "../../vendor/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Vendors = [];
    // const Companies = SelectData("company/companylists");


    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        // const comID = CisUI().getUserInfo('com_id');
        // if(comID > 0) {
        //     onCompanyChangeForComapny(comID);
        // }
        OrderDetails();
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            // document.getElementById("loader").style.display = "block";
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setChildAccount(res.data.child_account);
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setMainAccount(res.data.main_account);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            subsector: res.data.subsector,
                            committee: res.data.committee,
                            area: res.data.area,
                            district: res.data.district,
                            local: res.data.local
                        });
                        OrderDetails(res.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // document.getElementById("loader").style.display = "none";
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }


    // if(loaded) {
    //     OrderDetails();
    // }

    const OrderDetails = async (resp = null) => {
        // document.getElementById("loader").style.display = "block";
        return await axios.get(jsonfile.urls.viewEdit + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.data;
                    //onCompanyChange(podata.company_id);
                    let  trxType = "";
                    if(podata.debit > 0) {
                        setVoucherType("Receipt");
                        trxType = "Receipt";
                    }
                    else {
                        setVoucherType("Payment");
                        trxType = "Payment";
                    }

                    let head_id = podata.bank_account_id;

                    if(podata.type === 1) {
                        head_id = podata.head2_id;
                        setIsCash(true);
                    }


                    form.setFieldsValue({
                        date: moment(podata.date, 'YYYY-MM-DD'),
                        // bankname_id: podata.bank_id,
                        reference_no: podata.voucher_no,
                        payment_method: podata.type,
                        bank_account_id: head_id,
                        check_no: podata.reference,
                        remark: podata.remark,
                        notes: podata.notes,
                        trx_type: trxType
                    })
                    // setVendor(res.data.vendors);
                    setPOdata(res.data.data);
                    setMdata(res.data.multi);

                    setChildAccount(res.data.child_account);
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setBankAccounts(res.data.bank);
                    setEmployee(res.data.employee);
                    setCustomer(res.data.customer);
                    setCashAccount(res.data.cashaccounts);
                    setMainAccount(res.data.main_account);
                    setBeneficiaryData({
                        department: res.data.department,
                        users: res.data.users,
                        ministry: res.data.ministry,
                        pentsos: res.data.pentsos,
                        subsector: res.data.subsector,
                        committee: res.data.committee,
                        area: res.data.area,
                        district: res.data.district,
                        local: res.data.local
                    });

                    const child = res.data.child_account;

                    let allrows = [];
                    res.data.multi.map((items, index)=> {
                        let amount = items.debit;
                        if(items.credit > 0) {
                            amount = items.credit;
                        }
                        // console.log("SubAccount: "+JSON.stringify(accountchild));
                        const sub = child[items.account_id] || [];
                        // console.log(JSON.stringify(sub));
                        let e = items.benificiary_type;
                        let BF = [];

                        if (e === 2) {
                            BF = res.data.customer;
                        } else if (e === 3) {
                            BF = res.data.vendor;
                        } else if (e === 4) {
                            BF = res.data.employee;
                        } else if (e === 5) {
                            BF = res.data.accounts;
                        } else if (e === 6) {
                            BF = res.data.department;
                        } else if (e === 7) {
                            BF = res.data.area;
                        } else if (e === 8) {
                            BF = res.data.employee;
                        } else if (e === 9) {
                            BF = res.data.users;
                        } else if (e === 10) {
                            BF = res.data.ministry;
                        } else if (e === 11) {
                            BF = res.data.pentsos;
                        } else if (e === 12) {
                            BF = res.data.district;
                        } else if (e === 13) {
                            BF = res.data.local;
                        }
                        else if (e === 14) {
                            BF = res.data.committee;
                        }
                        else if (e === 15) {
                            BF = res.data.subsector;
                        }

                        allrows.push({
                            id: items.id,
                            account_id: items.account_id,
                            sub_account_id: items.sub_account_id,
                            description: items.details,
                            checkno: items.reference,
                            beneficiary_source : items.benificiary_type,
                            beneficiary_id : items.benificiary_id,
                            payment_method : items.type,
                            bank_account_id : items.bank_account_id,
                            amount: amount,
                            subaccounts: sub,
                            beneficiary: BF,
                        })

                        //onBenificaryChange(items.benificiary_type);

                    });

                    setState({
                        rows: allrows
                    })

                    let totalsReceipt = 0
                    let totalsPayment = 0
                    let totalAmount = 0
                    allrows.map((item, idx) => {
                        totalAmount += parseFloat(item.amount);
                    });


                    setBill({
                        totalReceipt : totalAmount
                    });

                    setLoaded(true);

                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                console.log(error);
                // document.getElementById("loader").style.display = "none";
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})
        setLoaded(true);
        let newRows = [];
        state.rows.map((items,ind)=>{
            newRows.push({
                id: items.id,
                account_id: items.account_id,
                sub_account_id: items.sub_account_id,
                description: items.description,
                checkno: items.checkno,
                beneficiary_source : items.beneficiary_source,
                beneficiary_id : items.beneficiary_id,
                payment_method : items.payment_method,
                bank_account_id : items.bank_account_id,
                amount : items.amount,
            });
        });

        values["items"] = newRows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    // CisUI().showAlert("Success","success",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    history.push("../../entries/mvoucherdetails/"+podata.voucher_no);
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
                setLoading({loading: false});
                setLoaded(false);
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                setLoading({loading: false});
                setLoaded(false);
                document.getElementById("loader").style.display = "none";
            });
    };


    const ItemCellChange = (idx,cname="") => e => {
        let name = "";
        let value = null;
        if(cname === "") {
            name = e.target.name;
            value = e.target.value;
        }
        else {
            name = cname;
            value = e;
        }

        const rows = [...state.rows];
        // console.log("Index:"+ idx);
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        // console.log("Event:"+ name);
        // console.log("EventValue:"+ value);

        if(name === "account_id") {
            const sub = accountchild[parseInt(value)] || [];
            setSubAccount(sub);
            rows[idx] = {
                id: rows[idx].id,
                account_id: value,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: sub,
                beneficiary: rows[idx].beneficiary,
            };
        }
        else if(name === "sub_account_id") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: value,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary,
            };
        }
        else if(name === "description") {
            //const amount = value * rows[idx].price;
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: value,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary,
            };
        }
        else if(name === "amount") {
            //CalculateCell();
            //const amount = value * rows[idx].qty;
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: value,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary,
            };
        }
        else if(name === "checkno") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: value,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "beneficiary_source") {
            e = value;
            let BF = [];
            if (e === 2) {
                BF = customer;
            } else if (e === 3) {
                BF = vendor;
            } else if (e === 4) {
                BF = employee;
            } else if (e === 5) {
                BF = accounts;
            } else if (e === 6) {
                BF = beneficiaryData.department;
            } else if (e === 7) {
                BF = beneficiaryData.area;
            } else if (e === 8) {
                BF = beneficiaryData.users;
            } else if (e === 9) {
                BF = beneficiaryData.users;
            } else if (e === 10) {
                // console.log(e);
                // console.log(beneficiaryData.ministry);
                BF = beneficiaryData.ministry;
            } else if (e === 11) {
                BF = beneficiaryData.pentsos;
            } else if (e === 12) {
                BF = beneficiaryData.district;
            } else if (e === 13) {
                BF = beneficiaryData.local;
            }
            else if (e === 14) {
                BF = beneficiaryData.committee;
            }
            else if (e === 15) {
                BF = beneficiaryData.subsector;
            }

            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : value,
                beneficiary_id : null,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: BF
            };
        }
        else if(name === "beneficiary_id") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : value,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "payment_method") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : value,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "bank_account_id") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : value,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }

        setState({
            rows: rows
        });

        let totalsReceipt = 0
        let totalsPayment = 0
        let totalAmount = 0
        rows.map((item, idx) => {
            totalAmount += parseFloat(item.amount);
        });


        setBill({
            totalReceipt : totalAmount
        });

    };


    const onPaymentMethodChange = (idx,cname="") => e => {
        const value = e;
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        if(!isCash) {
            bankaccounts.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });

            // console.log("RefNo : " + ref);

            form.setFieldsValue({
                check_no: ref,
            });
        }
    }


    const handleAddRow = () => {
        const item = {
            id: 0,
            account_id: null,
            sub_account_id: null,
            doc_class: null,
            selectedsegment: null,
            description: "",
            checkno : "",
            beneficiary_source : null,
            beneficiary_id : null,
            payment_method : null,
            bank_account_id : null,
            amount: 0,
            files : [],
            file_location : [],
            subaccounts: [],
            beneficiary: []
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        // handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }

    const onLineDelete = (lineID) => {
        // setLoading({loading: true});
        // setLoaded(true);
        document.getElementById("loader").style.display = "block";
        axios.delete(Config.apiserver + jsonfile.urls.linedelete+"/"+lineID)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    // window.location.reload();
                    const ft = state.rows.filter((item) => item.id !== lineID);
                    setState({rows: ft});
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
                setLoading({loading: false});
                setLoaded(false);
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
                setLoading({loading: false});
                setLoaded(false);
                // document.getElementById("loader").style.display = "none";
            });
    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...state.rows];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setState({rows: list});
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    var totalAmount = 0;

    const formField = (

        <>
            <div className="row">

                <div className="col-md-3">
                    <Form.Item
                        name="voucher_type"
                        label="Voucher Type"
                        initialValue={"Payment"}
                        rules={[
                            {
                                required: true,
                                message: "Select a voucher type"
                            },
                        ]}
                    >
                        <Radio.Group name={"trx_type"} onChange={onChangeRadio} value={voucherType}>
                            <Radio value={"Payment"}>Payment</Radio>
                            <Radio value={"Receipt"} style={voucherType === 'Receipt' ? {color: "green"} : {}} >Receipt</Radio>
                        </Radio.Group>

                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="reference_no"
                        label="Voucher No#"
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input readOnly={true} className="gx-mb-3 gx-w-100 readonly" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        initialValue={2}
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3" id="bankaccount" >
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                isCash ?
                                    cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="check_no"
                        label="Check No#"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter check no"
                            },
                        ]}
                    >
                        <Input placeholder="Enter check/reference no"/>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="notes"
                        label="Remark/Memo"
                        initialValue={polist.notes}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter something here"/>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Voucher Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>GL Code / Account</th>
                            <th>Beneficiary</th>
                            <th style={{width : '300px'}}>Item Description</th>
                            {
                                voucherType === "Receipt" ? <th style={{width : '100px'}}>Receipt</th> : <th>Payment</th>
                            }
                            {/*<th style={{width: '120px'}}>Attachment</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}>
                                    {
                                        item.id > 0 ? <>
                                            <Popconfirm
                                                placement="topLeft"
                                                title={"Are you sure you want to delete this line?"}
                                                // description={"Are you sure you want to delete this line?"}
                                                onConfirm={() => onLineDelete(item.id)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <a href="#" className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                            </Popconfirm>
                                        </> : <a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                    }
                                </td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        defaultValue={item.account_id}
                                        onChange={ItemCellChange(idx,"account_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            mainaccount.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br/> <br/>
                                    {
                                        item.sub_account_id > 0 ?
                                            <Select
                                                showSearch
                                                placeholder="Select a sub account"
                                                optionFilterProp="children"
                                                style={{width : '100%'}}
                                                defaultValue={item.sub_account_id}
                                                onChange={ItemCellChange(idx,"sub_account_id")}
                                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                            >
                                                {
                                                    item.subaccounts.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select> : item.subaccounts.length > 0 ? <>
                                                <Select
                                                    showSearch
                                                    placeholder="Select a sub account"
                                                    optionFilterProp="children"
                                                    style={{width : '100%'}}
                                                    defaultValue={item.sub_account_id}
                                                    onChange={ItemCellChange(idx,"sub_account_id")}
                                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                                >
                                                    {
                                                        item.subaccounts.map((items, index) =>
                                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </> : <></> }
                                </td>

                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary source"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        value={item.beneficiary_source}
                                        onChange={ItemCellChange(idx,"beneficiary_source")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            Benificiary.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br /> <br />
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        value={item.beneficiary_id}
                                        onChange={ItemCellChange(idx,"beneficiary_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            item.beneficiary.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>


                                <td>
                                    <input className="form-control" placeholder="Enter Item Description" name="description" value={state.rows[idx].description} onChange={ItemCellChange(idx)}  />
                                </td>
                                {
                                    voucherType === "Receipt" ?
                                        <td style={{width : '100px'}}><input className="form-control text-right" style={voucherType === 'Payment' ? {color: "#545454", textAlign : 'right'} : {color: "green" , textAlign : 'right'}} placeholder="Receipt" name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                        : <td style={{width : '100px'}}><input className="form-control text-right" style={voucherType === 'Payment' ? {color: "#545454", textAlign : 'right'} : {color: "green" , textAlign : 'right'}} name="amount" placeholder="Payment" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                }
                                {/*<td>*/}
                                {/*    <Upload data= {*/}
                                {/*        {*/}
                                {/*            file : formData,*/}
                                {/*            type : "manyvouher",*/}
                                {/*            file_id : fileUID,*/}
                                {/*            row_id : item.id,*/}
                                {/*            line_index : idx*/}
                                {/*        }*/}
                                {/*    } {...uplod}>*/}
                                {/*        <Button icon={<UploadOutlined />}></Button>*/}
                                {/*    </Upload>*/}
                                {/*</td>*/}
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Total</td>
                            {
                                voucherType === "Receipt" ?
                                    <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                                    :<td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                            }
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    return (
        <Card title={<IntlMessages id={"Multiple Voucher Edit"} />} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div onLoad={OrderDetails}></div>
                <div className="row">
                    {
                        loaded ?
                            <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                            : <h4 style={{textAlign: "center", color: "red"}}>Please wait ...</h4>
                    }
                </div>

            </Form>
        </Card>
    );
};

export default MultipleVoucherEdit;