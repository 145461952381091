import React from "react";
import {Route, Switch} from "react-router-dom";

import ChartOfAccounts from "../../containers/yotta/financial/chartofaccounts/list";
import DefaultChartofAccountList from "../../containers/yotta/financial/chartofaccounts/dfallaccounts";
import NewChartOfAccounts from "../../containers/yotta/financial/chartofaccounts/new";
import NationalAccountList from "../../containers/yotta/financial/chartofaccounts/nationalaccounts";
import RegionAccountList from "../../containers/yotta/financial/chartofaccounts/regionaccounts";
import DistrictAccountList from "../../containers/yotta/financial/chartofaccounts/districtaccounts";
import LocalAccountList from "../../containers/yotta/financial/chartofaccounts/localaccounts";
import TitheCollection from "../../containers/yotta/donation/collection/list";
import BillPaymentHistory from "../../containers/yotta/vendor_management/bill_payment/paymenthistory";
import AllTransactions from "../../containers/yotta/financial/transactions/alltransactions";
import NewTransaction from "../../containers/yotta/financial/transactions/newtransactionrcv";
import NewTransactionPay from "../../containers/yotta/financial/transactions/newpayment";
import AllBankTransactions from "../../containers/yotta/financial/bank/banktransaction";
import NewBankDeposit from "../../containers/yotta/financial/bank/newdeposit";
import NewBudget from "../../containers/yotta/financial/budget/newbudget";
import NewJournal from "../../containers/yotta/financial/journal/newjournal";
import JournalList from "../../containers/yotta/financial/journal/journallist";
import BankReconcilation from "../../containers/yotta/financial/bank/bankreconcile";
import GeneralLedgerV1 from "../../containers/yotta/financial/ledger/ledger";
import GeneralLedger from "../../containers/yotta/financial/ledger/ledgerv4";
import BankReconcileStart from "../../containers/yotta/financial/bank/bankreconcilestart";
import JournalDetails from "../../containers/yotta/financial/journal/journaldetails";
import Journaledit_off from "../../containers/yotta/financial/journal/journaledit";
import ReconcileHistory from "../../containers/yotta/financial/bank/reconcilehistory";
import ReconcileDetails from "../../containers/yotta/financial/bank/reconciledetails";
import TrialBalance from "../../containers/yotta/financial/trialbalance/trialbalance";
import IncomeStatement from "../../containers/yotta/financial/incomestatement/incomestatement";
import BalanceSheet from "../../containers/yotta/financial/balancesheet/balancesheet";
import StatementofActivity from "../../containers/yotta/financial/report/statementofactivity";
import StatementofFunctionalExpense from "../../containers/yotta/financial/report/statementoffunctionalexpense";
import CashflowStatement from "../../containers/yotta/financial/report/cashflow";
import PerformanceSummary from "../../containers/yotta/financial/report/smryperform";
import BudgetHistory from "../../containers/yotta/financial/budget/budgethistory";
import BudgetReport from "../../containers/yotta/financial/budget/budgetreport";
import BudgetDetails from "../../containers/yotta/financial/budget/budgetdetails";
import Transactionedit from "../../containers/yotta/financial/transactions/transactionedit";
import PeriodicalBudgetReport from "../../containers/yotta/financial/budget/periodicalreport";
import BudgetEdit from "../../containers/yotta/financial/budget/budegetedit";
import StatementofFinancialPosition from "../../containers/yotta/financial/balancesheet/statementoffinposition";
import LedgerSummary from "../../containers/yotta/financial/ledger/ledgersummary";
import BankAccountSummary from "../../containers/yotta/financial/ledger/bankaccountsummary";
import BankAccountTransactionDetails from "../../containers/yotta/financial/ledger/bankaccountdetails";
import CashflowStatementDetails from "../../containers/yotta/financial/ledger/cashflowstatement";
import TrialBalanceV3 from "../../containers/yotta/financial/trialbalance/trialbalanceV3";
import ProfitandLoss from "../../containers/yotta/financial/ledger/profitandloss";
import NewAdminGL from "../../containers/yotta/financial/chartofaccounts/admin/new";
import HQGLList from "../../containers/yotta/financial/chartofaccounts/admin/hq";
import AreaGLList from "../../containers/yotta/financial/chartofaccounts/admin/arealist";
import DistrictGLList from "../../containers/yotta/financial/chartofaccounts/admin/districtgllist";
import LocalGLList from "../../containers/yotta/financial/chartofaccounts/admin/locallist";

const Financial = ({match}) => (
    <Switch>
        <Route path={`${match.url}/allaccounts`} component={ChartOfAccounts}/>
        <Route path={`${match.url}/dfallaccounts`} component={DefaultChartofAccountList}/>
        <Route path={`${match.url}/nationalchart`} component={NationalAccountList}/>
        <Route path={`${match.url}/regionlevel`} component={RegionAccountList}/>
        <Route path={`${match.url}/districtlevel`} component={DistrictAccountList}/>
        <Route path={`${match.url}/locallevel`} component={LocalAccountList}/>
        <Route path={`${match.url}/newaccount`} component={NewChartOfAccounts}/>
        <Route path={`${match.url}/accountedit/:id`} component={NewChartOfAccounts}/>
        <Route path={`${match.url}/donationrcv`} component={TitheCollection}/>
        <Route path={`${match.url}/billpay`} component={BillPaymentHistory}/>
        <Route path={`${match.url}/alltrx`} component={AllTransactions}/>
        <Route path={`${match.url}/newtrxrcv`} component={NewTransaction}/>
        <Route path={`${match.url}/newpayment`} component={NewTransactionPay}/>
        <Route path={`${match.url}/banktrx`} component={AllBankTransactions}/>
        <Route path={`${match.url}/newbankdeposit`} component={NewBankDeposit}/>
        <Route path={`${match.url}/newbudget`} component={NewBudget}/>
        <Route path={`${match.url}/budgethistory`} component={BudgetHistory}/>
        <Route path={`${match.url}/newjournal`} component={NewJournal}/>
        <Route path={`${match.url}/journlalist`} component={JournalList}/>
        <Route path={`${match.url}/journaldetails/:id`} component={JournalDetails}/>
        <Route path={`${match.url}/journaledit/:id`} component={Journaledit_off}/>
        <Route path={`${match.url}/bankreconcile`} component={BankReconcilation}/>
        <Route path={`${match.url}/reconcilestart`} component={BankReconcileStart}/>
        <Route path={`${match.url}/reconcilehistory`} component={ReconcileHistory}/>
        <Route path={`${match.url}/reconciledetails/:id`} component={ReconcileDetails}/>
        <Route path={`${match.url}/ledger`} component={GeneralLedger}/>
        <Route path={`${match.url}/ledgerv1`} component={GeneralLedgerV1}/>
        <Route path={`${match.url}/trialbalance`} component={TrialBalanceV3}/>
        <Route path={`${match.url}/incomestmnt`} component={IncomeStatement}/>
        <Route path={`${match.url}/balancesheet`} component={BalanceSheet}/>
        <Route path={`${match.url}/finposition`} component={StatementofFinancialPosition}/>
        <Route path={`${match.url}/stmntactivity`} component={StatementofActivity}/>
        <Route path={`${match.url}/stmntfunctionalexpense`} component={StatementofFunctionalExpense}/>
        <Route path={`${match.url}/cashflow`} component={CashflowStatement}/>
        <Route path={`${match.url}/smryperform`} component={PerformanceSummary}/>
        <Route path={`${match.url}/budgetreport`} component={BudgetReport}/>
        <Route path={`${match.url}/budgetdetails/:id`} component={BudgetDetails}/>
        <Route path={`${match.url}/traxedit/:id`} component={Transactionedit}/>
        <Route path={`${match.url}/periodicalbudgetreport`} component={PeriodicalBudgetReport}/>
        <Route path={`${match.url}/budgetedit/:id`} component={BudgetEdit}/>
        <Route path={`${match.url}/ledgersummary`} component={LedgerSummary}/>
        <Route path={`${match.url}/bankactsummary`} component={BankAccountSummary}/>
        <Route path={`${match.url}/bankactdetails`} component={BankAccountTransactionDetails}/>
        <Route path={`${match.url}/cashflowstmnt`} component={CashflowStatementDetails}/>
        <Route path={`${match.url}/profitandloss`} component={ProfitandLoss}/>
        <Route path={`${match.url}/addnewgl`} component={NewAdminGL}/>
        <Route path={`${match.url}/hqgllist`} component={HQGLList}/>
        <Route path={`${match.url}/areagllist`} component={AreaGLList}/>
        <Route path={`${match.url}/districtgllist`} component={DistrictGLList}/>
        <Route path={`${match.url}/localgllist`} component={LocalGLList}/>
    </Switch>
);

export default Financial;
