import {Form, Upload, message, Row, Col, Input, Button, Card, Select, Checkbox,notification, Spin} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, {Component,useState, useEffect} from "react";
import {CisUI} from '../../../util/CISUI'
import PostToApi from '../../../util/PostToApi'
import InputFields from '../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api"; 
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";

import ReactDOM from 'react-dom';
// import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';
// import connector from '@opuscapita/react-filemanager-connector-node-v1';
// import _scope from '@opuscapita/react-filemanager-connector-google-drive-v2';


const { Dragger } = Upload;
const FileManagerDocument = (props) => {

    // const apiOptions = {
    //     ...connector.apiOptions,
    //     apiRoot: `https://demo.core.dev.opuscapita.com/filemanager/master`
    // };

    return (
        <div style={{ height: '480px' }}>
            {/*<FileManager>*/}
            {/*    <FileNavigator*/}
            {/*        id="filemanager-1"*/}
            {/*        api={connector.api}*/}
            {/*        apiOptions={apiOptions}*/}
            {/*        capabilities={connector.capabilities}*/}
            {/*        listViewLayout={connector.listViewLayout}*/}
            {/*        viewLayoutOptions={connector.viewLayoutOptions}*/}
            {/*    />*/}
            {/*</FileManager>*/}
        </div>
    );


}

export default FileManagerDocument;