import {Form, Upload, Row, Col, Radio, Input, Button, Card, Collapse, message, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import StaticSelectedData from "../../../util/StaticSelectData";
import {value} from "lodash/seq";
import { UploadOutlined } from '@ant-design/icons';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const ProvisionalCashClearence = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.payment_receive);
    const [hasPayment, setHasPayment] = useState(false);
    const [payments, setPayment] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});
    const [doc, setDoc] = useState({});
    const [segment, setSegment] = useState(1);
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(true);
    const [benificiariesData, setBenificiaryData] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [benificiaryaccount, setBenificiaryaccount] = useState([]);
    const [voucherType, setVoucherType] = useState("Payment");
    const [files, setFiles] = useState([]);

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        BillStatusUpdate(bill.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        BillStatusUpdate(bill.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };


    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("vendor/billstatusupdate?bill_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const PaymentMethod = SelectData("paymentmethod");
    const BankAccount = SelectData("bankaccount");
    const Benificiary = SelectData("accountbenificary");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+polist.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setExpense(res.data.expense);
                    setBankAccounts(res.data.bank);
                    setEmployee(res.data.employee);
                    setCustomer(res.data.customer);
                    setProject(res.data.project);
                    setCashAccount(res.data.cashaccount);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: null,
        benificiary_id: null,
        vendor_id: null,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });


    const [state, setState] = useState({
        po: {},
        items: []
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = "entries/singlevoucher";
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.rcv + "/" + userID);
        setTitle(jsonfile.edittitle);
        endpoint = "entries/voucheredit" + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    let BenificaryData = [];

    const onFinish = (values) => {
        setLoading({loading: true})
        console.log(JSON.stringify(values));
        if(files.length > 0) {
            document.getElementById("loader").style.display = "block";
            values['fileItem'] = files;
            values["checque_status"] = 1;
            axios.post(Config.apiserver + endpoint, values)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        CisUI().showAlert("Success", "success", res.data.msg);
                        document.getElementById("loader").style.display = "none";
                        history.push(redirectto);
                    } else {
                        CisUI().showAlert("Alert", "warning", res.data.msg);
                        document.getElementById("loader").style.display = "none";
                    }
                    setLoading({loading: false});

                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    setLoading({loading: false});
                    document.getElementById("loader").style.display = "none";
                });
        }
        else {
            CisUI().showAlert("Alert", "warning", "Attachment is required");
        }
    };

    let POData = {};

    useEffect(() => {
        // getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        if(isEdit === 0) {
            axios.get(Config.apiserver + "purchase/podetails/" + poid)
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        const PODataD = res.data.podata;
                        if(res.data.hasBill === 1) {
                            setBill(res.data.bill);
                            setHasbill(true);
                            setTitle("Bill Details");
                        }
                        if(res.data.isApprover === 1) {
                            setApprover(true);
                        }
                        if(res.data.hasPayment === 1) {
                            setHasPayment(true);
                            setPayment(res.data.payment);
                        }
                        setPolist({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company: PODataD.company,
                            vendor: PODataD.vendor_name,
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_id,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark,
                            status : PODataD.status,
                            approved_by : PODataD.approved_user,
                            approved_at : PODataD.approved_at
                        });

                        updateCompanyList();

                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }
        else {
            setPolist({
                po_number : userData.po_number,
                date: CisUI().DateFormat(userData.date),
                due_date: CisUI().DateFormat(userData.due_date),
                reference_no: userData.reference_no,
                company_id: userData.company_id,
                vendor_id: userData.vendor_d,
                payable_id: userData.payable_id,
                fund_program_id : userData.fund_program_id,
                func_expense_id : userData.func_expense_id,
                amount : userData.amount,
                description : userData.description,
                remark : userData.remark
            });
        }
    }

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        bankaccounts.map((items, index) => {
            if (items.id === value) {
                if (items.last_issued_check > 0) {
                    ref = parseInt(items.last_issued_check) + 1;
                } else {
                    ref = items.starting_check_no;
                }
            }
        });
    }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ",e);
        if(e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        }
        else if(e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        }
        else if(e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        }
        else if(e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        }

        form.setFieldsValue({
            benificiary_id : null
        });

    }

    const onSegementChange = (e) => {
        const vl = e.target.value;
        console.log(doc[vl]);
        form.setFieldsValue({
            doc_class: null
        });
        setSegment(vl);
        setSelectedSegment(doc[vl]);
    }


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }

    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        let filedata = [...files];
                        filedata.push({
                            file_id : data.data.id,
                            file_location : data.data.location,
                        });
                        setFiles(filedata);
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    const noBillField = (
        <>

            <div className="row">

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                        <div className="col-md-4">
                            <Form.Item
                                name="com_id"
                                initialValue={polist.company_id}
                                label="Department/Branch"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a Department/Branch"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a Department/Branch"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="PV No#"
                        initialValue={"PC"+Math.floor(Math.random() * 900000)}
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="benificiary_id"
                        initialValue={polist.benificiary_id}
                        label="Employee"
                        rules={[
                            {
                                required: true,
                                message: "Select an employee"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an employee"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                employee.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="purpose"
                        label="Purpose Imprest"
                        initialValue={polist.details}
                        rules={[
                            {
                                required: true,
                                message: "Enter purpose imprest here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter purpose imprest here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="date_clearance"
                        label="Date of Collection"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Amount Collected"
                        initialValue={polist.title}
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Total Expenses"
                        initialValue={polist.title}
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="title"
                        label="Details"
                        initialValue={polist.details}
                        rules={[
                            {
                                required: true,
                                message: "Enter details here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter details here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Balance Due To/From/me"
                        initialValue={polist.title}
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter amount here"/>
                    </Form.Item>
                </div>


                <div className="col-md-4">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter something here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <br/>
                    <Upload data= {
                        {
                            file : formData,
                            type : "voucher",
                            file_id : fileUID
                        }
                    } {...uplod}>
                        <Button icon={<UploadOutlined />}><span style={{color: 'red'}}>*&nbsp;</span> Click to Upload</Button>
                    </Upload>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                </div>
            </div>
        </>
    );

    const ApproveOrReject = (
        <>
            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                {
                    bill.status === 0 ?
                        isApprover === true
                            ? <>
                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="button">Reject</Button>
                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="button">Approve</Button>
                            </>
                            : ""
                        : ""
                }
                {
                    bill.status === 1 ?
                        hasPayment === false ?
                            <><Button id="submitbutton" type="primary" onClick={showModalReject} htmlType="button"><Link to={"newpayment?bill_id="+bill.id}>Payment</Link></Button></>
                            : ""
                        : ""
                }
                <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            </div>
        </>
    );

    const PaymentInformaiton = (
        <></>
    );

    let totalPaid = 0;

    return (
        <Card title={"Provisional Cash Clearance"}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {CisUI().showLoading}

                {
                    noBillField
                }



            </Form>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default ProvisionalCashClearence;