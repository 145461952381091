import React, {useState} from "react";
import {Form, Card, Input, Checkbox, Select, Col, Row, Button, notification, Popconfirm} from 'antd';
import jsonfile from './allowance_input.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import {CisUI} from "../../../../util/CISUI";
import InputFieldV2 from "../../../../util/InputFieldV2";
import SelectData from "../../../../util/selectdata";
import Config from "../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input;

const AllowanceInput = (props) => {
	
	// console.log(props);
	
	const history = useHistory();
	const [ form ] = Form.useForm();
	const [loading, setLoading] = useState([]);
	
	const userID = props.empid;
	const editID = props.editEmpId
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(editID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add+"/"+userID;
	let redirectto = "../"+jsonfile.urls.list;
	
	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+editID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+editID;
		redirectto = "../../"+jsonfile.urls.list;
	}
	
	const [state, setState] = useState({
		rows: [
			{
				allowance_name: "",
				start_period: "",
				end_period: "",
				amount: 0,
				basic_percentage: 0,
				date: "",
				cancelled: ""
			}
		]
	});
	
	const handleAdd = () => {
		// console.log("hi");
		const item = {
			allowance_name: "",
			start_period: "",
			end_period: "",
			amount: 0,
			basic_percentage: 0,
			date: "",
			cancelled: ""
		};
		setState({
			rows: [...state.rows, item]
		});
	};
	
	const handleAddChange = (id) => e => {
		
		const { name, value } = e.target;
		const rows = [...state.rows];
		// console.log("selectedITems:"+ JSON.stringify(rows[id]));
		
		if(name === "allowance_name") {
			rows[id] = {
				allowance_name: value,
				start_period: rows[id].start_period,
				end_period: rows[id].end_period,
				amount: rows[id].amount,
				basic_percentage: rows[id].basic_percentage,
				date: rows[id].date,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "start_period") {
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: value,
				end_period: rows[id].end_period,
				amount: rows[id].amount,
				basic_percentage: rows[id].basic_percentage,
				date: rows[id].date,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "end_period"){
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: rows[id].start_period,
				end_period: value,
				amount: rows[id].amount,
				basic_percentage: rows[id].basic_percentage,
				date: rows[id].date,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "amount"){
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: rows[id].start_period,
				end_period: rows[id].end_period,
				amount: value,
				basic_percentage: rows[id].basic_percentage,
				date: rows[id].date,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "basic_percentage"){
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: rows[id].start_period,
				end_period: rows[id].end_period,
				amount: rows[id].amount,
				basic_percentage: value,
				date: rows[id].date,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "date"){
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: rows[id].start_period,
				end_period: rows[id].end_period,
				amount: rows[id].amount,
				basic_percentage: rows[id].basic_percentage,
				date: value,
				cancelled: rows[id].cancelled
			};
		}
		else if(name === "cancelled"){
			rows[id] = {
				allowance_name: rows[id].allowance_name,
				start_period: rows[id].start_period,
				end_period: rows[id].end_period,
				amount: rows[id].amount,
				basic_percentage: rows[id].basic_percentage,
				date: rows[id].date,
				cancelled: value
			};
		}
		
		setState({
			rows
		});
		
	}
	
	const handleRemoveRow = (idx) => e => {
		console.log(idx);
		
		const filteredArray = [...state.rows];
		// console.log(filteredArray.length)
		if(filteredArray.length == 1) {
			notification.warning({
				message: 'Alert',
				type : "warning",
				description: "You can not delete this item."
			});
		} else {
			setState({
				rows: filteredArray.filter((item, index) => index !== idx)
			});
		}
		
	};
	
	
	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		const data = {
			...values,
			'dob': values['dob'].format("YYYY-MM-DD")
		}
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}
	
	return (
		<Card title={Title}>
			{CisUI().showLoading}
			
			<div className="row">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-6">
							{/*<h2>Purchase Items</h2>*/}
						</div>
						<div className="col-md-6" style={{textAlign : "right"}}>
							<a className="btn btn-primary" onClick={handleAdd}>Add More</a>
						</div>
					</div>
					
					<Form
						form={form}
						{...CisUI().formItemLayout}
						name="input"
						className="ant-advanced-search-form"
						onFinish={onFinish}
						initialValues={{ remember: true }}
					>
						<table id="educationinfo" className="ptable table table-bordered">
							<thead>
							<tr>
								<th></th>
								<th>Allowance Name</th>
								<th>Start Period</th>
								<th>End Period</th>
								<th>Amount</th>
								<th>% of Basic</th>
								<th>Date</th>
								<th>Cancelled</th>
							</tr>
							</thead>
							<tbody>
							{state.rows.map((item, idx) => (
								<tr id="addr0" key={idx}>
									<td style={{width : '5%', textAlign:"center"}}>
										{/*<a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
										<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>
										    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
										</Popconfirm>
									</td>
									<td style={{width : '11.8%'}}>
										<input type="text" className="form-control" placeholder="Allowance Name" name="allowance_name" value={state.rows[idx].allowance_name} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="month" placeholder="Start Period" className="form-control" name="start_period" value={state.rows[idx].start_period} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="month" placeholder="End Period" className="form-control" name="end_period" value={state.rows[idx].end_period} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="text" placeholder="Amount" className="form-control" name="amount" value={state.rows[idx].amount} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="text" placeholder="% of Basic" className="form-control" name="basic_percentage" value={state.rows[idx].basic_percentage} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="date" placeholder="Date" className="form-control" name="date" value={state.rows[idx].date} onChange={handleAddChange(idx)} />
									</td>
									<td style={{width : '11.8%'}}>
										<input type="text" placeholder="Cancelled" className="form-control" name="cancelled" value={state.rows[idx].cancelled} onChange={handleAddChange(idx)} />
									</td>
								</tr>
							))}
							</tbody>
						</table>
						
						<Row gutter={24}>
							<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
								<Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
							</Col>
						</Row>
					
					</Form>
				
				</div>
			</div>
		
		</Card>
	);
}

export default AllowanceInput;