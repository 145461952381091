import React, {useState} from "react";
import {Form, Card, notification } from 'antd';
import jsonfile from './vendor_info.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";

const NewVendorInfo = (props) => {

    const [loading, setLoading] = useState([]);
    const [check, setCheck] = useState(false);


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    function onchange(e) {

        console.log(JSON.stringify(e));
        if(e.hasOwnProperty("target")) {
            if(e.target.id === "input_shipping_address" ) {
                let checkD = false;
                if(e.target.checked === true) {
                    setCheck(true);
                    checkD = true;
                }
                else {
                    setCheck(false);
                    checkD = false;
                }

                let data = form.getFieldsValue([
                    'billing_address_line_1',
                    'billing_address_line_2',
                    'billing_city',
                    'billing_country',
                    'billing_state',
                    'billing_zipcode'
                ]);

                if(checkD)
                {
                    form.setFieldsValue({
                        'shipping_address_line_1' : data.billing_address_line_1,
                        'shipping_address_line_2' : data.billing_address_line_2,
                        'shipping_city' : data.billing_city,
                        'shipping_country' : data.billing_country,
                        'shipping_state' : data.billing_state,
                        'shipping_zipcode' : data.billing_zipcode,
                    });
                } else {
                    form.setFieldsValue({
                        'shipping_address_line_1' : null,
                        'shipping_address_line_2' : null,
                        'shipping_city' : null,
                        'shipping_country' : null,
                        'shipping_state' : null,
                        'shipping_zipcode' : null,
                    });
                }

            }
        }

    }

    const onFinish = (values) => {
        // setLoading({loading:true})
        // console.log(values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFields(jsonfile.input,userData,isEdit,props, onchange, redirectto)}
            </Form>
        </Card>
    );
};

export default NewVendorInfo;