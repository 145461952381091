import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import styled from 'styled-components'
import { useTable, useExpanded } from 'react-table'
import jsPDF from 'jspdf';
import moment from 'moment';

const Panel = Collapse.Panel;

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: right;

      :last-child {
        border-right: 0;
      }
    }
  }
  
  .table th {
    white-space: normal;
  }
  
  .table th:nth-child(1) {
    font-weight : bold;
    font-size: 18px !important;
  }
  
  .table td:nth-child(1) {
    font-weight : bold;
    font-size: 18px !important;
  }
  
  table td:last-child {
    font-weight : bold;
  }
  
  table tr:last-child td {
    font-weight : bold;
  }

  
  .table td:nth-child(2) {
    text-align: left;
  }
  
  .table th:nth-child(3) {
    text-align: left;
  }
  
  .table th:nth-child(2) {
    text-align: left;
  }
  
  .table td:nth-child(1) {
    text-align: left;
  }
  
  .table th:nth-child(1) {
    text-align: left;
  }
  
`

function Table({ columns: userColumns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded // Use the useExpanded plugin hook
    )

    return (
        <>
            <table className="table table-striped table-bordered" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                if(cell.value > 0) {
                                    return <td {...cell.getCellProps()}>{CisUI().getCurrencyFormated1(cell.value)}</td>
                                }
                                else {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                }
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {/*<br />*/}
            {/*<div>Showing the first 20 results of {rows.length} rows</div>*/}
      {/*      <pre>*/}
      {/*  /!*<code>{JSON.stringify({ expanded: expanded }, null, 2)}</code>*!/*/}
      {/*</pre>*/}
        </>
    )
}

const RegionContribution = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.contribution,
        filtervalue : '',
        from : '',
        to : '',
        head : [],
        headtitle : [],
        contribution : [],
        reportTitle: ''
    });

    useEffect(() => {
        getContirbutionData();
    }, []);


    const getContirbutionData = (newURL = "", reportTitle="") => {
        document.getElementById("loader").style.display = "block";
        let url = state.reqURL;
        if(newURL !== "") {
            url = newURL;
        }
        axios.get(url)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    console.log(res.data);
                    const data = res.data;
                    //console.log("Response : "+ JSON.stringify(data.rgdata));
                    setState({
                        reqURL: url,
                        headtitle: data.headtitle,
                        contribution: data.contribution,
                        head: data.head,
                        reportTitle: reportTitle
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();



    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    return `${key}=${CisUI().ParseDateFormat(values[key])}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.contribution+"/?"+qsup;
        const WKR = CisUI().ParseDateFormat(values.date);
        const reportTitle = "Between "+CisUI().DateFormat(WKR[0])+" and "+ CisUI().DateFormat(WKR[1]);
        // setState({reqURL: newURL});
        getContirbutionData(newURL, reportTitle);
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch([],"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    // const getExtra = (
    //     <>
    //         <Search
    //             placeholder='Filter...'
    //             name='filtervalue'
    //             onSearch={handleOnFilter}
    //             onChange={handleOnFilter}
    //             style={{width: 200}}
    //         />
    //         {CisUI().listAction("../"+jsonfile.urls.add)}
    //     </>
    // );


    const exportToPDF = () => {

        var doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: "landscape",
            unit: "px"
        });

        // var doc = new jsPDF('l', 'px', 'letter');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const marginX = (pageWidth - 80) / 2;
        const xOffset = (doc.internal.pageSize.width / 2);
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user)

        doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
        doc.text("Region Summary Report",xOffset,37, { align: 'center' });
        doc.setFontSize(12);
        doc.text(state.reportTitle,xOffset,43, { align: 'center' });
        doc.setFontSize(8);
        doc.text("Printed By : "+uj["name"],20,50, { align: 'left' });
        doc.setFontSize(8);
        doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,55, { align: 'left' });

        var content = document.getElementById("region_summary").innerHTML;

        doc.html(content,
            {
                margin: [40, 60, 40, 60],
                callback: function (doc) {
                    doc.save("region_summary");
                },
                x: 20,
                y: 50
            })

    }



    //for ReactTable
    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? '-' : '+'}
          </span>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
              {row.isExpanded ? '-' : '+'}
            </span>
                    ) : null,
            },
            {
                Header: 'Title',
                columns: [
                    {
                        Header: 'Region/District/Local',
                        accessor: 'name',
                    }
                ],
            },
            {
                Header: 'Contribution By Funding Source',
                //columns: state.headtitle
                columns: [{"Header":"40000-Tithes & Offering & Offering","accessor":"s40000"},{"Header":"40200-Missions Offering ","accessor":"s40200"},{"Header":"40400-Internal Missions Week ","accessor":"s40400"},{"Header":"40600-McKeown Offering","accessor":"s40600"},{"Header":"40800-Easter Convention Offering","accessor":"s40800"},{"Header":"41000-Holy Ghost Convention Offering","accessor":"s41000"},{"Header":"41100-National Prayer Conf Offering","accessor":"s41100"},{"Header":"41400-Investment Income","accessor":"s41400"},{"Header":"41600-Other Income","accessor":"s41600"},{"Header":"41640-Special Offering","accessor":"s41640"},{"Header":"41650-Outreach Support","accessor":"s41650"},{"Header":"41900-Donations Received","accessor":"s41900"},{"Header":"42000-Missions Outreach Offering","accessor":"s42000"},{"Header":"42170-Women's Ministry ","accessor":"s42170"},{"Header":"42270-PEMEM","accessor":"s42270"},{"Header":"42370-Evangelism Ministry","accessor":"s42370"},{"Header":"42470-Youth Ministry","accessor":"s42470"},{"Header":"42480-PENSA Ministry","accessor":"s42480"},{"Header":"42570-Children's Ministry","accessor":"s42570"},{"Header":"46430-Miscellaneous Revenue","accessor":"s46430"},{"Header":"Total","accessor":"total"}]
            },
        ],
        []
    )

    // console.log(JSON.parse(JSON.stringify(columns[2]['columns'])));

    let tableHeader = JSON.stringify((columns[2]['columns']));
    // console.log(window.btoa(tableHeader))

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"regioncontributionprint.html?url="+window.btoa(state.reqURL)+"&filename=region_contribution_report&data=regionmonthlyData&subtitle="+state.reportTitle+"&rptype=regioncontributionreport&tableHeader="+window.btoa(tableHeader),"_blank");
    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <Link to={"../"+jsonfile.urls.add} className="btn btn-text-adjust btn-circle-md" title="Add New">
                    <i className="fas fa-plus"/>
                </Link>
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                <button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i
                    className="fas fa-file-pdf"/></button>
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );

    //const data = React.useMemo(() => makeData(5, 5, 5), [])

    //console.log("datas :"+ JSON.stringify(data));

    return (
        <>
            {searchView}
            <Card title={jsonfile.contributiontitle} extra={getExtra}>

                {CisUI().showLoading}

                <Styles>
                    <div style={{overflow: 'auto'}}>
                        <Table columns={columns} data={state.contribution} />
                    </div>
                </Styles>
            </Card>
        </>
    );
};

export default RegionContribution;