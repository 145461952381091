import {Form, Row, Col, Input, Button, Card, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './journal.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";

const {Option} = Select;

const NewJournal = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [vendor, setVendor] = useState([]);

    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);

    const [payable, setPayable] = useState([]);
    const [project, setProject] = useState([]);
    const [allbankaccounts, setAllBankAccounts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(0);
    const [doc, setDoc] = useState({});
    const [segment, setSegment] = useState(1);
    const [selectedSegment, setSelectedSegment] = useState([]);


    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        area: [],
        district: [],
        local: [],
        committee: [],
        subsector: []

    });
    const [ttl, setTTL] = useState(
        {
            totalDebit: 0,
            totalCredit: 0
        }
    );

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + "accounts/journlalist";

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + "accounts/journlalist";
    }


    const Companies = SelectData("company/companylists");
    const Area = SelectData("allarea");
    const District = SelectData("alldistrict");
    const Local = SelectData("alllocal");

    const BankName = SelectData("banknames");
    // const Companies = SelectData("company/companylists");
    const PaymentMethod = SelectData("paymentmethod");


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading: true})
        values["items"] = state.rows;
        console.log(JSON.stringify(values));
        let act = 0;
        state.rows.map((items,ind)=>{
            if(items.account_id >0) {
              act += 1;
            }
        })

        if(act === state.rows.length) {

            if (ttl.totalDebit === ttl.totalCredit && state.rows.length > 1) {
                if (ttl.totalDebit > 0 && ttl.totalCredit > 0) {
                    document.getElementById("loader").style.display = "block";
                    axios.post(Config.apiserver + endpoint, values)
                        .then(res => {
                            // console.log(res)
                            if (res.data.status === 1) {
                                notification.success({
                                    message: 'Success',
                                    type: "success",
                                    description: res.data.msg
                                });
                                history.push(redirectto);
                            } else {
                                notification.warning({
                                    message: 'Alert',
                                    type: "warning",
                                    description: res.data.msg
                                });
                            }
                            setLoading({loading: false});
                            document.getElementById("loader").style.display = "none";
                        })
                        .catch(errors => {
                            console.log(errors);
                            // document.getElementById("loader").style.display = "none";
                        });
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Minimum item should be 2, total debit side and total credit side should be same"
                    });
                }
            } else {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Minimum item should be 2, total debit side and total credit side should be same"
                });
                document.getElementById("loader").style.display = "none";
            }
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "All account field should be selected"
            });
            document.getElementById("loader").style.display = "none";
        }

    };


    const onCompanyChange = value => {
        console.log("selected : " + value);
        let fl = Companies.filter(item => item.id === value);
        if (fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        // setVendor(res.data.vendor);
                        // setAccount(res.data.account);
                        // setBankAccounts(res.data.bank);
                        // setEmployee(res.data.employee);
                        // setCustomer(res.data.customer);
                        // setCashAccount(res.data.cashaccount);
                        // setMainAccount(res.data.main_account);
                        // setChildAccount(res.data.child_account);
                        // setBeneficiaryData({
                        //     department: res.data.department,
                        //     users: res.data.users,
                        //     ministry: res.data.ministry,
                        //     pentsos: res.data.pentsos,
                        //     area: res.data.area,
                        //     district: res.data.district,
                        //     local: res.data.local
                        // });

                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setAllBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setIsLoaded(1);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            subsector: res.data.subsector,
                            committee: res.data.committee,
                            // area: res.data.area,
                            // district: res.data.district,
                            // local: res.data.local
                        })

                        const JournalID = (parseInt(res.data.journalID || 0) + 1);
                        form.setFieldValue("thread","J"+JournalID);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const comID = CisUI().getUserInfo('com_id');

    useEffect(() => {
        if (comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : " + value);
        if (value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setCashAccount(res.data.cashaccount);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            // area: res.data.area,
                            // district: res.data.district,
                            // local: res.data.local
                        });

                        const JournalID = (parseInt(res.data.journalID || 0) + 1);
                        form.setFieldValue("thread","J"+JournalID);

                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const appendChild = () => {
        let {data} = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data: data,
            rows: {
                code: '',
                name: '',
                qty: 0,
                price: 0,
                amount: 0
            }
        });

    };


    const removeRow = () => {
        let {data} = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...state.rows];
        rows[idx] = {
            account_id: e,
            description: rows[idx].description,
            bank_account_id: rows[idx].bank_account_id,
            fund_program: rows[idx].fund_program,
            func_expense_id : rows[idx].func_expense_id,
            restriction_type : rows[idx].restriction_type,
            debit: rows[idx].debit,
            credit: rows[idx].credit
        };

        setState({
            rows
        });

    }

    const ItemCellChange = (idx,cname="") => e => {
        let name = "";
        let value = null;
        if(cname === "") {
            name = e.target.name;
            value = e.target.value;
        }
        else {
            name = cname;
            value = e;
        }

        const rows = [...state.rows];
        // console.log("selectedValued:" + e);
        console.log("selectedValue:" + value);
        if (name === "account_id") {
            rows[idx] = {
                account_id: value,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: rows[idx].credit
            };
        } else if (name === "description") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: value,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: rows[idx].credit
            };
        } else if (name === "fund_program") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id : rows[idx].bank_account_id,
                fund_program: value,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: rows[idx].credit
            };
        } else if (name === "func_expense_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                restriction_type: rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                func_expense_id : value,
                debit: rows[idx].debit,
                credit: rows[idx].credit
            };
        } else if (name === "debit") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: value,
                credit: rows[idx].credit
            };
        }
        else if (name === "credit") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: value
            };
        }
        else if (name === "bank_account_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }
        else if (name === "restriction_type") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                restriction_type : value,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }
        else if (name === "beneficiary_source") {
            onBenificaryChange(value);
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                beneficiary_source : value,
                beneficiary_id : rows[idx].beneficiary_id,
                restriction_type :rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }
        else if (name === "beneficiary_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : value,
                restriction_type :rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
            };
        }

        setState({
            rows
        });

        //console.log(rows);

        let tDebit = 0;
        let tCredit = 0;
        rows.map((item, idx) => {
            tDebit += parseFloat(item.debit);
            tCredit += parseFloat(item.credit);
        });

        setTTL({
            totalDebit : tDebit,
            totalCredit : tCredit
        });

    };

    const handleAddRow = () => {
        const item = {
            account_id: 0,
            description: '',
            bank_account_id: 0,
            fund_program: 0,
            func_expense_id : 0,
            beneficiary_source : 0,
            beneficiary_id : 0,
            restriction_type : null,
            debit: 0,
            credit: 0
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});

        let tDebit = 0;
        let tCredit = 0;
        filteredArray.map((item, idx) => {
            tDebit += parseFloat(item.debit);
            tCredit += parseFloat(item.credit);
        });

        setTTL({
            totalDebit : tDebit,
            totalCredit : tCredit
        });

    };


    const Benificiary = SelectData("accountbenificary");
    const [benificiariesData, setBenificiaryData] = useState([]);
    let BenificaryData = [];

    // const onBenificaryChange = (e) => {
    //     //setBenificiaryselected(e.target.value);
    //     console.log("Selected : ", e);
    //     if (e === 2) {
    //         BenificaryData = customer;
    //         setBenificiaryData(customer);
    //     } else if (e === 3) {
    //         BenificaryData = vendor;
    //         setBenificiaryData(vendor);
    //     } else if (e === 4) {
    //         BenificaryData = employee;
    //         setBenificiaryData(employee);
    //     } else if (e === 5) {
    //         BenificaryData = accounts;
    //         setBenificiaryData(accounts);
    //     } else if (e === 6) {
    //         BenificaryData = beneficiaryData.department;
    //         setBenificiaryData(beneficiaryData.department);
    //     } else if (e === 7) {
    //         BenificaryData = beneficiaryData.area;
    //         setBenificiaryData(beneficiaryData.area);
    //     } else if (e === 8) {
    //         BenificaryData = beneficiaryData.users;
    //         setBenificiaryData(beneficiaryData.users);
    //     } else if (e === 9) {
    //         BenificaryData = beneficiaryData.users;
    //         setBenificiaryData(beneficiaryData.users);
    //     } else if (e === 10) {
    //         // console.log(e);
    //         // console.log(beneficiaryData.ministry);
    //         BenificaryData = beneficiaryData.ministry;
    //         setBenificiaryData(beneficiaryData.ministry);
    //     } else if (e === 11) {
    //         BenificaryData = beneficiaryData.pentsos;
    //         setBenificiaryData(beneficiaryData.pentsos);
    //     } else if (e === 12) {
    //         BenificaryData = beneficiaryData.district;
    //         setBenificiaryData(beneficiaryData.district);
    //     } else if (e === 13) {
    //         BenificaryData = beneficiaryData.local;
    //         setBenificiaryData(beneficiaryData.local);
    //     }
    //     else if (e === 14) {
    //         BenificaryData = [];
    //         setBenificiaryData([]);
    //     }
    //     else if (e === 15) {
    //         BenificaryData = beneficiaryData.subsector;
    //         setBenificiaryData(beneficiaryData.subsector);
    //     }
    //
    //     form.setFieldsValue({
    //         benificiary_id: null
    //     });
    //
    // }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        } else if (e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        } else if (e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        } else if (e === 6) {
            BenificaryData = beneficiaryData.department;
            setBenificiaryData(beneficiaryData.department);
        } else if (e === 7) {
            BenificaryData = Area;
            setBenificiaryData(Area);
        } else if (e === 8) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 9) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            BenificaryData = beneficiaryData.ministry;
            setBenificiaryData(beneficiaryData.ministry);
        } else if (e === 11) {
            BenificaryData = beneficiaryData.pentsos;
            setBenificiaryData(beneficiaryData.pentsos);
        } else if (e === 12) {
            BenificaryData = District;
            setBenificiaryData(District);
        } else if (e === 13) {
            BenificaryData = Local;
            setBenificiaryData(Local);
        }
        else if (e === 14) {
            BenificaryData = [];
            setBenificiaryData([]);
        }
        else if (e === 15) {
            BenificaryData = beneficiaryData.subsector;
            setBenificiaryData(beneficiaryData.subsector);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }


    const formField = (

        <>
            <div className="row">
                {
                    comID > 0 ? <></> :

                        <div className="col-md-4">
                            <Form.Item
                                name="com_id"
                                label="Company"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-4">

                    <Form.Item
                        name="thread"
                        label="Entry No"
                        // initialValue={"J"+Math.floor(Date.now() / 1000)}
                        rules={[
                            {
                                required: true,
                                message: "Entry number is required"
                            },
                        ]}
                    >
                        <Input readOnly placeholder="Enter a number"/>
                    </Form.Item>

                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: "date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} placeholder="Date"/>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add Lines</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Account</th>
                            <th>Item Description</th>
                            <th>Beneficiary</th>
                            <th style={{display: 'none'}}>Fund Program</th>
                            <th style={{display: 'none'}}>Functional Expense</th>
                            <th style={{display: 'none'}}>Restriction Type</th>
                            <th>Debit ($)</th>
                            <th>Credit($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width: '30px'}}><a onClick={handleRemoveRow(idx)}
                                                               className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                        <Select
                                            showSearch
                                            clearIcon={true}
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            style={{width : '100%'}}
                                            onChange={ItemCellChangeAccount(idx)}
                                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                        >
                                            {
                                                accounts.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    {/*<select name="account_id" onChange={ItemCellChange(idx)} className="form-control select2" >*/}
                                    {/*    <option value="0">--Select--</option>*/}
                                    {/*    {*/}
                                    {/*        accounts.map((items, index) =>*/}
                                    {/*            <option value={items.id}>{items.name}</option>*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                </td>
                                <td style={{width: '300px'}}><input className="form-control text-right"
                                                                    style={{textAlign: 'left'}}
                                                                    placeholder="Description" name="description"
                                                                    value={item.description}
                                                                    onChange={ItemCellChange(idx)}/></td>

                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary source"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChange(idx,"beneficiary_source")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            Benificiary.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br /> <br />
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChange(idx,"beneficiary_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            benificiariesData.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>

                                <td style={{width: '100px', display : 'none'}}>
                                    <select name="fund_program" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            fund.map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '100px' ,display : 'none'}}>
                                    <select name="func_expense_id" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            expense.map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '100px', display : 'none'}}>
                                    <select name="restriction_type" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            StaticSelectData("restrictiontype").map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '80px'}}>
                                    <input className="form-control text-right"
                                                                   style={{textAlign: 'right'}} name="debit"
                                                                   value={item.debit}
                                                                   onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '80px'}}>
                                    <input className="form-control text-right"
                                                                   style={{textAlign: 'right'}} name="credit"
                                                                   value={item.credit}
                                                                   onChange={ItemCellChange(idx)}/></td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={4}>Total = </th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalDebit)}</th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalCredit)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )


    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../accounts/journlalist')
                    : CisUI().addAction('../accounts/journlalist')
            }
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>*/}
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewJournal;