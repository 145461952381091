import {
  FORGOT_PASSWORD,
  INIT_URL, RESET_PASSWORD,
  SIGNOUT_USER_SUCCESS,
  UPDATE_LOAD_USER,
  USER_DATA,
  USER_PROFILE_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";

import  localStorage  from  "react-secure-storage";

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '',
  authUser: null,
  loadingAuthUser: true,
  profileData: {},
  forgotPass: false,
  resetPass: false
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
        loadingAuthUser: false
      };
    }

    case USER_PROFILE_DATA: {
      return {
        ...state,
        profileData: action.payload,
        // loadingAuthUser: false
      };
    }

    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadingAuthUser: action.payload
      };
    }

    case FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPass: action.payload
      }
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        resetPass: action.payload
      }
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
}

export default AuthReducer;
