import React, { useState, useEffect } from "react";

import { Input, Card, Button, Table, Menu, Dropdown, Modal, Typography, Row, Col, notification } from "antd";

import { Link, useHistory } from "react-router-dom";

import { EllipsisOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { CisUI } from "../../../../util/CISUI";
import axios from "axios";
import config from "../../../../util/config";
import IntlMessages from "../../../../util/IntlMessages";
import jsonfile from "./category.json";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";
const { Search } = Input;
const { Title } = Typography;
// const onSearch = (value) => console.log(value);

const columns = [
  {
    title: "SL",
    dataIndex: "sl",
    key: "sl",
  },
  {
    title: <IntlMessages id="Category Name" />,
    dataIndex: "name",
    key: "name",
  },
  {
    title: <IntlMessages id="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="Actions" />,
    dataIndex: "action",
    key: "action",
  },
];

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [tableData, setTableData] = useState([]);

  const history = useHistory();
  const [state, setState] = useState({
    reqURL: Config.apiserver + jsonfile.urls.list,
    filtervalue: '',
    subtitle: '',
  });

  const getCategory = () => {
    axios
      .get(config.apiserver + jsonfile.urls.list, CisUI().HeaderRequest)
      .then((res) => setCategories(res.data.data))
      .catch((errors) => console.log(errors));
  };

  useEffect(() => {
    getCategory();
  }, []);


  const getExtra = (
    <>
      {CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", "customer_type", jsonfile, state.subtitle, "portrait")}
    </>
  );

  const onSearch = ((e) => {
    console.log(e.target.value)

    const salesproduct = [];

    categories.map((category, i) => {
      if (category.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        salesproduct.push({
          key: category.id,
          sl: ++i,
          name: category.name,
          status: CisUI().getStatusLabel(category.status),
          action: (
              ActionButton(category.id,jsonfile.urls,jsonfile.urls.list)
          ),
        });
      }
    })
    setTableData(salesproduct)
  });


  const getTableData = () => {
    const data = [];

    categories.map((category, i) => {
      data.push({
        key: category.id,
        sl: ++i,
        name: category.name,
        status: CisUI().getStatusLabel(category.status),
        action: (
            ActionButton(category.id,jsonfile.urls,jsonfile.urls.list)
        ),
      });
    });
    setTableData(data)
  }

  useEffect(() => {
    getTableData();
  }, [categories]);

  return (
    <>

      <Card title={jsonfile.title} extra={getExtra}>

        {/*<Row className="list-header">*/}
        {/*  <Col xs={{ span: 24 }} xl={{ span: 18 }}>*/}
        {/*    <Title className="product-title"*/}
        {/*      level={3}></Title>*/}
        {/*  </Col>*/}

        {/*  <Col xs={{ span: 24 }} xl={{ span: 6 }}>*/}
        {/*    <Input className="list-header-search" placeholder="Search Category" prefix={<SearchOutlined />} onChange={onSearch} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Table scroll={{ x: true }} columns={columns} dataSource={tableData} className="list-table" bordered pagination={{ position: ["bottomCenter"] }} />
      </Card>
    </>
  );
};

export default CategoryList;
