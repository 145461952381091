import React from "react";
import {Route, Switch} from "react-router-dom";

import AssetTypeList from "../../containers/yotta/asset_management/asset_type/AssetTypeList";
import NewAssetType from "../../containers/yotta/asset_management/asset_type/NewAssetType";
import AssetInfoList from "../../containers/yotta/asset_management/asset_info/AssetInfoList";
import NewAssetInfo from "../../containers/yotta/asset_management/asset_info/NewAssetInfo";
import AssetDistList from "../../containers/yotta/asset_management/asset_distribution/AssetDistributionList";
import NewAssetDist from "../../containers/yotta/asset_management/asset_distribution/NewAssetDistribution";
import EditAssetDist from "../../containers/yotta/asset_management/asset_distribution/EditAssetDistribution";
import AssetMaintenanceList from "../../containers/yotta/asset_management/asset_maintenance/AssetMaintenanceList";
import NewAssetMaintenance from "../../containers/yotta/asset_management/asset_maintenance/NewAssetMaintenance";
import AssetDestroyList from "../../containers/yotta/asset_management/asset_destroy/AssetDestroyList";
import NewAssetDestroy from "../../containers/yotta/asset_management/asset_destroy/NewAssetDestroy";

const Company = ({match}) => (
    <Switch>
        <Route path={`${match.url}/assettype`} component={AssetTypeList}/>
        <Route path={`${match.url}/newassettype`} component={NewAssetType}/>
        <Route path={`${match.url}/assettypeedit/:id`} component={NewAssetType}/>
        <Route path={`${match.url}/assetinfo`} component={AssetInfoList}/>
        <Route path={`${match.url}/newassetinfo`} component={NewAssetInfo}/>
        <Route path={`${match.url}/assetinfoedit/:id`} component={NewAssetInfo}/>
        <Route path={`${match.url}/assetdist`} component={AssetDistList}/>
        <Route path={`${match.url}/newassetdist`} component={NewAssetDist}/>
        <Route path={`${match.url}/assetdistedit/:id`} component={EditAssetDist}/>
        <Route path={`${match.url}/assetmaintain`} component={AssetMaintenanceList}/>
        <Route path={`${match.url}/newassetmaintain`} component={NewAssetMaintenance}/>
        <Route path={`${match.url}/assetmaintainedit/:id`} component={NewAssetMaintenance}/>
        <Route path={`${match.url}/assetdestroy`} component={AssetDestroyList}/>
        <Route path={`${match.url}/newassetdestroy`} component={NewAssetDestroy}/>
        <Route path={`${match.url}/assetdestroyedit/:id`} component={NewAssetDestroy}/>
    </Switch>
);

export default Company;
