import React, { useState, useEffect } from "react";
import {Input, Card, Button, Table, Menu, Dropdown, Modal, Typography, Row, Col} from "antd";
import { Link, useHistory } from "react-router-dom";
import {EllipsisOutlined, MoreOutlined, SearchOutlined} from "@ant-design/icons";
import { CisUI } from "../../../../util/CISUI";
import axios from "axios";
import config from "../../../../util/config";
import jsonfile from "./change_price.json";
import IntlMessages from "../../../../util/IntlMessages";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";
const { Search } = Input;
const { Title } = Typography;
const onSearch = (value) => console.log(value);

const columns = [
	{
		title: "SL",
		dataIndex: "sl",
		key: "sl",
	},
	{
		title: "Product Name",
		dataIndex: "product_name",
		key: "product_name",
	},
	{
		title: "Retail Price",
		dataIndex: "retail_price",
		key: "retail_price",
	},
	{
		title: "Dealer Price",
		dataIndex: "dealer_price",
		key: "dealer_price",
	},
	{
		title: "Distributor Price",
		dataIndex: "distributor_price",
		key: "distributor_price",
	},
	{
		title: "Wholesale Price",
		dataIndex: "wholesale_commission",
		key: "wholesale_commission",
	},
	{
		title: "Details",
		dataIndex: "details",
		key: "details",
	},
	{
		title: "Change date",
		dataIndex: "change_date",
		key: "change_date",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Action",
		dataIndex: "action",
		key: "action",
	},
];



const ChangeHistory = () => {
	const [products, setProducts] = useState([]);
	const [tableData, setTableData] = useState([]);
	const history = useHistory();
	const [state,setState] = useState({
		reqURL: Config.apiserver+jsonfile.urls.list,
		filtervalue : '',
		subtitle : '',
	});

	const onSearch = ((e) => {
		console.log(e.target.value)

		const salesproduct = [];

		products.map((product, i) => {
			if (product.product_name.toLowerCase().includes(e.target.value.toLowerCase())) {
				salesproduct.push({
					key: product.id,
					sl: ++i,
					product_name: product.product_name,
					retail_price: product.retail_price,
					dealer_price: product.dealer_price,
					distributor_price: product.distributor_price,
					wholesale_commission: product.wholesale_commission,
					details: product.details,
					change_date: product.created_at,
					status: CisUI().getStatusLabel(product.status),
					action: (
						ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
					),
				});
			}
		})
		setTableData(salesproduct)
	});

	const getproducts = () => {
		axios
			.get(config.apiserver + "stores/listChangePrice", CisUI().HeaderRequest)
			.then((res) => {
				console.log(res);
				setProducts(res.data.data)
			})
			.catch((errors) => console.log(errors));
	};


	useEffect(() => {
		getproducts();
	}, []);


	// const data = [];
	//
	// products.map((product, i) => {
	//
	// 	data.push({
	// 		key: product.id,
	// 		sl: ++i,
	// 		product_name: product.product_name,
	// 		retail_price: product.retail_price,
	// 		dealer_price: product.dealer_price,
	// 		distributor_price: product.distributor_price,
	// 		wholesale_commission: product.wholesale_commission,
	// 		details: product.details,
	// 		change_date: product.created_at
	// 	});
	// });
	const getExtra = (
		<>
			{CisUI().listAction("../"+jsonfile.urls.add,"", state.reqURL,"data","customer_type",jsonfile,state.subtitle,"portrait")}
		</>
	);

	const getTableData = () => {
		const data = [];

		products.map((product, i) => {
			data.push({
				key: product.id,
				sl: ++i,
				product_name: product.product_name,
				retail_price: product.retail_price,
				dealer_price: product.dealer_price,
				distributor_price: product.distributor_price,
				wholesale_commission: product.wholesale_commission,
				details: product.details,
				change_date: product.created_at,
				status: CisUI().getStatusLabel(product.status),
				action: (
					ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
				),
			});
		});
		setTableData(data)
	}

	useEffect(() => {
		getTableData();
	}, [products]);

	return (
		<>
			{/*<Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>*/}
			{/*	<Title className="product-title" level={3}>Price Change History</Title>*/}
			{/*	<Table columns={columns} dataSource={data} className="list-table"/>*/}
			{/*</Card>*/}


			<Card title={jsonfile.title} extra={getExtra}>

				{/*<Row className="list-header">*/}
				{/*	<Col xs={{ span: 24 }} xl={{ span: 6 }}>*/}
				{/*		<Input className="list-header-search" placeholder="Search Product" prefix={<SearchOutlined />} onChange={onSearch} />*/}
				{/*	</Col>*/}
				{/*</Row>*/}
				<Table scroll={{ x: true }} columns={columns} dataSource={tableData} className="list-table" bordered pagination={{ position: ["bottomCenter"] }} />
			</Card>

		</>
	);
};

export default ChangeHistory;


