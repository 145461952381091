import React from "react";
import {Route, Switch} from "react-router-dom";
import NewOfferingCollection from "../../containers/yotta/offering/collection/new";
import OfferingCollectionHistory from "../../containers/yotta/offering/collection/list";
import OfferingCollectionEdit from "../../containers/yotta/offering/collection/collectionedit";
import MinistryCollectionOffering from "../../containers/yotta/offering/ministry/new";
import MinistryCollectionHistory from "../../containers/yotta/offering/ministry/list";

const Offering = ({match}) => (
    <Switch>
        <Route path={`${match.url}/addnewoffering`} component={NewOfferingCollection}/>
        <Route path={`${match.url}/offeringhistory`} component={OfferingCollectionHistory}/>
        <Route path={`${match.url}/collectionedit/:id`} component={OfferingCollectionEdit}/>
        <Route path={`${match.url}/newmnstrycontribution`} component={MinistryCollectionOffering}/>
        <Route path={`${match.url}/mnstrycontibutions`} component={MinistryCollectionHistory}/>
    </Switch>
);

export default Offering;
