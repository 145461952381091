import React, {useState} from "react";
import {Form, Row, Col, Input, Button, Card, Select,notification} from 'antd';
import jsonfile from './payroll.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;
const NewPayroll = (props) => {

    const [loading, setLoading] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    let designation = SelectData('hrm/designation');
    let status = SelectData('userstatus');


    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState({
        rows1: [
            {
                add_level : '',
                add_amount : 0
            }
        ]
    });

    const [state1, setState1] = useState({
        rows2: [
            {
                deduction_level: '',
                deduction_amount: 0
            }
        ]
    });

    const [summary, setSummary] = useState({
            grand_total : '',
            total_addition : '',
            total_deduction : '',
        });

    const [total, setTotal] = useState(0);
    const [totalAddition, setTotalAddition] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(0);

    const handleRowAdd = () => {
        // console.log("hi");
        const item = {
            add_level : '',
            add_amount : 0
        };
        setState({
            rows1: [...state.rows1, item]
        });

        // console.log(state.rows1);
    };

    const handleRowDeduction = () => {
        const item = {
            deduction_level : '',
            deduction_amount : 0
        };
        setState1({
            rows2: [...state1.rows2, item]
        });
    };

    const handleRemoveState1Row = (idx) => e => {

        const filteredArray = [...state.rows1];
        setState({
            rows1: filteredArray.filter((item, index) => index !== idx)
        });

        const basic_amount = form.getFieldValue('basic');

        setTotal((totalAddition + parseFloat(basic_amount)) - totalDeduction)
    };

    const handleRemoveState2Row = (idx) => e => {

        const filteredArray = [...state1.rows2];
        setState1({
            rows2: filteredArray.filter((item, index) => index !== idx)
        });

        const basic_amount = form.getFieldValue('basic');

        setTotal((totalAddition + parseFloat(basic_amount)) - totalDeduction)
    };

    const handleBasicChange = (value) => {
        const basic_amount = form.getFieldValue('basic');

        const total_addition = totalAddition;
        const total_deduction = totalDeduction;

        setTotal((total_addition + parseFloat(basic_amount)) - total_deduction)

    }

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows1 = [...state.rows1];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "add_level") {
            rows1[id] = {
                add_level: value,
                add_amount: rows1[id].add_amount
            };
        }
        else if(name === "add_amount") {
            rows1[id] = {
                add_level: rows1[id].add_level,
                add_amount: value
            };
        }

        setState({
            rows1
        });

        let totals = 0
        rows1.map((item, idx) => {
            totals += parseFloat(item.add_amount);
        });

        // console.log(totals);

        var total_addition = totals;

        const basic_amount = form.getFieldValue('basic');

        setTotalAddition(total_addition)
        setTotal((total_addition + parseFloat(basic_amount)) - totalDeduction)

    }

    const handleDeductionChange = (id) => e => {
        console.log(e.target);
        const { name, value } = e.target;
        const rows2 = [...state1.rows2];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "deduction_level") {
            rows2[id] = {
                deduction_level: value,
                deduction_amount: rows2[id].deduction_amount
            };
        }
        else if(name === "deduction_amount") {
            rows2[id] = {
                deduction_level: rows2[id].deduction_level,
                deduction_amount: value
            };
        }

        setState1({
            rows2
        });

        let totals = 0
        rows2.map((item, idx) => {
            totals += parseFloat(item.deduction_amount);
        });

        console.log(totals);

        var total_deduction = totals;

        const basic_amount = form.getFieldValue('basic');
        // const total_addition = summary.total_addition;
        const total_addition = totalAddition;

        setTotalDeduction(total_deduction);
        setTotal((total_addition + parseFloat(basic_amount)) - total_deduction)
    }

    const onFinish = (values) => {
        setLoading({loading:true})
        // console.log("Input Data : "+values);
        values["item1"] = state.rows1;
        values["item2"] = state1.rows2;
        values["total_addition"] = totalAddition;
        values["total_deduction"] = totalDeduction;
        values["total"] = total;
        console.log(values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };


    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFieldV2(jsonfile.input,userData,isEdit,props)}*/}

                <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                    {/*<div className="col-md-4">*/}
                            <Form.Item
                                id="name"
                                label="Name"
                                name="name"
                                // initialValue={`${InV}`}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Name is required."
                                    },
                                ]}
                            >
                                <Input placeholder="Name"/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                id="designation_id"
                                label="Designation"
                                name="designation_id"
                                // initialValue={`${InV}`}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Designation is required."
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    placeholder="Select An Option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    // onChange={handleBasicChange}
                                >
                                    {
                                        designation.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>

                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                id="basic"
                                label="Basic"
                                name="basic"
                                // initialValue={`${InV}`}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Basic is required."
                                    },
                                ]}
                            >
                                <Input placeholder="Basic" onChange={handleBasicChange}/>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                id="status"
                                label="Status"
                                name="status"
                                // initialValue={`${InV}`}
                                rules={[
                                    {
                                        required: "required",
                                        message: "Status is required"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select An Option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    // onChange={handleChange}
                                >
                                    {
                                        status.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>

                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <div className="row">

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Addition</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <a className="btn btn-primary" onClick={handleRowAdd}>Add More</a>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Level</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows1.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}><a onClick={handleRemoveState1Row(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                    <td style={{width : '150px'}}><input type="text" className="form-control" placeholder="Level" name="add_level" value={state.rows1[idx].add_level} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control text-right" style={{textAlign : 'right'}} name="add_amount" value={state.rows1[idx].add_amount} onChange={handleAddChange(idx)} /></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={2}>Total Addition</td>
                                    {/*<td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(summary.total_addition)}</td>*/}
                                    <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(totalAddition)}</td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Deduction</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <a className="btn btn-primary" onClick={handleRowDeduction}>Add More</a>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Level</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                state1.rows2.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width : '30px'}}><a onClick={handleRemoveState2Row(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                        <td style={{width : '150px'}}><input type="text" className="form-control" placeholder="Level" name="deduction_level" value={state1.rows2[idx].deduction_level} onChange={handleDeductionChange(idx)} /></td>
                                        <td style={{width : '100px'}}><input type="number" className="form-control text-right" style={{textAlign : 'right'}} name="deduction_amount" value={state1.rows2[idx].deduction_amount} onChange={handleDeductionChange(idx)} /></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={2}>Total Deduction</td>
                                {/*<td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(summary.total_deduction)}</td>*/}
                                <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(totalDeduction)}</td>
                            </tr>
                            </tfoot>
                        </table>

                    </div>

                    <p>Total: {CisUI().getCurrencyFormated1(total)} </p>
                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewPayroll;