import React, {useEffect, useState} from "react";
import {Form, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";
import Config from "../util/config";
import axios from "util/Api";
import {useHistory} from "react-router-dom";
import config from "../util/config";
import {ResetPassword} from "../appRedux/actions";


const PasswordReset = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(({auth}) => auth.authUser);
    const resetPass = useSelector(({auth}) => auth.resetPass);

    const history = useHistory();

    const [user,setUser] = useState({
        otp : "",
        new_password: "",
        confirm_password: ""

    });
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (e) => {
        if(e.target.name === 'otp') {
            setUser({
                otp : e.target.value,
                new_password: user.new_password,
                confirm_password: user.confirm_password
            })
        }
        else if(e.target.name === 'new_password') {
            setUser({
                otp : user.otp,
                new_password: e.target.value,
                confirm_password: user.confirm_password
            })
        }
        else if(e.target.name === 'confirm_password') {
            setUser({
                otp : user.otp,
                new_password: user.new_password,
                confirm_password: e.target.value
            })
        }
    }

    const onFinish = values => {

        let endpoint = "passwordreset";
        let redirectto = "/signin";
        let email = localStorage.getItem("fuser");
        values["upd"] = user;
        let data = {...user, email: email}
        console.log(data)

        if(data["new_password"] === data["confirm_password"]) {
            dispatch(ResetPassword(data))
            // axios.post(Config.apiserver + endpoint, values)
            //     .then(res => {
            //         console.log(res)
            //         if(res.data.status === 1) {
            //             notification.success({
            //                 message: 'Success',
            //                 type : "success",
            //                 description: res.data.msg
            //             });
            //             localStorage.removeItem("fuser");
            //             history.push(redirectto);
            //         }
            //         else {
            //             notification.warning({
            //                 message: 'Alert',
            //                 type : "warning",
            //                 description: res.data.msg
            //             });
            //         }
            //         document.getElementById("loader").style.display = "none";
            //     })
            //     .catch(errors => {
            //         console.log(errors);
            //     });
        }
        else {
            notification.success({
                message: 'Alert',
                type : "warning",
                description: "Confirm password does not matched"
            });
        }

    };

    useEffect(() => {
        if (resetPass) {
            props.history.push('/');
        }
    }, [resetPass]);

    return (
        <div className="s_form_area">

            <div className="s_form_left">
                <img
                    alt="example"
                    src="/assets/images/penterpv1.png" className="s_login_logo"
                />
                <div className="s_left_text">

                </div>

                <div className="copy_right_area">{config.footerText}</div>
            </div>


            <div className="s_form_right">
                {/* <div className="elememt2_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg2.png'})`,  width: "346px",height: "330px",right: "52px",
        position: "absolute",top: "-160px", transform: "rotate(20deg)"}}></div> */}
                <div className="row" style={{width: "100%"}}>
                    <div className="col-md-6 offset-md-3">
                        <Form
                            initialValues={{remember: true}}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>

                            <div className="mb-3">
                                <label className="form-label" style={{color: 'white'}}>OTP</label>
                                <input type="number" required name="otp" onChange={onChange} className="form-control"
                                       id="exampleInputEmail1" aria-describedby="emailHelp"/>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" style={{color: 'white'}}>New Password</label>
                                <input type="password" required name="new_password" onChange={onChange} className="form-control"
                                       id="exampleInputEmail1" aria-describedby="emailHelp"/>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" style={{color: 'white'}}>Confirm Password</label>
                                <input type="password" required name="confirm_password" onChange={onChange} className="form-control"
                                       id="exampleInputEmail1" aria-describedby="emailHelp"/>
                            </div>

                            <div className="sub_link" style={{textAlign: 'right'}}>
                                <Link to="/">Have an account? Login</Link>
                            </div>

                            <button type="submit" className="btn btn_primary text-right s_btn"><IntlMessages
                                id="app.userAuth.resetPassword"/></button>
                            {/* <div className="elememt3_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg1.png'})`,  width: "250px",height: "208px",right: "0px", position: "absolute",bottom: "0px", transform: "rotate(-20deg)"}}></div> */}
                        </Form>

                    </div>
                </div>
                <div className="copy_left_area">
                    <div>{config.footerText}</div>
                    <div style={{color: "#939393"}}>{config.version}</div>
                </div>
            </div>
            <InfoView/>
        </div>
    );
};

export default PasswordReset;
