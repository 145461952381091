import React, {useState} from "react";
import {Form, Card, Tabs, Button} from 'antd';
import jsonfile from './employee.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import General from "./General";
import Communication from "./Communication";
import Administration from "./Administration";
import Personal from "./Personal";
import Benefits from "./Benefits";
import AllowanceInput from "./AllowanceInput";
import DeductionInput from "./DeductionInput";
import LanguageInfo from "./LanguageInfo";
import Documents from "./Documents";
import WorkingHistory from "./WorkingHistory";
import NetSalaryDetails from "./NetSalaryDetails";
import TaxAblePay from "./TaxAblePay";
import SSF from "./SSF";
import Deductions from "./Deductions";
import Allowances from "./Allowances";
import LoanInput from "./LoanInput";
import SavingSchemaInput from "./SavingSchemaInput";

const { TabPane } = Tabs;

const NewEmployee = (props) => {


    const [empId, setEmpId] = useState();

    const handleCallback = (childData) =>{
        // console.log(childData);
        setEmpId(childData)
    }

    // console.log("EMPID: ", empId);

    const userID = props.match.params.id;
    // console.log(userID)
    let userData = [];

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const [selectedTab, setSelectedTab] = useState(1);
    const tabCount = 11;


    const changeTab = () => {
        setSelectedTab((selectedTab + 1) % tabCount)
    }

    const tabChangeButton = () => {
        let button = "";
        if(selectedTab == 1) {
            button = '<Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>';
        } else if(selectedTab < 10) {
            button = '<Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back" style={{float:"right"}}>Back</Button>' +
                '<Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>'
        }

        return button;
    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {/*<Form*/}
            {/*    form={form}*/}
            {/*    {...CisUI().formItemLayout}*/}
            {/*    name="input"*/}
            {/*    className="ant-advanced-search-form"*/}
            {/*    onFinish={onFinish}*/}
            {/*    initialValues={{ remember: true }}*/}
            {/*>*/}

                {/*<Tabs activeKey={selectedTab.toString()} style={{ marginBottom: 32 }}>*/}
                <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                    <TabPane tab="General" key="1">
                        <General empid={handleCallback} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Communication" key="2">
                        <Communication empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Administration" key="3">
                        <Administration empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Personal" key="4">
                        <Personal empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Benefits" key="5">
                        <Benefits empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Allowance Input" key="6">
                        <AllowanceInput empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Deduction Input" key="7">
                        <DeductionInput empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Saving Schema Input" key="8">
                        <SavingSchemaInput empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Loan Input" key="9">
                        <LoanInput empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Allowances" key="10">
                        <Allowances empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Deductions" key="11">
                        <Deductions empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="SSF" key="12">
                        <SSF empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="TaxAble Pay" key="13">
                        <TaxAblePay empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Net Salary Details" key="14">
                        <NetSalaryDetails empid={empId} editEmpId={userID}/>
                    </TabPane>
                    {/*<TabPane tab="Languages" key="9">*/}
                    {/*    <LanguageInfo empid={empId} editEmpId={userID}/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Documents" key="10">*/}
                    {/*    <Documents empid={empId} editEmpId={userID}/>*/}
                    {/*</TabPane>*/}
                </Tabs>

                {/*{*/}
                {/*    selectedTab == 1 &&*/}
                {/*    <Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right"}}>Next</Button>*/}
                {/*}*/}

                {/*{*/}
                {/*    selectedTab > 1 && selectedTab < 10 &&*/}
                {/*    <>*/}
                {/*        <Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back" style={{float:"right"}}>Back</Button>*/}
                {/*        <Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>*/}
                {/*    </>*/}
                {/*}*/}
                {/*{*/}
                {/*    selectedTab == 10 &&*/}
                {/*    <>*/}
                {/*        <Row gutter={24}>*/}
                {/*            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>*/}
                {/*                <Button type="primary" htmlType="submit">*/}
                {/*                    Submit*/}
                {/*                </Button>*/}
                {/*                <Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back">Back</Button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </>*/}
                {/*}*/}

            {/*</Form>*/}

        </Card>
    );
}

export default NewEmployee;