import React, {useEffect, useState} from 'react'
import {
	DeleteOutlined,
	EditOutlined,
	MinusOutlined,
	PlusOutlined,
	SearchOutlined, UploadOutlined,
	UserAddOutlined,
	UserOutlined
} from '@ant-design/icons'
import {Card, Col, Input, Row, List, Modal, Form, Divider, Select, Upload} from 'antd'

import {Popover, Button} from 'antd';
import config from '../../../../util/config';
import axios from "axios";
import { CisUI } from '../../../../util/CISUI';

const NewOrderListView = () => {

	const [cartItems, setCartItems] = useState([]);

	const [topSalesProducts, setTopSalesProducts] = useState([]);
	const [tsProducts, setTtsProducts] = useState([]);

	const [customerInput, setCustomerInput] = useState("")
	const [customers, setCustomers] = useState([])
	const [customersL, setCustomersL] = useState([])

	const [subTotal, setSubTotal] = useState(0.00);
	const [itemsTax, setItemsTax] = useState(0.00);
	const [discountAllItems, setDiscountAllItems] = useState(0.00);
	const [discountSubtotal, setDiscountSubtotal] = useState(0.00);
	const [totalWithTax, setTotalWithTax] = useState(0.00);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const getproduct = () => {
			// axios.get("https://smesandbox.datalibrary.io/api/productManagement/listProduct", CisUI().HeaderRequest)
            // .then((res) => {
            //     console.log(res);
			// 	setTopSalesProducts(res.data.data)
			// 	setTtsProducts(res.data.data)
			//
            // })
            // .catch((errors) => console.log(errors));

		axios.get(`http://localhost:3000/data/productData.json`)
			.then((res) => {
				// setCustomers(res.data.data)
				setTopSalesProducts(res.data.data)
				setTtsProducts(res.data.data)
				console.log(res)
			})
			.catch((errors) => console.log(errors));
	};

	const getCustomers = () => {
		axios.get(`http://localhost:3000/data/customers.json`)
			.then((res) => {
				// setCustomers(res.data.data)
				setCustomersL(res.data.data)
				console.log(res)
			})
			.catch((errors) => console.log(errors));
	};

	const searchCustomer = ((e) => {
		// console.log(customers)
		// console.log(e)
		setCustomerInput(e.target.value);
		const customerlist = []

		if (e.keyCode === 8 && e.target.value !== "") {
			setCustomers(customersL)
			// console.log("Back")
		} else {
			// console.log(e.target.value)
			customersL.map((customer) => {
				if (customer.c_name.toLowerCase().includes(e.target.value.toLowerCase())) {
					customerlist.push({
						id: customer.id,
						c_name: customer.c_name
					})
				}
				// console.log(customer)
			})
			setCustomers(customerlist)
		}

		if (e.target.value === "") {
			setCustomers([])
		}
	})

	const itemAddToCart = (name, price, id) => {
		let newcart = [...cartItems];
		let qty = 1;
		const nc = newcart.filter(item => item.id === id);

		if (nc.length > 0) {
			newcart.map((item, index) => {
				if (item.id === id) {
					qty = item.quantity + 1;
					newcart[index] = {
						id: id,
						name: name,
						price: price,
						quantity: qty
					};
				}
			});
		} else {
			newcart.push({
				id: id,
				name: name,
				price: price,
				quantity: qty
			});
		}

		setCartItems(newcart);
		console.log(cartItems);
	}

	const discountAllItemsHandle = ((e) => {
		if (e){
			const totalDiscount = parseInt(e?.target?.value)
			const discountAmount = ((subTotal * totalDiscount) / 100);
			setDiscountAllItems(discountAmount)
		}
	})

	const discountSubtotalHandle = ((e) => {

		// console.log(discountSubtotal)
		if (e){
			setDiscountSubtotal(parseInt(e?.target?.value));
		}
	})

	const increaseQuantity = (id) => {
		let syscartd = [];
		cartItems.map((item, index) => {
			let qty = item.quantity;
			if (item.id === id) {
				qty = qty + 1;
			}
			syscartd.push({
				id: item.id,
				name: item.name,
				price: item.price,
				quantity: qty
			});
		});
		setCartItems(syscartd);
	}

	const decreaseQuantity = (id) => {

		let syscartd = [];
		cartItems.map((item, index) => {
			let qty = item.quantity;
			if (item.id === id) {
				qty = qty - 1;
			}

			syscartd.push({
				id: item.id,
				name: item.name,
				price: item.price,
				quantity: qty
			});

			if (qty <= 0) {
				syscartd.pop();
			}

			if (cartItems?.length === 1){
				setSubTotal(0.00)
				setItemsTax(0.00)
				setDiscountAllItems(0.00)
				setDiscountSubtotal(0.00)
				setTotalWithTax(0.00)
			}
			console.log("dec" +cartItems.length)
		});
		setCartItems(syscartd);
	}

	const deleteCartItem = (id) => {
		setCartItems(
			cartItems.filter((item) => item.id !== id)
		);
		if (cartItems?.length === 1){
			setSubTotal(0.00)
			setItemsTax(0.00)
			setDiscountAllItems(0.00)
			setDiscountSubtotal(0.00)
			setTotalWithTax(0.00)
		}
		console.log("del "+cartItems.length)
	}

	const onSearch = ((e) => {
		console.log(e.target.value)
		const salesproduct = [];

		if (e.keyCode === 8) {
			setTopSalesProducts(tsProducts)
		} else {
			topSalesProducts.map((item, index) => {
				if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
					salesproduct.push({
						id: item.id,
						name: item.name,
						price: item.price,
						image: item.image,
						status: item.status
					})
				}
			})
			setTopSalesProducts(salesproduct)
		}
		if (e.target.value === "") {
			setTopSalesProducts(tsProducts)
		}

	})

	const emptyCart = (() => {
		setCartItems([]);
		setSubTotal(0.00)
		setItemsTax(0.00)
		setDiscountAllItems(0.00)
		setDiscountSubtotal(0.00)
		setTotalWithTax(0.00)
	})

	const onFinish = (values) => {
		console.log('Success:', values);
	};

	const selectCustomer = ((customerID, customerName) => {
		setCustomerInput(customerName);
		setCustomers([])
	})

	const customerOnChange = ((e) => {
		setCustomerInput(e.target.value);
	})

	useEffect(() => {
		getproduct();
		getCustomers();
	}, []);

	useEffect(() => {
		let totalSum = 0;
		cartItems.map((item) => {
			const tp = parseInt(item.price) * parseInt(item.quantity)
			totalSum += tp;
		})
		setSubTotal(totalSum)
	}, [itemAddToCart, increaseQuantity, decreaseQuantity]);

	useEffect(() => {

		discountAllItemsHandle()
		discountSubtotalHandle()
		setTotalWithTax(subTotal - discountSubtotal - discountAllItems + itemsTax)
	}, [discountAllItemsHandle, discountSubtotalHandle, increaseQuantity, decreaseQuantity, itemAddToCart, emptyCart, deleteCartItem])
	return (
		<>
			<Card title="Retail Sales Entry">
				<Row>
					<Col span={16}>
						<div className="product-search-area">
							<Input className="search-input" placeholder="Search Product" prefix={<SearchOutlined/>} onKeyDown={onSearch}/>
						</div>
						<div>
							<Row className="sales-top-products">
								{topSalesProducts.map((product, index) => (
									<Col span={8} className="top-sales-products"
									     onClick={() => itemAddToCart(product.product_name, parseFloat(product.retail_price), product.id)}>
										<Card className="top-sales-card">
											<div className="ant-card-meta-title sales">{product.product_name} - SKU224</div>
											<div className="ant-card-meta-description">Price : ${product.retail_price}</div>
											{/*<Meta description="www.instagram.com" />*/}
										</Card>
									</Col>
								))}
							</Row>

						</div>
					</Col>
					<Col span={8}>
						<div className="customer-add-area">
							<Input placeholder="Search Customer"
							       prefix={<UserOutlined/>}
							       onKeyDown={searchCustomer}
							       // className="customer-search-input"
								   className="search-input"
							       value={customerInput}
							       onChange={customerOnChange}
								   suffix={<UserAddOutlined onClick={showModal}/>}
							/>
							{/*<UserAddOutlined className="add-customer-button" onClick={showModal}/>*/}
						</div>
						{customers?.length === 0 ? "" :
							<List className="customers-list" bordered dataSource={customers}
							      renderItem={item => <List.Item
								      onClick={() => selectCustomer(item.id, item.c_name)}>{item.c_name}</List.Item>}/>
						}

						{cartItems.length === 0 ? "" : <div className="cart-items">
							{cartItems.map((item, index) =>
								<List key={index} size="small">
									<List.Item actions={[<li>
										<Button size="small" className="mb-none cart-id-btn" shape="circle" value={1}
										        icon={<PlusOutlined/>} onClick={() => increaseQuantity(item.id)}/>

										<span style={{width: "25px", display:"inline-block"}}>{item.quantity}</span>
										<Button size="small" className="mb-none ml-15 cart-id-btn" shape="circle"
										        icon={<MinusOutlined/>} onClick={() => decreaseQuantity(item.id)}/>
										<span style={{width: "55px", display:"inline-block"}}>$ {item.price * item.quantity}</span>
										<DeleteOutlined className="cart-delete-btn"
										                onClick={() => deleteCartItem(item.id)}/></li>]}>
										{item.name}
									</List.Item>
								</List>
							)}
						</div>
						}


						<div className="total-amount">
							{/* 'Sub Total' */}
							<List size="small">
								<List.Item actions={[<li key="list-loadmore-edit">${subTotal}</li>]}>
									Sub Total
								</List.Item>
							</List>

							{/* 'Tax', */}
							<List size="small">
								<List.Item actions={[<li key="list-loadmore-edit">${itemsTax}</li>]}>
									Tax
								</List.Item>
							</List>
							{/* 'Discount on all items (%)', */}
							<List size="small">
								<List.Item actions={[<li key="list-loadmore-edit">${discountAllItems}</li>]}>
									Discount on all items (%)
									<Popover placement="right"
									         content={<Input onChange={discountAllItemsHandle} placeholder="0.00"/>}
									         trigger="click">
										<EditOutlined/>
									</Popover>
								</List.Item>
							</List>
							{/* 'Discount on subtotal', */}
							<List size="small">
								<List.Item actions={[<li key="list-loadmore-edit">${discountSubtotal}</li>]}>
									Discount on subtotal
									<Popover placement="right"
									         content={<Input onChange={discountSubtotalHandle} placeholder="0.00"/>}
									         trigger="click">
										<EditOutlined/>
									</Popover>
								</List.Item>
							</List>
							{/* 'Total ( Tax Excluded )', */}
							<List size="small">
								<List.Item style={{width: '100%'}}
								           actions={[<li key="list-loadmore-edit">${totalWithTax}</li>]}>
									Total ( Tax Excluded )
								</List.Item>
							</List>
							<div className="cart-footer-button">
								<Button shape="round" className="pay" type="primary">Pay</Button>
								<Button shape="round" className="reset" onClick={emptyCart}>Empty Cart</Button>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
			<Modal title="Add Customer" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}
			       footer={[
				       <Button key="back" onClick={handleCancel}>
					       Reset
				       </Button>,
				       <Button key="submit" type="primary" onClick={handleOk}>
					       Submit
				       </Button>
			       ]}>
				<Form onFinish={onFinish}>
					<Row className="ant-row" style={{rowGap: "15px"}} justify="space-between">

						<Col lg={12} xs={24}>
							<Form.Item label="Full Name" name="full_name" className="form-input">
								<Input placeholder="Full Name"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="Phone" name="phone" className="form-input">
								<Input placeholder="Phone"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="Email" name="email" className="form-input">
								<Input placeholder="Email"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="Address" name="address" className="form-input">
								<Input placeholder="Address"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="City" name="city" className="form-input">
								<Input placeholder="City"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="Post Code" name="post_code" className="form-input">
								<Input placeholder="Post Code"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="State" name="state" className="form-input">
								<Input placeholder="State"/>
							</Form.Item>
						</Col>

						<Col lg={12} xs={24}>
							<Form.Item label="Country" name="country" className="form-input">
								<Input placeholder="Country"/>
							</Form.Item>
						</Col>

					</Row>
				</Form>
			</Modal>
		</>
	)
}

export default NewOrderListView

