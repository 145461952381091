import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification, Modal, Row, Col} from 'antd';
import jsonfile from './sales.json'
import { PlusOutlined} from "@ant-design/icons";
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";

const {Option} = Select;

const CorporateSales = (props) => {

    const formRef = React.createRef();
    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [Customer, setCustomer] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [products, setProduct] = useState([]);
    const [bill, setBill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            Customer_address: "",
            Customer_city: "",
            Customer_state: "",
            Customer_zip_code: "",
            shipping_method: ""
        }
    );


    const [isModalCategory, setIsModalCategory] = useState(false);

    const showCategoryModal = () => {
        setIsModalCategory(true)
    }

    const handleCancel = () => {
        setIsModalCategory(false)
    };

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../Customer/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Customers = [];
    const Companies = SelectData("company/companylists");
    const ShippingMethod = SelectData("shippingmethod");
    const SaleType = StaticSelectData("sale_type");


    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();


    if(loaded) {
        OrderDetails();
    }

    const OrderDetails = async () => {
        return await axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.podata;
                    //onCompanyChange(podata.company_id);
                    setCustomer(res.data.Customers);
                    setPOdata(res.data.podata);
                    formRef.current.setFieldsValue({
                        company : podata.company_id,
                        com_address: podata.com_address,
                        com_city: podata.com_city,
                        com_state: podata.com_state,
                        com_zip_code: podata.com_zipcode,
                        Customer: podata.Customer_id,
                        Customer_address: podata.Customer_address,
                        Customer_city: podata.Customer_city,
                        Customer_state: podata.Customer_state,
                        Customer_zip_code: podata.Customer_zipcode,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });

                    const oid = res.data.oid;
                    const url = "../arap/salesdetails/"+oid;
                    const type = values['sales_type'];
                    history.push(redirectto);

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    useEffect(() => {
        if(isEdit === 1) {
            //OrderDetails();
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
        else {
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
    }, []);


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("Customer/Customerinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCustomer(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
            //document.getElementById("input_com_address").value = "Test";
            formRef.current.setFieldsValue({
                com_address: (company.address_line_1 || "")+", "+(company.address_line_2 || ""),
                com_city: company.city || "",
                com_state: company.state || "",
                com_zip_code: company.zip_code || "",
                Customer: "",
                Customer_address: "",
                Customer_city: "",
                Customer_state: "",
                Customer_zip_code: "",
                shipping_method: "",
                sales_tax_pp: 0
            });
        }
    }

    const onCompanyChangeLocal = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            let company = {};
            axios.get("customer/customerinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCustomer(res.data.data);
                        company = res.data.company;
                        setProduct(res.data.product);
                        console.log("selected : "+ JSON.stringify(company));


                        console.log("selected : "+ JSON.stringify(company));
                        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
                        //document.getElementById("input_com_address").value = "Test";
                        let  fullAddress = "";
                        if(company.address_line_2 !== null) {
                            fullAddress += company.address_line_1 || "";
                        }
                        if(company.address_line_2 !== null) {
                            fullAddress += "," +company.address_line_2 || "";
                        }

                        formRef.current.setFieldsValue({
                            company_id: company.id,
                            com_address: fullAddress,
                            com_city: company.city || "",
                            com_state: company.state || "",
                            com_zip_code: company.zip_code || "",
                            Customer: "",
                            Customer_address: "",
                            Customer_city: "",
                            Customer_state: "",
                            Customer_zip_code: "",
                            shipping_method: "",
                            sales_tax_pp: 0
                        });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const onCustomerChange = value => {
        console.log("selected : "+ value);
        let fl = Customer.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
           // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.billing_address_line_1+", "+company.billing_address_line_2);
            //document.getElementById("input_com_address").value = company.address_line_1+", "+company.address_line_2;
            var bill1 = company["address"];

            form.setFieldsValue({
                customer_address: bill1,
                customer_city: company["city"],
                customer_state: company["state"],
                customer_zip_code: company["zip_code"]
            });
        }
    }

    const appendChild = () => {
        let { data } = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data : data,
            rows : {
                code : '',
                name : '',
                qty : null,
                price : null,
                amount : null
            }
        });

    };


    const removeRow = () => {
        let { data } = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "qty") {
            const amount = value * rows[idx].price;
            rows[idx] = {
                item_id: rows[idx].item_id,
                code: rows[idx].code,
                name: rows[idx].name,
                current_stock: rows[idx].current_stock,
                qty: value,
                price: rows[idx].price,
                amount: amount
            };
        }
        else if(name === "price") {
            //CalculateCell();
            const amount = value * rows[idx].qty;
            rows[idx] = {
                item_id: rows[idx].item_id,
                code: rows[idx].code,
                name: rows[idx].name,
                current_stock: rows[idx].current_stock,
                qty: rows[idx].qty,
                price: value,
                amount: amount
            };

        }

        setState({
            rows
        });

        let totals = 0
        rows.map((item, idx) => {
            totals += item.amount;
        });

        var total = totals;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    };

    const ChangeTax = e => {

        const  value = parseFloat(e.target.value) || 0;
        let totals = 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (value / 100);
        const grand = (totals + bill.freight + tax) - bill.discount;
        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : value,
            discount : bill.discount
        });
    }

    const ChangeFreight = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value) || 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });


        var total = totals;
        var freight = value || 0;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const ChangeDiscount = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value) || 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (bill.tax / 100);
        const grand = (totals + bill.freight + tax) - value;

        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : bill.tax,
            discount : value
        });
    }

    const onDiscountChange = e => {

        var total = bill.total;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = parseFloat(e.target.value) || 0;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = totalBeforeTax + taxAmount + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const onTaxChange = e => {
        var total = bill.total;
        var freight = bill.freight;
        var taxp = parseFloat(e.target.value) || 0;
        var discount_p = bill.discount_p;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total)  - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const handleAddRow = () => {
        const item = {
            item_id : '',
            code : '',
            name : '',
            current_stock : 0,
            qty : null,
            price : null,
            amount : null
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    var totalAmount = 0;

    const CalculateCell = () => {
        var table = document.getElementById('itemtable');
        for (var r = 0, n = table.rows.length; r < n; r++) {
            var rowID = table.rows[r];
            var qty = rowID.cells[3].getElementsByTagName('INPUT')[0].value || 0;
            var price = rowID.cells[4].getElementsByTagName('INPUT')[0].value || 0;
            var total = parseInt(qty) * parseFloat(price);
            totalAmount += total;
            rowID.cells[4].getElementsByTagName('INPUT')[0].value = total;
        }
    }

    const ItemCellChangeProduct = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...state.rows];
        let productItems = products.filter((item, index) => item.id === e);
        let product =  productItems[0]
        rows[idx] = {
            item_id: product["id"],
            code: product["sku"],
            name: product["name"],
            current_stock: product["onhand"],
            qty: 1,
            price: product["retail_price"],
            amount: product["retail_price"]
        };

       // console.log(product);
       // console.log(rows);

        setState({
            rows
        });

        let totals = 0
        rows.map((item, idx) => {
            totals += item.amount;
        });

        var total = totals;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    }

    const onCustomerAdd = (values) => {
        axios.post(Config.apiserver + jsonfile.urls.newcustomer, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setCustomer(res.data.data);
                    form.setFieldsValue({"customer_id": res.data.newcustomer,
                        "customer_address":values["address"],
                        "customer_city":values["city"],
                        "customer_state":values["state"],
                        "customer_zip_code":values["zipcode"]
                    });
                    setIsModalCategory(false);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    const [selectedBeneficiary, setSelectedBeneficiary] = useState([]);
    const [loadingstatus, setLoadingStatus] = useState({
        'transaction': false,
        'beneficiary': false
    });

    const [beneficiary, setBenificiary] = useState({
        'customer': [],
        'vendor': [],
        'employee': [],
        'hqdepartment': [],
        'area': [],
        'district': [],
        'local': [],
        'staff': [],
        'ministries': [],
        'pentsos': [],
        'committee': [],
        'subsectors': [],
    });

    let BenificaryData = [];
    const BenificiaryType = SelectData("accountbenificary");
    const Local = SelectData("alllocal");

    const getBeneficiaryData = () => {
        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+"beneficiarylist")
            .then((res) => {
                // document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res.data.data;
                    setBenificiary(data);
                    loadingstatus["beneficiary"] = true;
                }
            })
            .catch((error) => {
                console.log(error);
                // document.getElementById("loader").style.display = "none";
            });
    }

    useEffect(() => {
        getBeneficiaryData();
    }, []);

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = beneficiary.customer;
            setSelectedBeneficiary(beneficiary.customer);
        } else if (e === 3) {
            BenificaryData = beneficiary.vendor;
            setSelectedBeneficiary(beneficiary.vendor);
        } else if (e === 4) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 6) {
            BenificaryData = beneficiary.hqdepartment;
            setSelectedBeneficiary(beneficiary.hqdepartment);
        } else if (e === 7) {
            BenificaryData = beneficiary.area;
            setSelectedBeneficiary(beneficiary.area);
        } else if (e === 8) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 9) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 10) {
            BenificaryData = beneficiary.ministries;
            setSelectedBeneficiary(beneficiary.ministries);
        } else if (e === 11) {
            BenificaryData = beneficiary.pentsos;
            setSelectedBeneficiary(beneficiary.pentsos);
        } else if (e === 12) {
            BenificaryData = beneficiary.district;
            setSelectedBeneficiary(beneficiary.district);
        } else if (e === 13) {
            BenificaryData = Local;
            setSelectedBeneficiary(Local);
        }
        else if (e === 14) {
            BenificaryData = beneficiary.committee;
            setSelectedBeneficiary(beneficiary.committee);
        }
        else if (e === 15) {
            BenificaryData = beneficiary.subsectors;
            setSelectedBeneficiary(beneficiary.subsectors);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }


    const formField = (

        <>
            <div className="row">
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="beneficiary_source"
                        label="Beneficiary/Source Type"
                        rules={[
                            {
                                required: true,
                                message: "Select a Beneficiary/source"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an payment source"
                            optionFilterProp="children"
                            onChange={onBenificaryChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                BenificiaryType.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="beneficiary_id"
                        label="Beneficiary"
                        rules={[
                            {
                                required: true,
                                message: "Select a beneficiary"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an beneficiary"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                selectedBeneficiary.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="shipping_method"
                            label="Shipping Method"
                            rules={[
                                {
                                    required: false,
                                    message: "Select a shipping method"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a shipping method"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    ShippingMethod.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="sales_type"
                            label="Sale Type"
                            initialValue="Regular"
                            rules={[
                                {
                                    required: false,
                                    message: "Select a sale type"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a sale type"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    SaleType.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>

                    </div>
                </div>


                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="discount_pp"
                            label="Discount (%)"
                            onChange={onDiscountChange}
                            rules={[
                                {
                                    required: false,
                                    message: "Payment Terms"
                                },
                            ]}
                        >
                            <Input value={bill.discount_p} placeholder="0" />
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="sales_tax_pp"
                            label="Sales Tax (%)"
                            onChange={onTaxChange}
                            rules={[
                                {
                                    required: false,
                                    message: "Enter sales tax amount"
                                }
                            ]}
                        >
                            <Input value={bill.taxp} placeholder="0" />
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="order_date"
                            label="Order Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Order date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Order Date" />
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="due_date"
                            label="Due Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Due date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Due Date" />
                        </Form.Item>

                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Sales Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Items</th>
                            <th>Current Stock</th>
                            <th>Quantity</th>
                            <th>Unit Price(₵)</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChangeProduct(idx)}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            products.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.sku}-{items.name} (Price: {items.retail_price})</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                                <td style={{width : '100px', textAlign: 'right'}}>{state.rows[idx].current_stock}</td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Quantity" name="qty" value={state.rows[idx].qty} onChange={ItemCellChange(idx)}  /></td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Price" name="price" value={state.rows[idx].price} onChange={ItemCellChange(idx)}  /></td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} readOnly={true} name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={5}>Gross Total</td>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.total || 0)}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign : 'right'}} colSpan={5}>Discount</td>
                            <td><input type="text" className="form-control" id="discount" readOnly={true} style={{textAlign : 'right'}} value={bill.discount_amount || 0} onChange={ChangeDiscount} name="discount" /></td>
                        </tr>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={5}>After Discount</td>
                            <td style={{textAlign: 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1((parseFloat(bill.total || 0) - parseFloat(bill.discount_amount || 0)))}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign : 'right'}} colSpan={5}>Sales Tax</td>
                            <td><input type="text" className="form-control" id="tax" readOnly={true} style={{textAlign : 'right'}} value={(bill.tax_amount || 0).toFixed(2)} onChange={ChangeTax} name="tax" /></td>
                        </tr>
                        <tr>
                            <td style={{textAlign : 'right'}} colSpan={5}>Freight</td>
                            <td><input type="text" className="form-control" id="tax" style={{textAlign : 'right'}} value={bill.freight || 0} onChange={ChangeFreight} name="freight" /></td>
                        </tr>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={5}>Grand Total</td>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.grand_total || 0)}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div onLoad={OrderDetails}></div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save Sales Order
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                    </div>
                </div>

            </Form>

            <Modal title="Add Customer" visible={isModalCategory} okButtonProps={{ form: 'categoryForm', key: 'submit', htmlType: 'submit' }} okText="Submit" onCancel={handleCancel} width={800}>
                <Form id="categoryForm" {...CisUI().formItemLayout} onFinish={onCustomerAdd} initialValues={{status: "1"}}>
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">

                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer Name" name="name" className="form-input" rules={[{ required: true, message: "Customer Name is required" }]}>
                                <Input placeholder="Enter a Customer Name" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer Phone" name="phone" className="form-input" rules={[{ required: true, message: "Customer phone is required" }]}>
                                <Input placeholder="Enter a phone number" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer Address" name="address" className="form-input" rules={[{ required: false, message: "Customer address is required" }]}>
                                <Input placeholder="Enter Customer Address" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer City" name="city" className="form-input" rules={[{ required: false, message: "Customer City is required" }]}>
                                <Input placeholder="Enter Customer City" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer State" name="state" className="form-input" rules={[{ required: false, message: "Customer State is required" }]}>
                                <Input placeholder="Enter Customer State" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Customer Zipcode" name="zipcode" className="form-input" rules={[{ required: false, message: "Customer zipcode is required" }]}>
                                <Input placeholder="Enter Customer zipcode" />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </Modal>


        </Card>
    );
};

export default CorporateSales;