import React, {useEffect, useState} from "react";
import {Form, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import Config from "../util/config";
import {useHistory} from "react-router-dom";
import axios from "util/Api";
import config from "../util/config";
import "./sForm.css";
import InfoView from "../components/InfoView";
import {forgotPassword} from "../appRedux/actions";


const Forgotpassword = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const forgotPass = useSelector(({auth}) => auth.forgotPass);
  const [loader, setLoader] = useState(false);
  const [user,setUser] = useState({
    email : ""
  });
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (e) => {
    if(e.target.name === 'email') {
      setUser({
        email : e.target.value
      })
    }
  }

  const history = useHistory();

  const onFinish = values => {
    // var endpoint = "forgotpassword";
    // var redirectto = "/passwordreset";
    values['email'] = user.email;
    // console.log(values)
    dispatch(forgotPassword(values))

    // axios.post(Config.apiserver + "auth/forgotpass", values)
    //     .then(res => {
    //       console.log(res)
    //       if(res.data.status === 1) {
    //         localStorage.setItem("fuser",values["email"]);
    //         notification.success({
    //           message: 'Success',
    //           type : "success",
    //           description: res.data.msg
    //         });
    //         history.push("/passwordreset");
    //       }
    //       else {
    //         notification.warning({
    //           message: 'Alert',
    //           type : "warning",
    //           description: res.data.msg
    //         });
    //       }
    //     })
    //     .catch(errors => {
    //       console.log(errors);
    //     });

  };

  useEffect(() => {
    if (forgotPass) {
      props.history.push('/passwordreset');
    }
  }, [forgotPass]);

  return (
      <>
        <div className="s_form_area">

          <div className="s_form_left">
            <img
                alt="example"
                src="/assets/images/penterpv1.png" className="s_login_logo"
            />
            <div className="s_left_text">

            </div>

            <div className="copy_right_area">{config.footerText}</div>
          </div>


          <div className="s_form_right">
            {/* <div className="elememt2_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg2.png'})`,  width: "346px",height: "330px",right: "52px",
        position: "absolute",top: "-160px", transform: "rotate(20deg)"}}></div> */}
            <div className="row" style={{width: "100%"}}>
              <div className="col-md-6 offset-md-3">
                <Form
                    initialValues={{remember: true}}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                  <div className="mb-3">
                    <label className="form-label" style={{color: 'white'}}>Email/Userid</label>
                    <input type="text" required name="email" onChange={onChange} className="form-control"
                           id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>

                  <div className="sub_link" style={{textAlign: 'right'}}>
                    <Link to="/">Have an account? Login</Link>
                  </div>

                  <button type="submit" className="btn btn_primary text-right s_btn"><IntlMessages
                      id="app.userAuth.submit"/></button>
                  {/* <div className="elememt3_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg1.png'})`,  width: "250px",height: "208px",right: "0px", position: "absolute",bottom: "0px", transform: "rotate(-20deg)"}}></div> */}
                </Form>

              </div>
            </div>
            <div className="copy_left_area">
              <div>{config.footerText}</div>
              <div style={{color: "#939393"}}>{config.version}</div>
            </div>
          </div>
          <InfoView/>
        </div>
      </>

  );
};

export default Forgotpassword;
