import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
// import jsonfile from './banktransaction.json'
import jsonfile from './reconcile_history.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Search = Input.Search;
const Panel = Collapse.Panel;

const ReconcileHistory = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.reconciliationhistory,
        filtervalue : '',
        subtitle : '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.reconciliationhistory
            }
        );
    }, []);

    const history = useHistory();

    const getMonthV = (mn) => {
        //console.log(mn);
        mn = String(mn);
        //console.log(mn);
        const mnd = mn.substr(0,4)+"-"+mn.substr(4,2)+"-25";
        //console.log(mnd);
        return CisUI().getMonthTitle(mnd);
    }

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            text: <IntlMessages id="Actions"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    <Link to={"reconciledetails/"+value} className="btn btn-text-adjust btn-circle-md">
                        <i className="fas fa-folder" />
                    </Link>
                </>
            )
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
        },
        account: {
            text: <IntlMessages id="Account"/>,
            sortable: true,
            filterable: true
        },
        month: {
            text: <IntlMessages id="Month"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                getMonthV(value)
            )
        },
        beginning_balance: {
            text: <IntlMessages id="Beginning Balance"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        deposit: {
            text: <IntlMessages id="Total Deposit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        payment: {
            text: <IntlMessages id="Total Payment"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        ending_balance: {
            text: <IntlMessages id="Ending Balance"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        created_by: {
            text: <IntlMessages id="Created By"/>,
            sortable: true,
            filterable: true
        },
        created_at: {
            text: <IntlMessages id="Created at"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alltransactions+"/?type=2&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,'date',CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionReport("../"+jsonfile.urls.add,"",state.reqURL,"data","reconcile_history",jsonfile,state.subtitle, "landscape")}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default ReconcileHistory;