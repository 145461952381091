import React, { Fragment } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input, Col } from "antd";

function DynamicField(props) {
  return (
    <Form.List name="custom_fields">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Fragment key={field.key}>

                {/*{console.log(field)}*/}

                <Col lg={11} xs={24}>
                  <Form.Item name={[index, "label"]} className="form-input">
                    <Input placeholder="Label" />
                  </Form.Item>
                </Col>
                <Col lg={11} xs={24}>
                  <Form.Item name={[index, "value"]} className="form-input">
                    <Input placeholder="Value" />
                  </Form.Item>
                </Col>
                {fields.length >= 1 ? (
                  <Col lg={2} xs={24}>
                    <Button onClick={() => remove(field.name)} type="link">
                      Remove
                    </Button>
                  </Col>
                ) : null}
              </Fragment>
            ))}
            <Col lg={24} xs={24}>
              <Button onClick={() => add()} type="link">
                {fields.length > 0? "Add More": "Add Custom Field"}
              </Button>
            </Col>
          </>
        );
      }}
    </Form.List>
  );
}

export default DynamicField;
