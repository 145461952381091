import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Modal, Select, notification} from 'antd';
import {Link} from "react-router-dom";
import jsonfile from './requistion.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";

var Barcode = require('react-barcode');
const {Option} = Select;

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();


const POReqDetails = (props) => {

    const formRef = React.createRef();

    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        po: {},
        items: []
    })


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const Print = () => {
        return window.print();
    };


    const onFinish = () => {

    }

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.podata,
                        items: res.data.items
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        let postatus = 0;
        if(state.po.workflow_status === 0) {
            postatus = 1;
        }
        else if(state.po.workflow_status === 1) {
            postatus = 2;
        }
        else if(state.po.workflow_status === 2) {
            postatus = 3;
        }
        axios.get("purchase/poreqstatuschange?po_id="+id+"&status="+status+"&postatus="+postatus)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                // console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };

    return (
        <>
            <Card title={Title}>
                {CisUI().showLoading}
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <div style={{width: '100%', background: 'white', border: '1px #ddd solid', padding: '20px'}}>
                            <table style={{width: '100%'}}>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                        <img alt="cop" style={{width: '100px'}} src={Config.print_logourl}/>
                                        {/*<div>{Config.address}</div>*/}
                                        {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                        {/*<div>Phone : {Config.phone}</div>*/}
                                        {/*<div>Email : {Config.email}</div>*/}
                                        {/*<div>Website : {Config.website}</div>*/}
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right'}}>
                                        <h2>PURCHASE REQUISITION</h2>
                                        <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                        <div>PO Number : {state.po.po_number}</div>
                                        <div><Barcode height={50} value={state.po.po_number}/></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Ship To : </h3>
                                        <h5>{state.po.company}</h5>
                                        <div>{state.po.address}</div>
                                        <div>{state.po.city}, {state.po.state}, {state.po.zipcode}</div>
                                        <div>Phone : {state.po.company_phone}</div>
                                        <div>Email : {state.po.company_phone}</div>
                                    </td>
                                </tr>
                            </table>

                            <table className="ptable table table-bordered" style={{marginTop: '30px'}} width="100%">
                                <thead>
                                <tr>
                                    <th>Required By</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{CisUI().DateFormat(state.po.required_date)}</td>
                                </tr>
                                </tbody>
                            </table>

                            <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item Code</th>
                                    <th>Item Description</th>
                                    <th>Reference/URL</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.items.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width: '30px'}}>{(idx + 1)}</td>
                                        <td style={{width: '150px'}}>{item.code}</td>
                                        <td>{item.name}</td>
                                        <td>{item.url}</td>
                                        <td style={{width: '70px', textAlign: 'right'}}>{item.quantity}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="row no-print">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                        {
                            state.isPOApprover === 1 ?
                                state.po.workflow_status === 0 ?
                                    <>
                                        <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="submit">Reject</Button>
                                        <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="submit">Review and Confirm</Button>
                                    </>
                                    : state.po.workflow_status === 1 ?
                                        <>
                                            <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="submit">Reject</Button>
                                            <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="submit">Certify and Confirm</Button>
                                        </>
                                        : state.po.workflow_status === 2 ?
                                            <>
                                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="submit">Reject</Button>
                                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="submit">Authorize and Confirm</Button>
                                            </>
                                            :<></>
                                :""
                        }


                        <a className="ant-btn ant-btn-primary" onClick={Print} >Print</a>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Back</a>
                    </div>
                </div>
            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </>
    );
};

export default POReqDetails;