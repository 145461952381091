import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import ForgotPassword from "../forgotpassword";
import PasswordReset from "../passwordreset";
import Otp from "../Otp";
// import axios from "../../util/Api";
import SignUp from "../SignUp";
import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";
// import AutoLagoutTimer from  "../../util/AutoLogoutTimer";

// import  localStorage  from  "react-secure-storage";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  // THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import CircularProgress from "../../components/CircularProgress";
import {getUser} from "../../appRedux/actions";
import SignInDup from "../Signinverify";

const RestrictedRoute = ({component: Component, location, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                      pathname: '/signin',
                      state: {from: location}
                    }}
                />}
    />;


const App = () => {
  const dispatch = useDispatch();
  const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
  const {token, initURL, loadingAuthUser, authUser} = useSelector(({auth}) => auth);


  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(getUser(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      // const counter = localStorage.getItem("otpstatus");
      const counter = 0;
      if (counter === 1) {
        history.push('/otp');
      } else {
        if (authUser === null) {
          history.push('/signin');
        } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
          history.push('/main/dashboard');
        } else {
          history.push(initURL);
        }
      }
    }
  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  const onUserNavigate = () => {
    // var timeoutTimer = setTimeout(function() {
    //   console.log("TimeCounting");
    //   userSignOut();
    // }.bind(), 10000);
  }

  return loadingAuthUser ? <CircularProgress/> : (
      <ConfigProvider locale={currentAppLocale.antd}>
        {/*{AutoLagoutTimer}*/}

        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signinverify' component={SignInDup}/>
            <Route exact path='/otp' component={Otp}/>
            {/*<Route exact path='/signup' component={SignUp}/>*/}
            <Route exact path='/forgotpassword' component={ForgotPassword}/>
            <Route exact path='/passwordreset' component={PasswordReset}/>
            <RestrictedRoute path={`${match.url}`} onEnter={onUserNavigate} onChange={onUserNavigate} authUser={authUser} location={location} component={MainApp}/>
            {/*<RestrictedRoute path={`${match.url}`} onEnter={onUserNavigate} onChange={onUserNavigate} authUser={authUser} location={location} >*/}
            {/*  <AutoLagoutTimer ComposedClass={MainApp} />*/}
            {/*</RestrictedRoute>*/}
          </Switch>
        </IntlProvider>
      </ConfigProvider>
  )
};

export default memo(App);
