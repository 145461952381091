import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import CONFIG from "../../util/config";
import {CisUI} from "../../util/CISUI";

import {getUserProfileData} from "../../appRedux/actions";

const UserProfile = () => {
    const dispatch = useDispatch();

    const userLocal = localStorage.getItem('userData') || "";
    let userID = 0;
    let userName = "";
    let companyName = "";
    if (userLocal !== "") {
        const user = JSON.parse(userLocal);
        userID = user['id'];
        userName = user['name'];
        companyName = user['companyName'] || "";
    }

    let userData = useSelector((state) => state.auth.profileData);


    useEffect(() => {
        if (userLocal !== "") {
            dispatch(getUserProfileData(userID));
        }
    }, []);
    // console.log(userData)

    const setVerticalLayout = () => {
        console.log("Clicked on Window reload");
        localStorage.setItem("layout", "vertical");
        window.location.reload();
    }

    let ProfilePicURL = "https://via.placeholder.com/150";
    const image = CisUI().getUserInfo('image');
    if(image !== "") {
        ProfilePicURL = CONFIG.profile_pic_url+""+image;
        console.log(ProfilePicURL);
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>
                <Link to={`/profile/myaccount`}>My Account</Link>
            </li>
            <li>
                <Link to={`/profile/changepassword`}>Change Password</Link>
            </li>
            <li>
                <Link to={"/changelayout"}>Change Layout</Link>
            </li>
            <li onClick={() => dispatch(userSignOut())}>Logout
            </li>
        </ul>
    );

    // const userInfo = localStorage.getItem("userData");
    // const user = JSON.parse(userInfo);

    return (

        <>
            <div className="gx-flex-row gx-align-items-center gx-avatar-row">
                <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                    {/*<Avatar src={Config.profile_pic_url + userData.image}*/}
                    <Avatar src={ProfilePicURL}
                            className="gx-pointer gx-mr-3" alt=""/>
                    <span className="gx-avatar-name">
                        <span>{userName}</span>
                        <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
                    </span>
                </Popover>
            </div>
            <div className="gx-font-weight-light" style={{ color: "#051c35", marginTop: '-10px', paddingLeft : '50px', fontSize: '14px'}}>{companyName}</div>
        </>

    )
};

export default UserProfile;
