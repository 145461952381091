import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification, Radio} from 'antd';
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";

const {Option} = Select;
const {TextArea} = Input;

const MultipleVoucher = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [isCash, setIsCash] = useState(false);
    const [cashaccount, setCashAccount] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);
    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        area: [],
        district: [],
        local: [],
        subsector: [],
        committee: [],
    });


    const [doc, setDoc] = useState({});
    const [segment, setSegment] = useState(1);
    const [selectedSegment, setSelectedSegment] = useState([]);

    const [voucherType, setVoucherType] = useState("Payment");

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
    };


    const [bill, setBill] = useState(
        {
            totalReceipt : 0,
            totalPayment : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            vendor_address: "",
            vendor_city: "",
            vendor_state: "",
            vendor_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const BankName = SelectData("banknames");
    const Benificiary = SelectData("accountbenificary");
    const [benificiariesData, setBenificiaryData] = useState([]);
    const PaymentMethod = SelectData("paymentmethod");
    const BankAccount = SelectData("bankaccount");
    let BenificaryData = [];
    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        } else if (e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        } else if (e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        } else if (e === 6) {
            BenificaryData = beneficiaryData.department;
            setBenificiaryData(beneficiaryData.department);
        } else if (e === 7) {
            BenificaryData = beneficiaryData.area;
            setBenificiaryData(beneficiaryData.area);
        } else if (e === 8) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 9) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            BenificaryData = beneficiaryData.ministry;
            setBenificiaryData(beneficiaryData.ministry);
        } else if (e === 11) {
            BenificaryData = beneficiaryData.pentsos;
            setBenificiaryData(beneficiaryData.pentsos);
        } else if (e === 12) {
            BenificaryData = beneficiaryData.district;
            setBenificiaryData(beneficiaryData.district);
        } else if (e === 13) {
            BenificaryData = beneficiaryData.local;
            setBenificiaryData(beneficiaryData.local);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }

    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: null,
        benificiary_id: null,
        vendor_id: null,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });

    const onBankChange = (value) => {
        const vl = value+Math.floor(Math.random() * 900000);
        form.setFieldsValue({reference_no : vl});
    }


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.many_voucher_add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../vendor/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const ShippingMethod = SelectData("shippingmethod");


    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            area: res.data.area,
                            district: res.data.district,
                            local: res.data.local
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }


    if(loaded) {
        OrderDetails();
    }

    const OrderDetails = async () => {
        return await axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.podata;
                    //onCompanyChange(podata.company_id);
                    setVendor(res.data.vendors);
                    setPOdata(res.data.podata);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    history.push("../entries/mvoucherdetails/"+res.data.voucher_id);
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    };

    useEffect(() => {
        if(isEdit === 1) {
            //OrderDetails();
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
        else {
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
    }, []);


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const onCompanyChangeLocal = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            let company = {};
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                        company = res.data.company;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const ItemCellChange = (idx,cname="") => e => {
        let name = "";
        let value = null;
        if(cname === "") {
            name = e.target.name;
            value = e.target.value;
        }
        else {
            name = cname;
            value = e;
        }

        const rows = [...state.rows];
        console.log("Index:"+ idx);
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        console.log("Event:"+ name);
        console.log("EventValue:"+ value);

        if(name === "account_id") {
            const sub = accountchild[parseInt(value)] || [];
            setSubAccount(sub);
            rows[idx] = {
                account_id: value,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                amount: rows[idx].amount,
                subaccounts: sub
            };
        }
        else if(name === "sub_account_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: value,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                amount: rows[idx].amount,
                subaccounts: rows[idx].subaccounts
            };
        }
        else if(name === "doc_class") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: value,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                amount: rows[idx].amount,
                subaccounts: rows[idx].subaccounts
            };
        }
        else if(name === "description") {
            //const amount = value * rows[idx].price;
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: value,
                checkno: rows[idx].checkno,
                amount: rows[idx].amount,
                subaccounts: rows[idx].subaccounts
            };
        }
        else if(name === "amount") {
            //CalculateCell();
            //const amount = value * rows[idx].qty;
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                amount: value,
                subaccounts: rows[idx].subaccounts
            };
        }
        else if(name === "checkno") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: value,
                amount: rows[idx].amount,
                subaccounts: rows[idx].subaccounts
            };
        }
        else if(name === "selectedsegment") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: value,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                amount: rows[idx].amount,
                subaccounts: rows[idx].subaccounts
            };
        }

        setState({
            rows
        });

        let totalsReceipt = 0
        let totalsPayment = 0
        let totalAmount = 0
        rows.map((item, idx) => {
            totalAmount += parseFloat(item.amount);
        });


        setBill({
            totalReceipt : totalAmount
        });

    };


    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        if(!isCash) {
            bankaccounts.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });

            // console.log("RefNo : " + ref);

            form.setFieldsValue({
                check_no: ref,
            });
        }
    }


    const handleAddRow = () => {
        const item = {
            account_id : null,
            sub_account_id : null,
            doc_class : null,
            selectedsegment : 1,
            description : "",
            checkno : "",
            amount : 0,
            subaccounts: []
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }

    var totalAmount = 0;

    const formField = (

        <>
            <div className="row">

                <div className="col-md-3">
                    <Form.Item
                        name="voucher_type"
                        label="Voucher Type"
                        initialValue={"Payment"}
                        rules={[
                            {
                                required: true,
                                message: "Select a voucher type"
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeRadio} value={voucherType}>
                            <Radio value={"Payment"}>Payment</Radio>
                            <Radio value={"Receipt"}>Receipt</Radio>
                        </Radio.Group>

                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                        <div className="col-md-3">
                            <Form.Item
                                name="com_id"
                                initialValue={polist.company_id}
                                label="Company"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-3">
                    <Form.Item
                        name="bankname_id"
                        initialValue={polist.bankname_id}
                        label="Bank"
                        rules={[
                            {
                                required: false,
                                message: "Select a bank"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank"
                            optionFilterProp="children"
                            onChange={onBankChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                BankName.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="reference_no"
                        label="Voucher No#"
                        initialValue={Math.floor(Math.random() * 900000)}
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="benificiary_type"
                        initialValue={polist.benificiary_type}
                        label="Beneficiary/Source Type"
                        rules={[
                            {
                                required: true,
                                message: "Select a Beneficiary/source"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an payment source"
                            optionFilterProp="children"
                            onChange={onBenificaryChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                Benificiary.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="benificiary_id"
                        initialValue={polist.benificiary_id}
                        label="Benificiary"
                        rules={[
                            {
                                required: true,
                                message: "Select a benificiary"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an benificiary"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                benificiariesData.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        initialValue={2}
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3" id="bankaccount" >
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                isCash ?
                                    cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="check_no"
                        label="Cheque No#"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter cheque no"
                            },
                        ]}
                    >
                        <Input placeholder="Enter cheque/reference no"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter somthing ehre"/>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Voucher Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>GL Code / Account</th>
                            {/*<th>Doc. Class</th>*/}
                            <th style={{width : '300px'}}>Item Description</th>
                            {
                                voucherType === "Receipt" ? <th style={{width : '100px'}}>Receipt</th> : <th>Payment</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChange(idx,"account_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            mainaccount.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br/> <br/>
                                    {
                                        item.subaccounts.length > 0 ?
                                    <Select
                                        showSearch
                                        placeholder="Select a sub account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChange(idx,"sub_account_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            item.subaccounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select> : <></> }
                                </td>
                                {/*<td style={{width : '250px'}}>*/}
                                {/*    <Select*/}
                                {/*        showSearch*/}
                                {/*        placeholder="Select a document class"*/}
                                {/*        optionFilterProp="children"*/}
                                {/*        style={{width : '100%'}}*/}
                                {/*        value={state.rows[idx].doc_class}*/}
                                {/*        onChange={ItemCellChange(idx,"doc_class")}*/}
                                {/*        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}*/}
                                {/*    >*/}
                                {/*        {*/}
                                {/*            selectedSegment.length > 0 ?*/}
                                {/*            doc[state.rows[idx].selectedsegment].map((items, index) =>*/}
                                {/*                <Option key={++index} value={items.id}>{items.class}-{items.description}</Option>*/}
                                {/*            ) : null*/}
                                {/*        }*/}
                                {/*    </Select>*/}
                                {/*    <div style={{paddingLeft: '20px'}}>*/}
                                {/*        <Radio.Group name="selectedsegment" onChange={ItemCellChange(idx)} value={state.rows[idx].selectedsegment}>*/}
                                {/*            <Radio value={1}>HQ</Radio>*/}
                                {/*            <Radio value={2}>Area</Radio>*/}
                                {/*            <Radio value={3}>Individual</Radio>*/}
                                {/*        </Radio.Group>*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                                <td><input className="form-control" placeholder="Enter Item Description" name="description" value={state.rows[idx].name} onChange={ItemCellChange(idx)}  /></td>
                                {
                                    voucherType === "Receipt" ?
                                        <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Receipt" name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                        : <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} name="amount" placeholder="Payment" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                }
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={3}>Total</td>
                            {
                                voucherType === "Receipt" ?
                                    <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                                    :<td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                            }
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    return (
        <Card title={<IntlMessages id={"Multiple Voucher Entry"} />} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div onLoad={OrderDetails}></div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default MultipleVoucher;