import React from "react";
import {Route, Switch} from "react-router-dom";

import RegionList from "../../containers/yotta/settings/region/RegionList";
import NewRegion from "../../containers/yotta/settings/region/NewRegion";
import DistrictList from "../../containers/yotta/settings/district/DistrictList";
import NewDistrict from "../../containers/yotta/settings/district/NewDistrict";
import LocalList from "../../containers/yotta/settings/local/LocalList";
import NewLocal from "../../containers/yotta/settings/local/NewLocal";
import OfficeList from "../../containers/yotta/settings/office/OfficeList";
import NewOffice from "../../containers/yotta/settings/office/NewOffice";
import FundProgramList from "../../containers/yotta/settings/fund_program/FundProgramList";
import NewFundProgram from "../../containers/yotta/settings/fund_program/NewFundProgram";
import FunctionalExpenseList from "../../containers/yotta/settings/functional_expense/FunctionalExpenseList";
import NewFunctionalExpense from "../../containers/yotta/settings/functional_expense/NewFunctionalExpense";
import ApproverList from "../../containers/yotta/settings/approver/ApproverList";
import NewApprover from "../../containers/yotta/settings/approver/NewApprover";
import BankAccountList from "../../containers/yotta/settings/bank_account/BankAccountList";
import NewBankAccount from "../../containers/yotta/settings/bank_account/NewBankAccount";
import ArticleList from "../../containers/yotta/settings/article/ArticleList";
import NewArticle from "../../containers/yotta/settings/article/NewArticle";
import EditArticle from "../../containers/yotta/settings/article/EditArticle";
import NewApporverMulty from "../../containers/yotta/settings/approver/NewApporverMulty";
import MyApproverList from "../../containers/yotta/settings/approver/myapprovalpermissions";
import ImportOpeningBalance from "../../containers/yotta/settings/importopeningbalance";
import NewPENTSOS from "../../containers/yotta/settings/pentsos/new";
import PentSOSList from "../../containers/yotta/settings/pentsos/list";
import MinistryList from "../../containers/yotta/settings/ministry/list";
import NewMinistry from "../../containers/yotta/settings/ministry/new";
import NewHQDepartment from "../../containers/yotta/settings/hqdepartment/new";
import HQDepartmentList from "../../containers/yotta/settings/hqdepartment/list";
import NewBank from "../../containers/yotta/settings/bank/new";
import BankList from "../../containers/yotta/settings/bank/list";
import ComitteeList from "../../containers/yotta/settings/committee/list";
import NewCommittee from "../../containers/yotta/settings/committee/new";
import SubsectorList from "../../containers/yotta/settings/subsector/list";
import NewSubsector from "../../containers/yotta/settings/subsector/new";


const Settings = ({match}) => (
    <Switch>
        <Route path={`${match.url}/regions`} component={RegionList}/>
        <Route path={`${match.url}/newregion`} component={NewRegion}/>
        <Route path={`${match.url}/regionedit/:id`} component={NewRegion}/>
        <Route path={`${match.url}/district`} component={DistrictList}/>
        <Route path={`${match.url}/newdistrict`} component={NewDistrict}/>
        <Route path={`${match.url}/districtedit/:id`} component={NewDistrict}/>
        <Route path={`${match.url}/local`} component={LocalList}/>
        <Route path={`${match.url}/newlocal`} component={NewLocal}/>
        <Route path={`${match.url}/localedit/:id`} component={NewLocal}/>
        <Route path={`${match.url}/offices`} component={OfficeList}/>
        <Route path={`${match.url}/newoffice`} component={NewOffice}/>
        <Route path={`${match.url}/officeedit/:id`} component={NewOffice}/>
        <Route path={`${match.url}/fundprograms`} component={FundProgramList}/>
        <Route path={`${match.url}/newfundprogram`} component={NewFundProgram}/>
        <Route path={`${match.url}/fundprogramedit/:id`} component={NewFundProgram}/>
        <Route path={`${match.url}/functionalexpenses`} component={FunctionalExpenseList}/>
        <Route path={`${match.url}/newfunctionalexpense`} component={NewFunctionalExpense}/>
        <Route path={`${match.url}/functionalexpenseedit/:id`} component={NewFunctionalExpense}/>
        <Route path={`${match.url}/approvers`} component={ApproverList}/>
        {/*<Route path={`${match.url}/newapprover`} component={NewApprover}/>*/}
        {/*<Route path={`${match.url}/newapprover`} component={NewApporverMulty}/>*/}
        <Route path={`${match.url}/approver`} component={NewApporverMulty}/>
        <Route path={`${match.url}/apvperm`} component={MyApproverList}/>
        <Route path={`${match.url}/approveredit/:id`} component={NewApprover}/>
        <Route path={`${match.url}/bankaccounts`} component={BankAccountList}/>
        <Route path={`${match.url}/newbankaccount`} component={NewBankAccount}/>
        <Route path={`${match.url}/bankaccountedit/:id`} component={NewBankAccount}/>
        <Route path={`${match.url}/supportarticle`} component={ArticleList}/>
        <Route path={`${match.url}/newsupportarticle`} component={NewArticle}/>
        <Route path={`${match.url}/supportarticleedit/:id`} component={EditArticle}/>
        <Route path={`${match.url}/openingbalance`} component={ImportOpeningBalance}/>

        <Route path={`${match.url}/addnewpentsos`} component={NewPENTSOS}/>
        <Route path={`${match.url}/pentsosedit/:id`} component={NewPENTSOS}/>
        <Route path={`${match.url}/pentsoslist`} component={PentSOSList}/>

        <Route path={`${match.url}/addnewministry`} component={NewMinistry}/>
        <Route path={`${match.url}/ministryedit/:id`} component={NewMinistry}/>
        <Route path={`${match.url}/ministrylist`} component={MinistryList}/>

        <Route path={`${match.url}/addnewhqdep`} component={NewHQDepartment}/>
        <Route path={`${match.url}/hqdepartmentedit/:id`} component={NewHQDepartment}/>
        <Route path={`${match.url}/hqdepartmentlist`} component={HQDepartmentList}/>
        <Route path={`${match.url}/addbank`} component={NewBank}/>
        <Route path={`${match.url}/editbank/:id`} component={NewBank}/>
        <Route path={`${match.url}/banklist`} component={BankList}/>

        <Route path={`${match.url}/committee`} component={ComitteeList}/>
        <Route path={`${match.url}/editcommittee/:id`} component={NewCommittee}/>

            <Route path={`${match.url}/subsector`} component={SubsectorList}/>
            <Route path={`${match.url}/editsubsector/:id`} component={NewSubsector}/>


    </Switch>
);

export default Settings;
