import {Form, Row, Col, Input, Button, Card, Select, Checkbox,notification, Spin} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './profile.json'
import {CisUI} from '../../../util/CISUI'
import PostToApi from '../../../util/PostToApi'
import InputFields from '../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import  localStorage  from  "react-secure-storage";

const ChangePassword = (props) => {

    const [loading, setLoading] = useState([]);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const user = JSON.parse(localStorage.getItem('userData'));
    const userID = user['id'];
    const userData = [];


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        console.log("Input Data : "+values);
        // console.log(values);
        document.getElementById('loader').style.display = "block";
        axios.post(Config.apiserver + jsonfile.urls.password_update + "/" + userID, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    // history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById('loader').style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
            });
    };


    //console.log("UserData : "+userData);

    return (
        <Col lg={12} md={12} sm={12} xs={24} offset={6}>
            <Card title={jsonfile.title1}>
                {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="old_password"
                                label="Old Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your old password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>



            </Form>
        </Card>
        </Col>
    );
};

export default ChangePassword;