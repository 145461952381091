import React from "react";
import {Route, Switch} from "react-router-dom";
// import ProductsReport from "../../containers/Products/ProductsReport";
// import Price from "./price";
// import ProductInfo from "./productinfo";
// import SubCategory from "./subcategory";

import AddCategory from "../../containers/yotta/products/category/AddCategory";
import CategoryList from "../../containers/yotta/products/category/CategoryList";
import EditCategory from "../../containers/yotta/products/category/AddCategory";
import AddSubCategory from "../../containers/yotta/products/sub_category/AddSubCategory";
import SubCategoryList from "../../containers/yotta/products/sub_category/SubCategoryList";
import EditSubCategory from "../../containers/yotta/products/sub_category/AddSubCategory";
import AddProduct from "../../containers/yotta/products/product_info/AddProduct";
import ProductList from "../../containers/yotta/products/product_info/ProductList";
import EditProduct from "../../containers/yotta/products/product_info/EditProduct";
import AddPrice from "../../containers/yotta/products/price/NewPrice";
import PriceList from "../../containers/yotta/products/price/ChangeHistory";
import EditPrice from "../../containers/yotta/products/price/NewPrice";
import ProductReport from "../../containers/yotta/products/ProductsReport";
import CurrentStock from "../../containers/yotta/inventory/currentstock";

const Product = ({ match }) => (
	<>
		<Switch>
			<Route path={`${match.url}/addCategory`} component={AddCategory} />
			<Route path={`${match.url}/pdcategory`} component={CategoryList} />
			<Route path={`${match.url}/editCategory/:id`} component={EditCategory} />
			<Route path={`${match.url}/addSubCategory`} component={AddSubCategory} />
			<Route path={`${match.url}/subcategory`} component={SubCategoryList} />
			<Route path={`${match.url}/editSubCategory/:id`} component={EditSubCategory} />
			<Route path={`${match.url}/addProduct`} component={AddProduct} />
			<Route path={`${match.url}/products`} component={ProductList} />
			<Route path={`${match.url}/editProduct/:id`} component={EditProduct} />
			<Route path={`${match.url}/addNewPrice`} component={AddPrice} />
			<Route path={`${match.url}/pdpricesetup`} component={PriceList} />
			<Route path={`${match.url}/editChangePrice/:id`} component={EditPrice} />
			<Route path={`${match.url}/productReport`} component={ProductReport} />

			<Route path={`${match.url}/currentstock`} component={CurrentStock} />
		</Switch>
	</>

);

export default Product;