import React from 'react';
import {Popover, message, Popconfirm, notification} from "antd";
import {Link} from "react-router-dom";
import Config from "./config";
import axios from "axios";
import {CisUI} from "./CISUI";
import IntlMessages from "./IntlMessages";

const ActionButton = (id,prefix= {"edit":"edit","open":"open","delete":"delete"},endpoint="",mprefix="../",hasAdditonalMenu=0,additionalMenu=[], isDelete="", qstring="") => {

    function confirm(e) {
        message.success('Click on Yes');
        let url = Config.apiserver+prefix.delete+`/${id}`;
        // <Redirect to={"../"+prefix.delete+`/${id}`}></Redirect>
        // console.log(url);

        axios.delete(url, CisUI().HeaderRequest)
            .then(res=>{
                console.log(res.data.status);

                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "warning",
                        description: res.data.msg
                    });

                    window.location.reload();
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
            })
            .catch((error)=>{
                console.log(error)
                notification.error({
                    message: 'Error.',
                    type: 'error',
                    description: "Failed to delete."
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error("Data is not deleted.");
    }

    const AddiontalMenuTP = () => {
        // console.log("MeneFounds");
        if(hasAdditonalMenu === 1) {
            additionalMenu.map((itemsd,index)=> {
                if(itemsd.hasID === 1) {
                    return <p>
                        <Link to={itemsd.url+id}>
                            <button className="btn btn-primary btn-circle">
                                <i className="fas fa-link"/>
                            </button> <IntlMessages id={itemsd.label}/>
                        </Link>
                    </p>
                }
                else {
                    // console.log("MeneFound");
                    return <p>
                        <Link to={itemsd.url}>
                            <button className="btn btn-primary btn-circle">
                                <i className="fas fa-link"/>
                            </button> <IntlMessages id={itemsd.label}/>
                        </Link>
                    </p>
                }
            });
        }
        else {
            return "";
        }
    }

    const  adMenu = AddiontalMenuTP();

    let menu = 1;

    if(CisUI().getPermission(prefix.list,"edit") === 1 || CisUI().getUserInfo("com_id") === 0) {
        menu = 1
    }

    if(CisUI().getPermission(prefix.list,"delete") === 1 || CisUI().getUserInfo("com_id") === 0) {
        menu = 1
    }

    const text = <span><IntlMessages id="Actions"/></span>;
    const content = (
        <div>
            {/*<p>*/}
            {/*    <Link to={prefix.view+`/${id}`}>*/}
            {/*        <button className="btn btn-primary btn-circle">*/}
            {/*            <i className="fas fa-folder-open"/>*/}
            {/*        </button> Open*/}
            {/*    </Link>*/}
            {/*</p>*/}

            {AddiontalMenuTP}
            {
                CisUI().getPermission(prefix.list,"edit") === 1 || CisUI().getUserInfo("com_id") === 0 ?
            <p>
                {
                    qstring !== "" ?
                        <Link to={mprefix+prefix.edit+`/${id}`+"?"+qstring}>
                            <button className="btn btn-primary btn-circle">
                                <i className="fas fa-pencil-alt"/>
                            </button> <IntlMessages id="Edit" />
                        </Link>
                    :
                        <Link to={mprefix+prefix.edit+`/${id}`}>
                            <button className="btn btn-primary btn-circle">
                                <i className="fas fa-pencil-alt"/>
                            </button> <IntlMessages id="Edit" />
                        </Link>
                }
            </p> : "" }
            {
                CisUI().getPermission(prefix.list,"delete") === 1 || CisUI().getUserInfo("com_id") === 0 ?
            <p>
                <Link to="#">
                    <Popconfirm title="Are you sure you want to delete this?" onConfirm={confirm} onCancel={cancel} okText="Yes"
                                cancelText="No">
                            <button className="btn btn-danger btn-circle">
                                <i className="fas fa-trash"/>
                            </button> <IntlMessages id="Delete" />
                    </Popconfirm>
                </Link>
            </p> : "" }
        </div>
    );

    return (
        <>
            {menu === 1 ?
            <Popover placement="leftBottom" title={text} content={content} trigger="click">
                <button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-h"></i></button>
            </Popover> : "" }
        </>
    );
}

export default ActionButton;