import {Form, Row, Col, Button, Card, notification, DatePicker} from 'antd';
import React, {useState} from "react";
import jsonfile from './collection.json';
import jsonMemberFile from './../member/member.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";
import moment from "moment";
import dayjs from 'dayjs';
import 'moment/locale/es-us';


import IntlMessages from "../../../../util/IntlMessages";

const NewCollection = (props) => {

    moment.locale('en-us');

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [cdate, setCDate] = useState({
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
    });

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../" + jsonfile.urls.list;
    const history = useHistory();

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : " + props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
    }


    const onFinish = (values) => {
        setLoading({loading: true})

        let msg = "Sorry! request not processed, please try again";
        values["is_ministry"] = 0;
        document.getElementById("loader").style.display = "block";

        console.log("Input Data : " + JSON.stringify(values));
        const month = values['month'];
        const today = moment().format("YYYY-MM");
        const selected = moment(month).format("YYYY-MM");
        const selected1 = moment(month).format("YYYYMM");
        // console.log(today);
        // console.log(selected);

        // let date = moment().format("YYYY-MM-DD");
        // if(today > selected) {
        //     date = moment(month).clone().endOf('month').format("YYYY-MM-DD");
        //     console.log("NewDate: "+ date);
        // }
        // else {
        //     // console.log(today);
        //     date = moment().format("YYYY-MM-DD");
        // }

        values["month"] = selected1;

        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });

                    form.resetFields();

                    document.getElementById("submitbutton").style.display = "none";
                    const time = Math.random();

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading: false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };


    $('#input_tithe_number').unbind('keyup');
    $('#input_tithe_number').bind('keyup', function (e) {
        document.getElementById("loaderinfo").style.display = "block";
        document.getElementById("meminfo").style.display = "none";
        // document.getElementById("loader").style.display = "none";
        const tithenumber = this.value;
        const local = formRef.current.getFieldValue("local_id") || 0;

        if (e.key === "Enter") {
            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + jsonMemberFile.urls.view2 + "/" + tithenumber + "?local_id=" + local)
                .then((res) => {
                    if (res.data.data !== null) {
                        //document.getElementById("meminfo").style.display = "block";
                        const data = res.data.data;
                        document.getElementById("input_member_name").value = data.name;
                        document.getElementById("input_member_id").value = data.ref_number;
                        document.getElementById("input_amount").value = null;

                        document.getElementById("loader").style.display = "none";
                    } else {
                        document.getElementById("meminfo").style.display = "none";
                        document.getElementById("loader").style.display = "none";
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: "No member information found, please try with correct tithe number"
                        });
                        //document.getElementById("loaderinfo").style.display = "none";
                    }
                })
                .catch((error) => {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Network, please try again"
                    });
                    document.getElementById("loader").style.display = "none";
                });
        }

        return false;
    });

    $('#input_amount').unbind('keyup');
    $('#input_amount').bind('keyup', function (e) {
        if (this.value > 0) {
            document.getElementById("submitbutton").style.display = "inline";
        } else {
            document.getElementById("submitbutton").style.display = "none";
        }
    });

    if (CisUI().getUserInfo('comarea') === 6) {
        const titheID = localStorage.getItem("titheID") || null;
        //jsonfile.input[2].value = parseInt(titheID);
    }

    //console.log("JSON Data : "+JSON.stringify(jsonfile.input));

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const onMonthSelect = (e) => {
        const selected = moment(e).format("YYYY-MM-01");
        // let date = moment().set('year',  moment(e).format("YYYY")).set('month',  moment(e).format("MM")).set('date', 1).isoWeekday(8)
        let firstDate = moment().format("YYYY-MM-DD");
        for(var x=0; x<=6; x++){
            const st1 = moment(selected).add(x, 'days');
            const st = moment(st1).format('dddd');
            if(st === "Sunday") {
                // console.log(st);
                firstDate = moment(st1).format("YYYY-MM-DD");
                // console.log(firstDate);
            }
        }

        const lastDay = moment(selected).endOf('month').format('dddd');
        // console.log("DayName: " + lastDay);
        let lastDate = moment(selected).endOf('month').format('YYYY-MM-DD');
        if (lastDay === "Sunday") {
            lastDate = moment(lastDate).add(1,'days').format('YYYY-MM-DD');
            form.setFieldValue("date", moment(lastDate).subtract(1,'days'));
        } else {
            const nextMonth = moment(selected).add(1, "months").format('YYYY-MM-01');
            for(var x=0; x<=6; x++){
                const st1 = moment(nextMonth).add(x, 'days');
                const st = moment(st1).format('dddd');
                // console.log(st);
                if(st === "Monday") {
                    lastDate = moment(st1).format("YYYY-MM-DD");
                    form.setFieldValue("date", moment(lastDate).subtract(1,"days"));
                    // console.log(st);
                    // console.log(lastDate);
                }
            }
        }
        setCDate({start: firstDate, end: lastDate});

        if(moment().format("MM") === moment(selected).format("MM")) {
            form.setFieldValue("date", moment(e));
        }

    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction("../../" + jsonfile.urls.list)
                : CisUI().addAction("../" + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Row gutter={24}>
                            <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                    id="month"
                                    label="Tithes Closing Month"
                                    name="month"
                                    // initialValue={moment()}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Month is required"
                                        },
                                    ]}
                                >
                                    <DatePicker picker="month"
                                                format={"MMMM YYYY"}
                                                onChange={onMonthSelect}
                                                className="gx-mb-3 gx-w-100"
                                                placeholder="Transaction Closing month"/>

                                </Form.Item>
                            </Col>
                            <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                    id="date"
                                    label="Transaction Date"
                                    name="date"
                                    initialValue={moment(cdate.end)}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Transaction date is required"
                                        },
                                    ]}
                                >
                                    <DatePicker picker="date" format={"YYYY-MM-DD"}
                                                disabledDate={(current) => {
                                                    return moment(cdate.start) >= current || moment(cdate.end) <= current;
                                                }}
                                                className="gx-mb-3 gx-w-100"
                                                placeholder="Transaction date"/>

                                </Form.Item>
                            </Col>
                        </Row>
                        {InputOnly(jsonfile.input, userData, isEdit)}
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: 'center'}}>
                                <Button id="submitbutton" style={{display: 'none'}} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

        </Card>
    );
};

export default NewCollection;