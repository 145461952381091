import React, {useState, useEffect} from "react";
import {Form, Popconfirm, Col, Input, Button, Collapse, Card, notification} from 'antd';
import jsonfile from './voucher.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
// import ActionButton from '../../../util/actionbutton';
import ActionButton2 from '../../../util/ActionButton2';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import axios from "../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;

const UnclearedCheque = () => {
    const value = 0;
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.list + "?status=0",
        filtervalue: '',
        subtitle: '',
    });
    const [stdata, setStdata] = useState([]);
    const [selected, setSelected] = useState([]);

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const {id, checked} = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver + jsonfile.urls.list + "?status=0"
            }
        );
    }, []);

    const history = useHistory();

    const getPrintAction = (row) => {
        if (row.is_multy === 0) {
            return <Link to={"../" + jsonfile.urls.view + "/" + row.id}><span
                style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        } else {
            return <Link to={"../" + jsonfile.urls.mview + "/" + row.voucher_no}><span
                style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        }
    }

    const checkAll = (e) => {
        var dcd = document.getElementById("datatable");
        var checkboxes = dcd.getElementsByTagName('input');
        let allselected = [];
        if (e.target.checked) {
            for (var i = 1; i < checkboxes.length; i++) {
                console.log(i);
                if (checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                } else {
                    checkboxes[i].checked = true;
                    if (!allselected.includes(checkboxes[i].value)) {
                        allselected.push(checkboxes[i].value);
                    }
                }
            }
        } else {
            allselected = [];
            for (var i = 1; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
            }
        }
        setSelected(allselected);
        if (allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        } else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const selectedForPrint = (e) => {
        let allselected = selected;
        if (e.target.checked === true) {
            if (!allselected.includes(e.target.value)) {
                allselected.push(e.target.value);
            }
        } else {
            var index = allselected.indexOf(e.target.value)
            if (index !== -1) {
                allselected.splice(index, 1);
            }
        }
        setSelected(allselected);

        if (allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        } else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const confirm = (id) => {
        document.getElementById("loader").style.display = "block";
        axios.get("entries/clearcheck/"+id)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    // getPODetails();
                } else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            text: <input type="checkbox" style={{paddingLeft: '5px !important'}} onChange={checkAll} name="checkAll" id="checkAll" value={1}/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <input type="checkbox" onChange={selectedForPrint} name={"ck_" + value} value={value}/>
            )
        },
        bank_account: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        source: {
            text: <IntlMessages id="Beneficiary"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                value
            )
        },
        type: {
            text: <IntlMessages id="Payment Method"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                value
            )
        },
        voucher_no: {
            text: <IntlMessages id="Voucher No#"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                getPrintAction(row)
            )
        },
        head: {
            text: <IntlMessages id="Account"/>,
        },
        debit: {
            text: <IntlMessages id="Receipt(₵)"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Payment(₵)"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="Created by"/>,
            sortable: true,
            filterable: true,
        },
        title: {
            text: <IntlMessages id="Title"/>,
            sortable: true,
            filterable: true,
        },
        reference: {
            text: <IntlMessages id="Reference"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {CisUI().getTransactionStatus(value)}
                </>
            ),
        },
        checque_status: {
            text: <IntlMessages id="Check Status"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {CisUI().getChecqueStatus(value)}
                    {/*<Popconfirm*/}
                    {/*    placement="topRight"*/}
                    {/*    title={"Are you sure you want to clear this cheque?"}*/}
                    {/*    onConfirm={confirm}*/}
                    {/*    okText="Yes"*/}
                    {/*    cancelText="No"*/}
                    {/*>*/}
                    {/*    <Button className="btn btn-primary">Clear?</Button>*/}
                    {/*</Popconfirm>*/}
                </>
            ),
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {
                        row.status !== 3 ? ActionButton2(row.id, jsonfile.urls, jsonfile.urls.list) : ""
                    }
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "/?status=0&" + qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input, "", 1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", 'all_transaction', jsonfile, state.subtitle, "landscape")}
        </>
    );

    const goForApprove = () => {
        console.log(stdata);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + "entries/multyvoucherclear?status=1&tps=" + stdata, null)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    setState(
                        {
                            reqURL: Config.apiserver + jsonfile.urls.list+"?status=0&tm="+Math.random()
                        }
                    );
                    history.push("../" + jsonfile.urls.list+"?status=0&tm="+Math.random());
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
               // setLoading({loading: false});
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                //console.log(errors.response.data.errors);
                //setLoading({loading: false});
            });
    }

    return (
        <>
            {searchView}
            {CisUI().showLoading}
            <div className="row" id="prntbtn" style={{padding: '10px', display: 'none'}}>
                {/*<a onClick={goForApprove} className="btn btn-primary" style={{width: '100px'}}>Cleared</a>*/}
                <Popconfirm
                    placement="topRight"
                    title={"Are you sure you want to clear selected cheque?"}
                    onConfirm={goForApprove}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className="btn btn-primary" style={{width: '100px'}}>Clear?</Button>
                </Popconfirm>
            </div>
            <div id="datatable">
                <Card title={jsonfile.listtitle_pending} extra={getExtra}>
                    <SmartDataTable
                        name='test-fake-table'
                        data={state.reqURL}
                        dataRequestOptions={CisUI().HeaderRequest}
                        dataKey="data"
                        headers={headers}
                        orderedHeaders={orderedHeaders}
                        hideUnordered={CisUI().hideUnordered}
                        className={CisUI().sematicUI.table}
                        filterValue={state.filterValue}
                        perPage={CisUI().perPage}
                        sortable
                        withLinks
                        withHeader
                        loader={CisUI().loader}
                        parseBool={{
                            yesWord: 'Yes',
                            noWord: 'No',
                        }}
                        parseImg={{
                            style: {
                                border: '1px solid #ddd',
                                borderRadius: '50px',
                                padding: '3px',
                                width: '30px',
                                height: '30px'
                            },
                        }}
                        emptyTable={CisUI().emptyTable}
                    />
                </Card>
            </div>
        </>
    );
};

export default UnclearedCheque;