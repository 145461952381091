import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card, notification} from 'antd';
import jsonfile from './report.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import moment from "moment";

const Search = Input.Search;
const Panel = Collapse.Panel;

const StatementofFunctionalExpense = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.funcExpenses,
        filtervalue : '',
        subtitle : '',
    });

    const [header,setHeader] = useState("");
    const [period,setPeriod] = useState("");
    const [reportyear,setReportYear] = useState({
        currentYear: 2021,
        lastYear: 2020,
    });

    const [resp,setResp] = useState({
        status : 1,
        msg : "STATEMENT OF ACTIVITIES",
        company : {},
        data : [],
        functionalEexpenses : []

    });

    const [hide,setHide] = useState([]);
    const [reporttype,setReportType] = useState("periodical");
    const [company,setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setResp(res.data);
                    //setData(res.data.data);
                    //console.log(res.data.data.assets[0].currentAsset);
                    //console.log(res.data.data[0]["assets"]["currentAsset"]);
                    setCompany(res.data.company);
                    setHeader(res.data.company.legal_name);

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();


    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = [];
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    // wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);

                    if(values["reporttype"] === "comparative") {
                        setReportYear({
                            currentYear : "as of "+CisUI().getMonthTitle(dt[1]),
                            lastYear : "as of "+CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(1, 'year'))
                        });
                    }
                    else  {
                        setReportYear({
                            currentYear : "as of "+CisUI().getMonthTitle(dt[1]),
                            lastYear : ""
                        });
                    }
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                }else if (key === 'reporttype') {
                    setReportType(values[key]);
                    return `${key}=${values[key]}`

                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        if (values['reporttype'] === 'comparative') {
            setPeriod("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(1, 'year')) );
        }
        else {
            setPeriod("For the End of " + CisUI().getMonthTitle(dt[1]));
        }

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.funcExpenses + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        window.open(Config.baseurl+"reports/stmntoffunexprint.html?url="+window.btoa(state.reqURL)+"&filename=statement_of_functional_expense&data=stmntoffunexData&subtitle="+period+"&rptype=stmntoffunex","_blank");
    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if(hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index,1);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                //row[i].removeAttribute('style');
                row[i].style.display = 'none';
            }
        }
        else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'none';
                row[i].removeAttribute('style');
            }
        }
        console.log("hides : ", hide);
    }

    let totalDebit = 0;
    let totalCredit = 0;

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                { resp.status === 1 ?
                    <div className="table-responsive">
                        <div style={{textAlign : 'center'}}>
                            <h2>{header}</h2>
                            <h4>STATEMENT OF FUNCTIONAL EXPENSES</h4>
                            <h4>{period}</h4>
                        </div>

                        <table className="statement table mx-auto w-auto">
                            <thead>
                            <tr>
                                <th style={{width: '200px'}}>Expenses</th>
                                {
                                    resp["functionalEexpenses"].map((itmes,index)=> {
                                        return <th style={{textAlign : 'right', width : '130px'}}>{itmes.name}</th>
                                    })
                                }
                                <th style={{textAlign : 'right', width : '130px'}}>{reportyear.currentYear} Total</th>
                                <th style={{textAlign : 'right', width : '130px'}}>{reportyear.lastYear} Total</th>

                            </tr>
                            </thead>
                            <tbody>

                            {
                                resp.data.map((itmes,index)=> {
                                    if(itmes.type === 'section') {
                                        return <tr>
                                            <td colSpan={5} style={{fontWeight: 'bold'}}><div>{itmes.title}</div></td>
                                        </tr>
                                    }
                                    else if(itmes.type === 'total') {
                                        return <tr className="showTotalBorder">
                                            <td style={{fontWeight: 'bold'}}><div>{itmes.title}</div></td>
                                            {
                                                resp["functionalEexpenses"].map((itm,indx)=> {
                                                    return  <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(itmes.clm[indx])}</td>
                                                })
                                            }
                                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(itmes.thisYear)}</td>
                                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(itmes.lastYear)}</td>
                                        </tr>
                                    }
                                    else {
                                        return <tr>
                                            <td style={{fontWeight: 'normal'}}><div className="subItemText">{itmes.title}</div></td>
                                            {
                                                resp["functionalEexpenses"].map((itm,indx)=> {
                                                    return  <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(itmes.clm[indx])}</td>
                                                })
                                            }
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(itmes.thisYear)}</td>
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(itmes.lastYear)}</td>
                                        </tr>
                                    }
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    : <></> }

            </Card>
        </>
    );
};

export default StatementofFunctionalExpense;