import React from "react";
import {Route, Switch} from "react-router-dom";
import Donation from "../donation";
import SingleVoucher from "../../containers/yotta/entries/singlevoucher";
import MultipleVoucher from "../../containers/yotta/entries/multiplevoucher";
import BankReconcilation from "../../containers/yotta/financial/bank/bankreconcile";
import NewJournal from "../../containers/yotta/financial/journal/newjournal";
import NewPurchaseOrder from "../../containers/yotta/purchase/new";
import CreateCartItem from "../../containers/yotta/sales/order/CreateCartItem";
import NewOrderListView from "../../containers/yotta/sales/order/NewOrderListView";
import CorporateSales from "../../containers/yotta/sales/order/corporatesales";
import AllVoucherList from "../../containers/yotta/entries/allvoucherlist";
import VoucherDetails from "../../containers/yotta/entries/voucherdetails";
import MultipleVoucherDetails from "../../containers/yotta/entries/mvoucherdetails";
import SalesOrderHistory from "../../containers/yotta/sales/order/SalesOrderHistory";
import SalesOrderDetails from "../../containers/yotta/sales/order/Salesorderdetails";
import ProvisionalCashVoucher from "../../containers/yotta/entries/provisionalcashvoucher";
import ProvisionalCashClearence from "../../containers/yotta/entries/provisionalcashclearence";
import Voucheredit from "../../containers/yotta/entries/voucheredit";
import Unclearedchecque from "../../containers/yotta/entries/unclearedchecque";
import AllPVoucherList from "../../containers/yotta/entries/pvhistory";
import PVDetails from "../../containers/yotta/entries/pvdetails";
import PVClearanceList from "../../containers/yotta/entries/pvclearencelist";
import Journallist from "../../containers/yotta/financial/journal/journallist";
import Importjournal_new from "../../containers/yotta/financial/journal/importjournal_new";
import JournalDetails from "../../containers/yotta/financial/journal/journaldetails";
import Journaledit_off from "../../containers/yotta/financial/journal/journaledit";
import BankReconcileStart from "../../containers/yotta/financial/bank/bankreconcilestart";
import ReconcileHistory from "../../containers/yotta/financial/bank/reconcilehistory";
import ReconcileDetails from "../../containers/yotta/financial/bank/reconciledetails";
import AllTransactions from "../../containers/yotta/financial/transactions/alltransactions";
import NewTransaction from "../../containers/yotta/financial/transactions/newtransactionrcv";
import NewTransactionPay from "../../containers/yotta/financial/transactions/newpayment";
import AllBankTransactions from "../../containers/yotta/financial/bank/banktransaction";
import NewBankDeposit from "../../containers/yotta/financial/bank/newdeposit";
import BillPaymentHistory from "../../containers/yotta/vendor_management/bill_payment/paymenthistory";
import MultipleVoucherNew from "../../containers/yotta/entries/multiplevoucher_new";
import MultipleVoucherEdit from "../../containers/yotta/entries/mvoucheredit";
import SingleVoucherV2 from "../../containers/yotta/entries/voucher/singlevoucherv2";
import SingleVoucherEditV2 from "../../containers/yotta/entries/voucher/vouchereditv2";

const Entries = ({match}) => (
    <Switch>
        <Route path={`${match.url}/singlevoucher`} component={SingleVoucherV2}/>
        {/*<Route path={`${match.url}/singlevoucher`} component={SingleVoucher}/>*/}
        <Route path={`${match.url}/manyvoucher`} component={MultipleVoucherNew}/>
        <Route path={`${match.url}/allvoucher`} component={AllVoucherList}/>
        <Route path={`${match.url}/pvhistory`} component={AllPVoucherList}/>
        <Route path={`${match.url}/pvdetails/:id`} component={PVDetails}/>
        <Route path={`${match.url}/voucherlist`} component={AllVoucherList}/>
        <Route path={`${match.url}/pvclearence`} component={PVClearanceList}/>
        <Route path={`${match.url}/customervoucher`} component={SingleVoucher}/>
        <Route path={`${match.url}/vendorvoucher`} component={SingleVoucher}/>
        <Route path={`${match.url}/bankrconvoucher`} component={BankReconcilation}/>
        <Route path={`${match.url}/adjustmentjournal`} component={NewJournal}/>
        <Route path={`${match.url}/journlalist`} component={Journallist}/>
        <Route path={`${match.url}/journalimport`} component={Importjournal_new}/>
        <Route path={`${match.url}/journaldetails/:id`} component={JournalDetails}/>
        <Route path={`${match.url}/journaledit/:id`} component={Journaledit_off}/>

        <Route path={`${match.url}/purchaseorders`} component={NewPurchaseOrder}/>

        <Route path={`${match.url}/alltrx`} component={AllTransactions}/>
        <Route path={`${match.url}/newtrxrcv`} component={NewTransaction}/>
        <Route path={`${match.url}/newpayment`} component={NewTransactionPay}/>
        <Route path={`${match.url}/banktrx`} component={AllBankTransactions}/>
        <Route path={`${match.url}/newbankdeposit`} component={NewBankDeposit}/>
        <Route path={`${match.url}/billpay`} component={BillPaymentHistory}/>

        <Route path={`${match.url}/purchaseindent`} component={NewPurchaseOrder}/>
        <Route path={`${match.url}/purchasedo`} component={NewPurchaseOrder}/>
        <Route path={`${match.url}/purchaseinvoice`} component={NewPurchaseOrder}/>
        <Route path={`${match.url}/salesorder`} component={CorporateSales}/>
        <Route path={`${match.url}/saleshistory`} component={SalesOrderHistory}/>
        <Route path={`${match.url}/salesdetails/:id`} component={SalesOrderDetails}/>
        <Route path={`${match.url}/salesinvoice`} component={CorporateSales}/>
        <Route path={`${match.url}/salesdelivery`} component={NewOrderListView}/>
        <Route path={`${match.url}/voucherdetails/:id`} component={VoucherDetails}/>
        <Route path={`${match.url}/mvoucherdetails/:id`} component={MultipleVoucherDetails}/>
        <Route path={`${match.url}/addnewpv`} component={ProvisionalCashVoucher}/>
        <Route path={`${match.url}/pcvvoucher`} component={ProvisionalCashVoucher}/>
        <Route path={`${match.url}/pcclearence`} component={ProvisionalCashClearence}/>
        {/*<Route path={`${match.url}/voucheredit/:id`} component={Voucheredit}/>*/}
        <Route path={`${match.url}/voucheredit/:id`} component={SingleVoucherEditV2}/>
        <Route path={`${match.url}/mvoucheredit/:id`} component={MultipleVoucherEdit}/>
        <Route path={`${match.url}/unclearedchecque`} component={Unclearedchecque}/>
        <Route path={`${match.url}/reconcilehistory`} component={ReconcileHistory}/>
        <Route path={`${match.url}/reconcilestart`} component={BankReconcileStart}/>
        <Route path={`${match.url}/reconciledetails/:id`} component={ReconcileDetails}/>

    </Switch>
)

export default Entries;