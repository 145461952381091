import {Form, Row, Col, Button, Card, notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './collection.json';
import jsonMemberFile from '../../donation/member/member.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";

const NewOfferingCollection = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        let msg = "Sorry! request not processed, please try again";
        values["is_ministry"] = 0;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });

                    form.resetFields();

                    document.getElementById("submitbutton").style.display = "none";
                    const time = Math.random();

                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };


    $('#input_tithe_number').unbind('keyup');
    $('#input_tithe_number').bind('keyup', function (e) {
        document.getElementById("loaderinfo").style.display = "block";
        document.getElementById("meminfo").style.display = "none";
        // document.getElementById("loader").style.display = "none";
        const tithenumber = this.value;
        const local = formRef.current.getFieldValue("local_id") || 0;

        if (e.key === "Enter") {
           document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver+jsonMemberFile.urls.view2+"/"+tithenumber+"?local_id="+local)
                .then((res) => {
                    if(res.data.data !== null) {
                        //document.getElementById("meminfo").style.display = "block";
                        const data = res.data.data;
                        document.getElementById("input_member_name").value = data.name;
                        document.getElementById("input_member_id").value = data.ref_number;
                        document.getElementById("input_amount").value = null;

                        document.getElementById("loader").style.display = "none";
                    }
                    else {
                        document.getElementById("meminfo").style.display = "none";
                        document.getElementById("loader").style.display = "none";
                        notification.warning({
                            message: 'Alert',
                            type : "warning",
                            description: "No member information found, please try with correct tithe number"
                        });
                        //document.getElementById("loaderinfo").style.display = "none";
                    }
                })
                .catch((error) => {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: "Network, please try again"
                    });
                    document.getElementById("loader").style.display = "none";
                });
        }

        return false;
    });

    $('#input_amount').unbind('keyup');
    $('#input_amount').bind('keyup', function (e) {
       if(this.value > 0) {
           document.getElementById("submitbutton").style.display = "inline";
       }
       else {
           document.getElementById("submitbutton").style.display = "none";
       }
    });

    if(CisUI().getUserInfo('comarea') === 6) {
        const titheID = localStorage.getItem("titheID") || null;
        //jsonfile.input[2].value = parseInt(titheID);
    }

    //console.log("JSON Data : "+JSON.stringify(jsonfile.input));

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        {InputOnly(jsonfile.input,userData,isEdit)}
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: 'center'}}>
                                <Button id="submitbutton" style={{display: 'none'}} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

        </Card>
    );
};

export default NewOfferingCollection;