import React from "react";
import {Route, Switch} from "react-router-dom";

import PurachaseList from "../../containers/yotta/purchase/list";
import PurachaseListPeinding from "../../containers/yotta/purchase/pendinglist";
import NewPurchase from "../../containers/yotta/purchase/new";
import EditPurchase from "../../containers/yotta/purchase/edit_purchase";
import OrderDetails from "../../containers/yotta/purchase/details";
import PrintDetails from "../../containers/yotta/purchase/print";
import NewStockPurcahseOrder from "../../containers/yotta/purchase/stockpurchase/newstockorder";
import PurchaseOrderList from "../../containers/yotta/purchase/stockpurchase/orderlist";
import PoItemReceive from "../../containers/yotta/purchase/stockpurchase/itemreceiv";
import NewPurchaseRequisition from "../../containers/yotta/purchase/requistition/newrequisition";
import PurchaseRequisitonHistory from "../../containers/yotta/purchase/requistition/reqhistory";
import POReqDetails from "../../containers/yotta/purchase/requistition/reqdetails";
import PendingRequisitonHistory from "../../containers/yotta/purchase/requistition/pendinglist";

const Purchase = ({match}) => (
    <Switch>
        <Route path={`${match.url}/newpoorder`} component={NewPurchase}/>
        <Route path={`${match.url}/poorders`} component={PurachaseList}/>
        <Route path={`${match.url}/pendingpo`} component={PurachaseListPeinding}/>
        <Route path={`${match.url}/podetails/:id`} component={OrderDetails}/>
        <Route path={`${match.url}/poedit/:id`} component={EditPurchase}/>
        <Route path={`${match.url}/print/:id`} component={PrintDetails}/>
        <Route path={`${match.url}/newpostockorder`} component={NewStockPurcahseOrder}/>
        <Route path={`${match.url}/orderhistory`} component={PurchaseOrderList}/>
        <Route path={`${match.url}/itemreceive/:id`} component={PoItemReceive}/>
        <Route path={`${match.url}/newporeq`} component={NewPurchaseRequisition}/>
        <Route path={`${match.url}/reqhistory`} component={PurchaseRequisitonHistory}/>
        <Route path={`${match.url}/pendingreq`} component={PendingRequisitonHistory}/>
        <Route path={`${match.url}/poreqdetails/:id`} component={POReqDetails}/>

    </Switch>
);

export default Purchase;