import {Form, Row, Col, Input, InputNumber, DatePicker, Button, Card, Select, Checkbox, notification, Upload} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './budget.json';
import {CisUI} from '../../../../util/CISUI';
import PostToApi from '../../../../util/PostToApi';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import StaticSelectData from "../../../../util/StaticSelectData";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";
import getRemoteJSON from "../../../../util/getremotejson";
import _ from "lodash";

import moment from "moment";
import 'moment/locale/es-us';

const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;


const BudgetEdit = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [inputList2, setInputList2] = useState([]);
    const [incomes, setInocmes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [checked, setChecked] = useState([]);
    const [checkedExp, setCheckedExp] = useState([]);
    const [processed, setProcessed] = useState(false);
    const [ttl, setTtl] = useState({
        totalIncome : 0,
        totalExpense : 0
    });

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthS = ["jan", "feb", "mar", "apr", "may", "jun",
        "jul", "aug", "sep", "oct", "nov", "dec"
    ];

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.edit;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        //console.log(jsonfile.urls.editview);
        //userData = RemoteJson(jsonfile.urls.editview+"/"+userID);

        // axios.get(jsonfile.urls.editview+"/"+userID)
        //     .then((res) => {
        //         //console.log(res.data);
        //         if(res.data.status === 1) {
        //             incomes(res.data.incomes);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //
        //     });
        //
        // Title = jsonfile.edittitle;
        // endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})

        const incomesArray = incomes.filter(value => value.total > 0);
       // const expenseArray = expenses.filter(valueX => valueX.total > 0);

        //values['incomes'] = incomesArray;
        //values['expenses'] = expenseArray;
        values['allinput'] = [...incomesArray];
        values['budget_id'] = userID;
        console.log("All Input : "+JSON.stringify(values));
        let msg = "Sorry! request not processed, please try again";

        axios.post(Config.apiserver + endpoint+"/"+userID, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });



    };

    //const dataOptions2 = SelectData("incomehead");
    //const dataOptions = SelectData("expensehead");

    useEffect(() => {
        getBudgetHead();
    }, []);

    let dataex = [];

    let dataex2 = [];

    const getBudgetHead = async () => {
        return await axios.get(jsonfile.urls.editview+"/"+userID)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    //setInocmes(res.data.incomes);
                    //setExpenses(res.data.expenses);

                    res.data.incomes.map((value, index) => {
                        dataex2[index] = {
                            id: value.id,
                            name: value.account_number+"-"+value.account_name,
                            total: value.total,
                            input: value.total,
                            jan : value.month1,
                            feb : value.month2,
                            mar : value.month3,
                            apr : value.month4,
                            may : value.month5,
                            jun : value.month6,
                            jul : value.month7,
                            aug : value.month8,
                            sep : value.month9,
                            oct : value.month10,
                            nov : value.month11,
                            dec : value.month12
                        };
                    });

                    setInocmes(dataex2);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...incomes];
            //const list = [...incomes, { }];

            list[index]["id"] = incomes[index]["id"];
            list[index]["name"] = incomes[index]["name"];
            list[index]["total"] = value;
            list[index]["input"] = value;

            _.times(12, (i) => {
                list[index][monthS[i]] = incomes[index][monthS[i]] || 0;
            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : total,
                totalExpense : ttl.totalExpense
            });
            setInocmes(list);

        }
    };

    const  handleAmountInputChange = (e, index,i) => {
        if(e && e.target) {
            const list = [...incomes];
            list[index]["id"] = incomes[index]["id"];
            list[index]["name"] = incomes[index]["name"];

            let ttls = 0;
            _.times(12, (ix) => {
                if(ix === i) {
                    list[index][monthS[ix]] = parseFloat(e.target.value || 0);
                    ttls += parseFloat(e.target.value || 0);
                }
                else {
                    list[index][monthS[ix]] = incomes[index][monthS[ix]] || 0;
                    ttls += parseFloat(incomes[index][monthS[ix]] || 0);
                }

            });

            list[index]["total"] = ttls;
            list[index]["input"] = incomes[index]["input"];

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : total,
                totalExpense : ttl.totalExpense
            });

            setInocmes(list);
        }
    };

    const m_handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;
            const list = [...expenses];

            list[index]["id"] = expenses[index]["id"];
            list[index]["name"] = expenses[index]["name"];
            list[index]["total"] = value;
            list[index]["input"] = value;

            _.times(12, (i) => {
                list[index][monthS[i]] = expenses[index][monthS[i]] || 0;
            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : ttl.totalIncome,
                totalExpense : total
            });

            setExpenses(list);

        }
    };


    const  m_handleAmountInputChange = (e, index,i) => {
        if(e && e.target) {
            const list = [...expenses];
            list[index]["id"] = expenses[index]["id"];
            list[index]["name"] = expenses[index]["name"];
            //list[index]["total"] = expenses[index]["total"];

            let ttls = 0;
            _.times(12, (ix) => {
                if(ix === i) {
                    list[index][monthS[ix]] = parseFloat(e.target.value || 0);
                    ttls += parseFloat(e.target.value || 0);
                }
                else {
                    list[index][monthS[ix]] = expenses[index][monthS[ix]] || 0;
                    ttls += parseFloat(expenses[index][monthS[ix]] || 0);
                }

            });

            list[index]["total"] = ttls;
            list[index]["input"] = expenses[index]["input"];

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : ttl.totalIncome,
                totalExpense : total
            });

            setExpenses(list);
        }
    };



    $('#input_region_id').on('change', function() {
        alert( "Regeion Selected" );
    });


    const onCheck = (e) => {
        let ck = checked;
        const vl = e.target.value;
        if(ck.indexOf(vl) > -1) {
            const index = ck.indexOf(vl);
            ck.splice(index,1);
        }
        else {
            ck.push(vl);
        }

        // console.log(e.target.checked);
        // if(e.target.checked == true) {
        //     const vl = e.target.value;
        //     if(ck.indexOf(vl) > -1) {
        //         const index = ck.indexOf(vl);
        //         ck.splice(index,1);
        //     }
        //     else {
        //         ck.push(vl);
        //     }
        // }
        // else {
        //     const vl = e.target.value;
        //     if(ck.indexOf(vl) > -1) {
        //         const index = ck.indexOf(vl);
        //         ck.splice(index,1);
        //     }
        //     else {
        //         ck.push(vl);
        //     }
        // }

        setChecked(ck);
        console.log("checked value :",ck);
    }

    const onCheckExp = (e) => {
        let ck = checkedExp;
        if(e.target.checked == true) {
            const vl = e.target.value;
            if(ck.indexOf(vl) > -1) {
            }
            else {
                ck.push(vl);
            }
        }
        else {
            const vl = e.target.value;
            if(ck.indexOf(vl) > -1) {
                const index = ck.indexOf(vl);
                ck.splice(index,1);
            }
        }
        setCheckedExp(ck);
        console.log("checked value :",ck);
    }

    const onFillClick = () => {
        if(checked.length > 0) {

            const list = [...incomes];

            checked.map((value,index) => {
                const data = incomes[value];
                list[value]["id"] = incomes[value]["id"];
                list[value]["name"] = incomes[value]["name"];
                let total = 0;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = incomes[value]["input"] || 0;
                    total += parseFloat(incomes[value]["input"]) || 0;
                });
                list[value]["total"] = total;
                list[value]["input"] = incomes[value]["input"];

            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : total,
                totalExpense : ttl.totalExpense
            });

            setInocmes(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onDistributeClick = () => {
        if(checked.length > 0) {

            const list = [...incomes];
            // const list = incomes;
            checked.map((value,index) => {
                const data = incomes[value];
                list[value]["id"] = incomes[value]["id"];
                list[value]["name"] = incomes[value]["name"];
                list[value]["total"] = incomes[value]["input"];
                list[value]["input"] = incomes[value]["input"];
                const eachV = incomes[value]["total"] / 12;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = eachV.toFixed(2) || 0;
                });
            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : total,
                totalExpense : ttl.totalExpense
            });

            setInocmes(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onFillClickExp = () => {
        if(checkedExp.length > 0) {

            const list = [...expenses];

            checkedExp.map((value,index) => {
                const data = expenses[value];
                list[value]["id"] = expenses[value]["id"];
                list[value]["name"] = expenses[value]["name"];
                let total = 0;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = expenses[value]["input"] || 0;
                    total += parseFloat(expenses[value]["input"]) || 0;
                });
                list[value]["total"] = total;
                list[value]["input"] = expenses[value]["input"];

            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : ttl.totalIncome,
                totalExpense : total
            });

            setExpenses(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onDistributeClickExp = () => {
        if(checkedExp.length > 0) {

            const list = [...expenses];
            checkedExp.map((value,index) => {
                const data = expenses[value];
                list[value]["id"] = expenses[value]["id"];
                list[value]["name"] = expenses[value]["name"];
                list[value]["total"] = expenses[value]["input"];
                list[value]["input"] = expenses[value]["input"];
                const eachV = expenses[value]["total"] / 12;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = eachV.toFixed(2) || 0;
                });
            });

            let total = 0;
            list.map((items,index)=>{
                total += parseFloat(items.total);
            });

            setTtl({
                totalIncome : ttl.totalIncome,
                totalExpense : total
            });

            setExpenses(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onMarkAll = () => {
        let ck = [];
        incomes.map((items,index) => {
            ck.push(index);
        })
        setChecked(ck);
    }

    const onMarkAllExp = () => {
        let ck = [];
        expenses.map((items,index) => {
            ck.push(index);
        })
        setCheckedExp(ck);
    }

    const onUnMarkAll = () => {
        setChecked([]);
    }

    const onUnMarkAllExp = () => {
        setCheckedExp([]);
    }


    return (
        <Card title="Budget Details">
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >

                <Row gutter={24}>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th style={{width : "300px"}}>Account Name</th>
                                <th style={{width : "80px"}}>Total</th>
                                {_.times(12, (i) => (
                                    <td style={{width : "60px"}} key={i}>{monthNames[i]}</td>
                                ))}
                            </tr>
                            </thead>

                            {incomes.map((x, index) => {
                                return (
                                    <>
                                        <tr>
                                            <td>{x.name}
                                                <input type="hidden" id={"income_"+x.id} name="income" value={x.id} />
                                            </td>
                                            <td style={{width : "80px"}}>
                                                <input
                                                    className="ant-input"
                                                    name="total"
                                                    value={incomes[index].total}
                                                    style={{width : "60px"}}
                                                    id={"total_"+x.id+"_"+index}
                                                    onChange={e => handleInputChange(e, index)}
                                                />
                                            </td>
                                            {_.times(12, (i) => (
                                                <td style={{width : "60px"}}>
                                                    <input
                                                        className="ant-input"
                                                        name={"amount"}
                                                        value={incomes[index][monthS[i]]}
                                                        style={{width : "60px"}}
                                                        id={"amount_"+x.id+"_"+index}
                                                        onChange={e => handleAmountInputChange(e, index,i)}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    </>
                                );
                            })}
                        </table>

                    </div>
                </Row>

                <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                </Col>


            </Form>


        </Card>
    );
};

export default BudgetEdit;